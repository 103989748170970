import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, TextField } from "@mui/material";
import { updateRequestScheduleAsync } from "../../redux/fieldVisitRequestSlice";
function DeleteFieldVisitPopup({ selectedId, setOpenVerifyDialog }) {
  const [rescheduleDate, setRescheduleDate] = React.useState("");
  const dispatch = useDispatch();
  const handleDeleteProperty = () => {
    dispatch(
      updateRequestScheduleAsync({
        request_id: selectedId,
        schedule_date: rescheduleDate,
      })
    ).then((action) => {
      if (updateRequestScheduleAsync.fulfilled.match(action)) {
        setOpenVerifyDialog(false);
      }
    });
  };

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box sx={{ mt: "1rem" }}>
        <TextField
          type="date"
          id="outlined-basic"
          label="Reschedule Date"
          variant="outlined"
          fullWidth
          required
          size="small"
          value={rescheduleDate}
          onChange={(e) => setRescheduleDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "1rem" }}>
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            fontSize: "0.8rem",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleDeleteProperty}
        >
          Update
        </Button>
      </Box>{" "}
    </Box>
  );
}

export default DeleteFieldVisitPopup;
