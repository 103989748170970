import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

// import Scrollbar from "../scrollbar/index";

import TableNoData from "../table/table-no-data";
import UserTableHead from "../table/user-table-head";
import TableEmptyRows from "../table/table-empty-rows";

import { fetchServiceListAsync } from "../../redux/servicesSlice";

import { emptyRows, applyFilter, getComparator } from "../table/utils";

// import { BASE_URL } from 'src/config/base_url';

import UserTableRow from "./services_table_row";
import UserTableToolbar from "./services_table_toolbar";
import useDebounce from "src/hooks/useDebounce";

// ----------------------------------------------------------------------

const serviceHeadLabel = [
  {
    id: "s_id",

    label: "S_ID",
  },
  {
    id: "name",
    label: "Name",
  },

  {
    id: "phone_number",
    label: "Phone",
  },
  {
    id: "gender",
    label: "Gender",
  },
  {
    id: "age",
    label: "Age",
  },
  {
    id: "service_type",
    label: "Service Type",
  },
  {
    id: "province",
    label: "Province",
  },
  {
    id: "district",
    label: "District",
  },

  {
    id: "status",
    label: "Status",
  },
];

const ROWSPERPAGE = 5;

export default function ServiceTableMain() {
  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = useState(null);

  const [selectedIdName, setSelectedIdName] = useState("");
  const [serviceStatus, setServiceStatus] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);

  // const [serverPage, setServerPage] = useState(1);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [rowsPerPage, setRowsPerPage] = useState(ROWSPERPAGE);

  const servicesList = useSelector((state) => state.services.servicesList);

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    dispatch(
      fetchServiceListAsync({
        searchQuery: debouncedSearchQuery,
        page: 1,
      })
    );
  }, [dispatch, debouncedSearchQuery]);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleClick = (s_id, full_name, status) => {
    if (selectedId === null) {
      setSelectedId(s_id);
      setSelectedIdName(full_name);
      setServiceStatus(status);
    } else {
      setSelectedId(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //todo
    // console.log("new page", newPage);
    // if (newPage === 0) {
    //   setServerPage(1);
    // } else if (newPage % 3 === 0) {
    //   setServerPage(newPage / 3 + 1);
    // }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const dataFiltered = applyFilter({
    inputData: servicesList,
    comparator: getComparator(order, orderBy),
  });

  const notFound = !dataFiltered.length;

  return (
    <Container disableGutters>
      <Card>
        <UserTableToolbar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedId={selectedId}
          selectedIdName={selectedIdName}
          serviceStatus={serviceStatus}
          setServiceStatus={setServiceStatus}
        />

        {/* <Scrollbar sx={{ border: "1px solid red" }}> */}
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              rowCount={servicesList.length}
              numSelected={selectedId}
              onRequestSort={handleSort}
              headLabel={serviceHeadLabel}
            />
            <TableBody>
              {dataFiltered
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <UserTableRow
                    key={row.id}
                    s_id={row.id}
                    index={index}
                    profileImage={row?.profileImage}
                    name={row?.name}
                    phone_number={row?.phone_number}
                    gender={row?.gender}
                    age={row?.age}
                    service_type={row?.service_type}
                    province={row?.province}
                    district={row?.district}
                    status={row?.status}
                    selectedId={selectedId}
                    handleClick={handleClick}
                  />
                ))}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(page, rowsPerPage, servicesList.length)}
              />

              {notFound && (
                <TableNoData columnSpan={serviceHeadLabel?.length} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Scrollbar> */}

        <TablePagination
          page={page}
          component="div"
          count={servicesList.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[ROWSPERPAGE]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
