import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Button } from "@mui/material";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TableRow,
  Typography,
  TablePagination,
  InputAdornment,
  Popover,
} from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { getComparator, stableSort } from "../../../utils/dashboard_table";

import {
  fetchSoldPropertyList,
  searchSoldProperty,
} from "../../../redux/overviewSlice";

// import { splitLocation } from "../../../utils/split_location";

// import DeletePropertyPopup from "./delete_property_popup";
import PropertyInfoHome from "./property_details_home";
import PropertyInfoApartment from "./property_details_apartment";
import PropertyInfoLand from "./property_details_land";

import EnhancedTableHead from "../../table/EnhancedTableHead";
import { getDate } from "../../../utils/get_datetime";

const headCells = [
  {
    id: "p_id",

    disablePadding: true,
    label: "P_ID",
    sort: true,
  },
  {
    id: "property_title",
    disablePadding: false,
    label: "Property Title",
    sort: true,
  },
  {
    id: "property_type",
    disablePadding: false,
    label: "Property Type",
    sort: true,
  },
  {
    id: "location",

    disablePadding: false,
    label: "Location",
    sort: true,
  },
  {
    id: "sold_on",
    disablePadding: false,
    label: "Sold On",
    sort: true,
  },
];
function createData(p_id, property_title, property_type, location, sold_on) {
  return {
    p_id,
    property_title,
    property_type,
    location,
    sold_on,
  };
}

export default function AdminDashboardOverviewTable1() {
  const dispatch = useDispatch();
  // const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [propertyDetailsAnchorEl, setPropertyDetailsAnchorEl] =
    React.useState(null);
  const [selectedIdName, setselectedIdName] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("p_id");
  const [selectedId, setselectedId] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [propertyType, setPropertyType] = React.useState("");
  const [propertyStatus, setPropertyStatus] = React.useState("");
  const [searchQuery, setSearchQuery] = React.useState("");
  const soldPropertyList = useSelector(
    (state) => state.overview.soldPropertyList
  );
  const soldPropertyListLoading = useSelector(
    (state) => state.overview.soldPropertyListLoading
  );

  const openPropertyDetails = Boolean(propertyDetailsAnchorEl);
  const propertyDetailsId = openPropertyDetails ? "simple-popover" : undefined;
  useEffect(() => {
    dispatch(fetchSoldPropertyList());
  }, [dispatch]);

  const rows = useMemo(
    () =>
      soldPropertyList?.properties?.map((property) => {
        const sold_on = getDate(property?.createdAt);
        const location = property?.municipality + ", " + property?.district;

        return (
          createData(
            property.property_id,
            property.property_name,
            property.property_type,
            location,
            sold_on
          ) || []
        );
      }),
    [soldPropertyList?.properties]
  );

  const handleSearch = () => {
    dispatch(
      searchSoldProperty({
        location: searchQuery,
        property_type: propertyType,
        listed_for: propertyStatus,
      })
    );
  };
  const handleRetry = () => {
    dispatch(fetchSoldPropertyList());
    setSearchQuery("");
    setPropertyStatus("");
    setPropertyType("");
  };
  const handlePropertyStatusChange = (e) => {
    setPropertyStatus(e.target.value);
    dispatch(
      searchSoldProperty({
        location: searchQuery,
        property_type: propertyType,
        listed_for: e.target.value,
      })
    );
  };
  const handlePropertyDetailsChange = (e) => {
    setPropertyType(e.target.value);
    dispatch(
      searchSoldProperty({
        location: searchQuery,
        property_type: e.target.value,
        listed_for: propertyStatus,
      })
    );
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // START SELECT ROW

  const handleClick = (event, p_id, property_type) => {
    if (selectedId === null) {
      setselectedId(p_id);
      setselectedIdName(property_type);
    } else {
      setselectedId(null);
      setselectedIdName("");
    }
  };
  // END SELECT ROW

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {soldPropertyListLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Box sx={{ flex: "1 1 100%", display: "flex", gap: "2rem" }}>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    id="Sold Properties"
                  >
                    Sold Properties
                  </Typography>
                  <span
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "center",
                    }}
                  >
                    <Typography>Total sold : </Typography>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                      id="Sold Properties"
                    >
                      {soldPropertyList?.totalCount}
                    </Typography>
                  </span>
                </Box>

                {selectedId !== null ? (
                  <>
                    {/* <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip> */}

                    <Tooltip title="Property Details">
                      <IconButton
                        onClick={(e) =>
                          setPropertyDetailsAnchorEl(e.currentTarget)
                        }
                      >
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        <Typography
                          sx={{ fontSize: "0.9rem", color: "#666666" }}
                        >
                          Property Details
                        </Typography>{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={propertyType}
                        label="Property Details"
                        onChange={handlePropertyDetailsChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"house"}>House</MenuItem>
                        <MenuItem value={"land"}>Land</MenuItem>
                        <MenuItem value={"apartment"}>Apartment</MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        <Typography
                          sx={{ fontSize: "0.9rem", color: "#666666" }}
                        >
                          Property Status
                        </Typography>{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={propertyStatus}
                        label="Property Status"
                        onChange={handlePropertyStatusChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"sale"}>On Sale</MenuItem>
                        <MenuItem value={"rent"}>On Rent</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
              </Toolbar>
              <Box display={selectedId ? "none" : "flex"} sx={{ mr: "0.5rem" }}>
                <TextField
                  sx={{ ml: "auto", mr: 1 }}
                  id="outlined-basic"
                  label="Search"
                  type="text"
                  variant="outlined"
                  size="small"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  // inputRef={(input) => input && input.focus()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={{ cursor: "pointer" }}>
                        <SearchIcon onClick={() => handleSearch()} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    fontSize: "0.75rem",
                    padding: "0 0.3rem",
                    backgroundColor: "#dedede",
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      backgroundColor: "#c4c2c2",
                    },
                  }}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Box>
            </Box>
            {soldPropertyList?.properties?.length >= 1 ? (
              <>
                <TableContainer
                  sx={{ background: "rgba(246, 237, 237, 0.60)" }}
                >
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows?.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows?.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) =>
                              handleClick(event, row?.p_id, row?.property_type)
                            }
                            role="checkbox"
                            aria-checked={selectedId === row?.p_id}
                            tabIndex={-1}
                            key={row?.p_id}
                            selected={selectedId === row?.p_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={selectedId === row?.p_id}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row?.p_id}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property_title}
                            </TableCell>
                            <TableCell align="left">
                              {row?.property_type}
                            </TableCell>
                            <TableCell align="left">{row?.location}</TableCell>
                            <TableCell align="left">{row?.sold_on}</TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h1>No Properties</h1>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    "&:hover": {
                      backgroundColor: "#333",
                    },
                  }}
                  onClick={handleRetry}
                >
                  retry
                </Button>
              </Box>
            )}
          </>
        )}
      </Paper>

      <Popover
        id={propertyDetailsId}
        open={openPropertyDetails}
        anchorEl={propertyDetailsAnchorEl}
        onClose={() => {
          setPropertyDetailsAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{ vertical: 150, horizontal: -20 }}
      >
        {selectedIdName === "house" && (
          <PropertyInfoHome selectedId={selectedId} from="sold" />
        )}
        {selectedIdName === "apartment" && (
          <PropertyInfoApartment selectedId={selectedId} from="sold" />
        )}
        {selectedIdName === "land" && (
          <PropertyInfoLand selectedId={selectedId} from="sold" />
        )}
      </Popover>
    </Box>
  );
}
