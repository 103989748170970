import React from "react";
import { Box, Typography } from "@mui/material";
import ContactList from "./contact_list";

function AdminDashboardContactMain() {
  return (
    <Box sx={{ padding: "2rem" }}>
      <Typography variant="h4">Contact</Typography>
      <ContactList />
    </Box>
  );
}

export default AdminDashboardContactMain;
