//convert  years, month to day
export const convertToDay = (value, type) => {
  if (type === "year") {
    return Math.floor(value / 365);
  } else if (type === "month") {
    return Math.floor(value / 30);
  } else if (type === "day") {
    return value;
  }
};

// Convert cm, meter, inch to feet
export const convertToFeet = (value, type) => {
  if (type === "cm") {
    return value / 30.48;
  } else if (type === "meter") {
    return value * 3.281;
  } else if (type === "inch") {
    return value / 12;
  } else if (type === "feet") {
    return value;
  }
};
