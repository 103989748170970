export const splitLocation = (location) => {
  if (!location) {
    return "";
  }

  if (!location.includes("|")) {
    return location;
  }

  const actualLocation = location.split("|")[1];
  return actualLocation;
};
