import React from "react";
import { Box, Typography } from "@mui/material";
import StaffList from "./staff_management_staff_list";
import StaffEdit from "./staff_edit";
import StaffDetails from "./staff_details";
import StaffGraph from "./staff_graph";
import { useSelector } from "react-redux";
function AgentDashboardStaffMgmt() {
  const [actionSelected, setActionSelected] = React.useState("staffGraph");
  const [selectedId, setSelectedId] = React.useState(null);
  const isAdmin = useSelector((state) => state.auth.role);
  if (isAdmin !== "superAdmin") {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h4" sx={{ color: "red" }}>
          You are not authorized to access{" "}
        </Typography>
      </Box>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "5rem",
        }}
      >
        <StaffList
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setActionSelected={setActionSelected}
        />
        <div style={{ width: "100%" }}>
          {actionSelected === "staffEdit" && selectedId && (
            <StaffEdit selectedId={selectedId} />
          )}
          {actionSelected === "staffDetails" && selectedId && (
            <StaffDetails selectedId={selectedId} />
          )}
          {actionSelected === "staffGraph" && <StaffGraph />}
        </div>
      </div>
    </div>
  );
}

export default AgentDashboardStaffMgmt;
