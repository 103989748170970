import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TableRow,
  Typography,
  TablePagination,
  // InputAdornment,
  Dialog,
  DialogContent,
  Popover,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import TextField from "@mui/material/TextField";
// import SearchIcon from "@mui/icons-material/Search";
import { getComparator, stableSort } from "../../../utils/dashboard_table";
// import { searchStaff } from "../../redux/staffMgmtSlice";
import DeleteRequestPopup from "./delete_request_popup";
import RequestDetailHome from "./request_details_home_popup";
import RequestPropertySignUpPopup from "../../request_property/request_property_popup";
import RequestPropertyModal from "../../request_property/request_property_modal";
import {
  fetchRequestList,
  searchRequestProperty,
} from "../../../redux/overviewSlice";
import RequestDetailApartmentPopup from "./request_details_apartment_popup";
import RequestDetailLandPopup from "./request_details_land_popup";
// import { splitLocation } from "../../../utils/split_location";
// import { fetchBuyerList } from "../../../redux/overviewSlice";

import EnhancedTableHead from "../../table/EnhancedTableHead";
import useDebounce from "src/hooks/useDebounce";

const headCells = [
  {
    id: "c_id",

    disablePadding: true,
    label: "S_ID",
    sort: true,
  },
  {
    id: "full_name",
    disablePadding: false,
    label: "Full Name",
    sort: true,
  },
  {
    id: "location",

    disablePadding: false,
    label: "Address",
    sort: true,
  },
  {
    id: "property_type",
    disablePadding: false,
    label: "Property Type",
    sort: true,
  },
  {
    id: "contact",
    disablePadding: false,
    label: "Contact",
    sort: true,
  },
];
function createData(c_id, full_name, location, property_type, contact) {
  return {
    c_id,
    full_name,
    location,
    property_type,
    contact,
  };
}

export default function AgentList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [requestDetailsAnchorEl, setRequestDetailsAnchorEl] =
    React.useState(null);
  const [openRequestPropertySignupPopup, setOpenRequestPropertySignupPopup] =
    React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIdType, setSelectedIdType] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("c_id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchQuery, setSearchQuery] = React.useState("");

  const requestList = useSelector((state) => state.overview.requestList);
  const requestListLoading = useSelector(
    (state) => state.overview.customerLoading
  );

  const openRequestDetails = Boolean(requestDetailsAnchorEl);
  const requestDetailsId = openRequestDetails ? "simple-popover" : undefined;

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    if (debouncedSearchQuery === "") dispatch(fetchRequestList());
  }, [dispatch, debouncedSearchQuery]);

  const rows = useMemo(() => {
    return requestList?.map((request) => {
      const district = request?.district;
      return (
        createData(
          request?.id,
          request?.request_by?.name,
          district,
          request?.property_type,
          request?.request_by?.phone_number
        ) || []
      );
    });
  }, [requestList]);

  // const handleSearch = () => {
  //   // dispatch(
  //   //   searchStaff({
  //   //     staffSearchQuery: searchQuery,
  //   //   })
  //   // );
  // };

  useEffect(() => {
    if (debouncedSearchQuery) {
      dispatch(
        searchRequestProperty({
          search: debouncedSearchQuery,
        })
      );
    }
  }, [debouncedSearchQuery, dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (c_id, name) => {
    if (selectedId === null) {
      setSelectedId(c_id);
      setSelectedIdType(name);
    } else {
      setSelectedId(null);
      setSelectedIdType("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  console.log("request list", requestList);

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {requestListLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    gap: "1.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    id="Agent List"
                  >
                    Request List
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddIcon />}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => setOpenRequestPropertySignupPopup(true)}
                  >
                    Add New Request
                  </Button>
                </Box>

                {selectedId !== null ? (
                  <>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Customer Details">
                      <IconButton
                        onClick={(e) =>
                          setRequestDetailsAnchorEl(e.currentTarget)
                        }
                      >
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Box display={selectedId ? "none" : "block"}>
                      <TextField
                        sx={{ ml: "auto", mr: 1 }}
                        id="outlined-basic"
                        label="Search"
                        type="text"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        // inputRef={(input) => input && input.focus()}
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       <SearchIcon onClick={() => handleSearch()} />
                        //     </InputAdornment>
                        //   ),
                        // }}
                      />
                    </Box>
                  </>
                )}
              </Toolbar>
            </Box>
            <>
              {requestList?.length >= 1 ? (
                <>
                  <TableContainer
                    sx={{ background: "rgba(246, 237, 237, 0.60)" }}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      aria-labelledby="tableTitle"
                      size={"medium"}
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows?.length}
                        headCells={headCells}
                      />
                      <TableBody>
                        {visibleRows?.map((row, index) => {
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={() =>
                                handleClick(row.c_id, row.property_type)
                              }
                              role="checkbox"
                              aria-checked={selectedId === row.c_id}
                              tabIndex={-1}
                              key={index}
                              selected={selectedId === row.c_id}
                              sx={{ cursor: "pointer" }}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={selectedId === row.c_id}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.full_name}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.location}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={{ textTransform: "capitalize" }}
                              >
                                {row.property_type}
                              </TableCell>
                              <TableCell align="left">{row.contact}</TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: 53 * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h1>No Properties</h1>
                </Box>
              )}
            </>
          </>
        )}
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteRequestPopup
            selectedId={selectedId}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>

      <Popover
        id={requestDetailsId}
        open={openRequestDetails}
        anchorEl={requestDetailsAnchorEl}
        onClose={() => {
          setRequestDetailsAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {selectedIdType === "house" && (
          <RequestDetailHome selectedId={selectedId} />
        )}
        {selectedIdType === "apartment" && (
          <RequestDetailApartmentPopup selectedId={selectedId} />
        )}
        {selectedIdType === "land" && (
          <RequestDetailLandPopup selectedId={selectedId} />
        )}
      </Popover>
      <RequestPropertySignUpPopup
        openRequestPropertySignupPopup={openRequestPropertySignupPopup}
        setOpenRequestPropertySignupPopup={setOpenRequestPropertySignupPopup}
      >
        <RequestPropertyModal
          setOpenRequestPropertySignupPopup={setOpenRequestPropertySignupPopup}
        />
      </RequestPropertySignUpPopup>
    </Box>
  );
}
