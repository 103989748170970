import React from "react";
import logo from "../../assets/images/logo.png";
import { Box, Typography, Avatar } from "@mui/material";
import { getTime } from "../../utils/get_datetime";

function ChatMessage({ message }) {
  const isSender = message.sender_id !== Number(0);

  return (
    <React.Fragment>
      <Box
        sx={{
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: isSender ? "flex-start" : "flex-end",
          marginRight: isSender ? "4rem" : "0",
          marginLeft: isSender ? "0" : "4rem",
          marginTop: "1.5rem",
        }}
      >
        {isSender ? (
          // SENDER
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Avatar src={logo} sx={{ width: 36, height: 36 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "0.5rem",
              }}
            >
              <Box
                sx={{
                  padding: "0.8rem 0.5rem",
                  background: "rgb(234, 240, 246)",
                  borderTopRightRadius: "0.3rem",
                  borderBottomRightRadius: "0.3rem",
                  borderBottomLeftRadius: "0.3rem",
                }}
              >
                <Typography variant="body1" alignItems="flex-end">
                  {message?.message}
                </Typography>
              </Box>
              <Typography variant="caption" sx={{ color: "#a3a6a8" }}>
                {getTime(message?.createdAt)}
              </Typography>
            </Box>
          </Box>
        ) : (
          // RECEIVER
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                background: "rgb(34, 33, 36)",
                padding: "0.3rem 0.4rem",
                borderTopRightRadius: "0.3rem",
                borderBottomRightRadius: "0.3rem",
                borderBottomLeftRadius: "0.3rem",
                alignSelf: "flex-end",
              }}
            >
              <Typography variant="body1" sx={{ color: "white" }}>
                {message?.message}
              </Typography>
            </Box>
            <Typography variant="caption" sx={{ color: "#a3a6a8" }}>
              {getTime(message?.createdAt)}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

export default ChatMessage;
