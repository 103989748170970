import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../config/base_url";
import { throwErrorToast, throwSuccessToast } from "src/utils/throw_toast";

const initialState = {
  homeLoanRequestList: [
    {
      id: 1,
      user_id: 4,
      property_id: 1,
      property_type: "house",
      description: "i Need House . can you provide me",
      createdAt: "2024-02-04T08:06:48.000Z",
      updatedAt: "2024-02-04T08:06:48.000Z",
      name: "Nepal Real Estate Solution Pvt Ltd",
      email: "nplrealestates@gmail.com",
      phone_number: "98656975428",
      loan_amount: "17247885",
    },
  ],
  homeLoanRequestListLoading: false,
  homeLoanRequestListError: null,

  //   homeLoanRequestDetails: null,
  //   homeLoanRequestDetailsLoading: false,
  //   homeLoanRequestDetailsError: null,

  isHomeLoanAddLoading: false,
  homeLoanAddError: null,

  isHomeLoanDeleteLoading: false,
  homeLoanDeleteError: null,
};

export const fetchHomeLoanRequestList = createAsyncThunk(
  "homeLoan/fetchHomeLoanRequestList",
  async ({ rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/property/homeLoan`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);

export const addHomeLoanRequestAsync = createAsyncThunk(
  "homeLoan/addHomeLoanRequestAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/homeLoan`,
        data,
        {
          withCredentials: true,
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Added home loan request", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteHomeLoanRequestAsync = createAsyncThunk(
  "homeLoan/deleteHomeLoanRequestAsync",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/property/homeLoan/${id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Deleted Successfully!");
        return id;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const homeLoanSlice = createSlice({
  name: "homeLoan",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchHomeLoanRequestList.pending, (state, action) => {
      state.homeLoanRequestListLoading = true;
      state.homeLoanRequestListError = null;
    });
    builder.addCase(fetchHomeLoanRequestList.fulfilled, (state, action) => {
      state.homeLoanRequestListLoading = false;
      state.homeLoanRequestListError = null;
      state.homeLoanRequestList = action.payload;
    });
    builder.addCase(fetchHomeLoanRequestList.rejected, (state, action) => {
      state.homeLoanRequestListLoading = false;
      state.homeLoanRequestListError = action.payload;
    });

    builder.addCase(addHomeLoanRequestAsync.pending, (state, action) => {
      state.isHomeLoanAddLoading = true;
      state.homeLoanAddError = null;
    });
    builder.addCase(addHomeLoanRequestAsync.fulfilled, (state, action) => {
      state.isHomeLoanAddLoading = false;
      state.homeLoanAddError = null;
      state.homeLoanRequestList.push(action.payload);
    });
    builder.addCase(addHomeLoanRequestAsync.rejected, (state, action) => {
      state.isHomeLoanAddLoading = false;
      state.homeLoanAddError = action.payload;
    });

    builder.addCase(deleteHomeLoanRequestAsync.pending, (state, action) => {
      state.isHomeLoanDeleteLoading = true;
      state.homeLoanDeleteError = null;
    });
    builder.addCase(deleteHomeLoanRequestAsync.fulfilled, (state, action) => {
      state.isHomeLoanDeleteLoading = false;
      state.homeLoanDeleteError = null;
      state.homeLoanRequestList = state.homeLoanRequestList.filter(
        (item) => item._id !== action.payload
      );
    });
    builder.addCase(deleteHomeLoanRequestAsync.rejected, (state, action) => {
      state.isHomeLoanDeleteLoading = false;
      state.homeLoanDeleteError = action.payload;
    });
  },
});

export default homeLoanSlice.reducer;
