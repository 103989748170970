import React from "react";
import { Box, Typography } from "@mui/material";
import ShowMap from "../map/show_map_location";
function ShootScheduleDetailPopup({ selectedItem }) {
  return (
    <Box>
      <Box>
        <Typography variant="h5" textAlign={"center"}>
          For Sale
        </Typography>
        <Typography
          variant="body1"
          textAlign={"center"}
          sx={{ fontWeight: "bold" }}
        >
          {/* print date and time */}
          {selectedItem?.scheduled_date.split("T")[0]}{" "}
        </Typography>
        <hr />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: "2rem",
        }}
      >
        <table>
          <tbody>
            <tr>
              <td>
                <strong>Property : </strong>
              </td>
              <td>{selectedItem?.property_type}</td>
            </tr>
            <tr>
              <td>
                <strong>Location : </strong>{" "}
              </td>
              <td>{selectedItem?.location}</td>
            </tr>
            <tr>
              <td>
                <strong>Owner Name : </strong>
              </td>
              <td>{selectedItem?.owner}</td>
            </tr>
            <tr>
              <td>
                <strong>Contact : </strong>
              </td>
              <td>{selectedItem?.contact}</td>
            </tr>
            <tr>
              <td>
                <strong>Scheduled Date : </strong>
              </td>
              <td> {selectedItem?.scheduled_date.split("T")[0]} </td>
            </tr>
          </tbody>
        </table>
      </Box>
      <ShowMap
        longitude={selectedItem?.longitude || 85.307258}
        latitude={selectedItem?.latitude || 27.670799}
      />
    </Box>
  );
}

export default ShootScheduleDetailPopup;
