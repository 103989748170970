import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TableRow,
  Typography,
  TablePagination,
  InputAdornment,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { getComparator, stableSort } from "../../utils/dashboard_table";
import { searchStaff } from "../../redux/staffMgmtSlice";
import { fetchStaffList } from "../../redux/staffMgmtSlice";
import AddStaff from "./add_staff_popup";

import DeleteStaffPopup from "./delete_staff_popup";
import AdminAcessPopup from "./admin_acess_popup";

import EnhancedTableHead from "../table/EnhancedTableHead";

const headCells = [
  {
    id: "s_id",

    disablePadding: true,
    label: "S_ID",
    sort: true,
  },
  {
    id: "full_name",
    disablePadding: false,
    label: "Full Name",
    sort: true,
  },
  {
    id: "responsibility",

    disablePadding: false,
    label: "Responsibility",
    sort: true,
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    sort: true,
  },
  {
    id: "contact",
    disablePadding: false,
    label: "Contact",
    sort: true,
  },
  {
    id: "admin_access",
    disablePadding: false,
    label: "Admin Access",
    sort: true,
  },
  // {
  //   id: "is_active",
  //   disablePadding: false,
  //   label: "Active",
  //   sort: true,
  // },
];
function createData(
  s_id,
  admin_id,
  full_name,
  responsibility,
  email,
  contact,
  account_access
  // isActive
) {
  return {
    s_id,
    admin_id,
    full_name,
    responsibility,
    email,
    contact,
    account_access,
    // isActive,
  };
}

export default function StaffList({
  selectedId,
  setSelectedId,
  setActionSelected,
}) {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openAdminAcessDialog, setOpenAdminAcessDialog] = React.useState(false);
  // const [adminId, setAdminId] = React.useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("s_id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [addStaffDialog, setAddStaffDialog] = React.useState(false);

  const [searchQuery, setSearchQuery] = React.useState("");
  const staffList = useSelector((state) => state.staffMgmt.staffList);
  const staffListLoading = useSelector(
    (state) => state.staffMgmt.staffListLoading
  );

  useEffect(() => {
    dispatch(fetchStaffList());
  }, [dispatch]);

  const rows = useMemo(() => {
    return staffList?.map((staff) => {
      return (
        createData(
          staff?.staff_id,
          staff?.admin_id,
          staff?.name,
          staff?.responsibility,
          staff?.email,
          staff?.contact,
          staff?.account_access
          // staff?.isActive
        ) || []
      );
    });
  }, [staffList]);

  const handleSearch = () => {
    dispatch(
      searchStaff({
        staffSearchQuery: searchQuery,
      })
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (s_id, admin_id) => {
    if (selectedId === null) {
      setSelectedId(s_id);
      // setAdminId(admin_id);
    } else {
      setActionSelected("staffGraph");
      setSelectedId(null);
      // setAdminId(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {staffListLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    gap: "1.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    id="Staff List"
                  >
                    Staff List
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddIcon />}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => setAddStaffDialog(true)}
                  >
                    Add New Staff
                  </Button>
                </Box>
                {selectedId !== null ? (
                  <>
                    <Tooltip title="Admin Access">
                      <IconButton
                        onClick={() => {
                          setOpenAdminAcessDialog(true);
                        }}
                      >
                        <AdminPanelSettingsIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit">
                      <IconButton
                        onClick={() => setActionSelected("staffEdit")}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Staff Details">
                      <IconButton
                        onClick={() => setActionSelected("staffDetails")}
                      >
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Box display={selectedId ? "none" : "block"}>
                      <TextField
                        sx={{ ml: "auto", mr: 1 }}
                        id="outlined-basic"
                        label="Search"
                        type="text"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        // inputRef={(input) => input && input.focus()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              sx={{ cursor: "pointer" }}
                            >
                              <SearchIcon onClick={() => handleSearch()} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </>
                )}
              </Toolbar>
            </Box>
            {staffList?.length >= 1 ? (
              <>
                <TableContainer
                  sx={{ background: "rgba(246, 237, 237, 0.60)" }}
                >
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows?.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows?.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={() => handleClick(row.s_id, row.admin_id)}
                            role="checkbox"
                            aria-checked={selectedId === row.s_id}
                            tabIndex={-1}
                            key={index}
                            selected={selectedId === row.s_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={selectedId === row.s_id}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">{row?.full_name}</TableCell>
                            <TableCell align="left">
                              {row?.responsibility}
                            </TableCell>
                            <TableCell align="left">{row?.email}</TableCell>
                            <TableCell align="left">{row?.contact}</TableCell>
                            <TableCell align="left">
                              {row?.admin_id ? "True" : "False"}
                            </TableCell>
                            {/* <TableCell align="left">
                              {row?.isActive + ""}
                            </TableCell> */}
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>Staff not found</h1>
              </Box>
            )}
          </>
        )}
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteStaffPopup
            selectedId={selectedId}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAdminAcessDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions sx={{ background: "#F6EDED" }}>
          <Button onClick={() => setOpenAdminAcessDialog(false)}>
            <CloseIcon sx={{ color: "black" }} />
          </Button>
        </DialogActions>
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AdminAcessPopup
            staffId={selectedId}
            setOpenAdminAcessDialog={setOpenAdminAcessDialog}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={addStaffDialog} onClose={() => setAddStaffDialog(false)}>
        <DialogContent
          sx={{
            padding: "9px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AddStaff setAddStaffDialog={setAddStaffDialog} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
