import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
  Grid,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchRecentPropertyDetailsById,
  fetchSoldPropertyDetailsById,
  fetchUnApprovedPropertyDetailsById,
} from "../../../redux/overviewSlice";
// import { splitLocation } from "../../../utils/split_location";

import NoImage from "../../../assets/images/NoImage.jpg";

import ShowImageDialog from "../../image_dialog/show_image_dialog";
import { formatPrice } from "../../../utils/formatPrice";
import SocialMediaDetails from "./social_media_details";

const PropertyDetailsLand = ({ selectedId, from }) => {
  const dispatch = useDispatch();
  const [showBasicDetails, setShowBasicDetails] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const isLoading = useSelector((state) => state.overview.isLoading);

  const propertyDetails = useSelector(
    (state) => state.overview.propertyDetails
  );
  useEffect(() => {
    if (from === "recent") {
      dispatch(
        fetchRecentPropertyDetailsById({
          property_id: selectedId,
          endPoint: "land",
        })
      );
    } else if (from === "sold") {
      dispatch(
        fetchSoldPropertyDetailsById({
          property_id: selectedId,
          endPoint: "land",
        })
      );
    } else if (from === "unapprove") {
      dispatch(
        fetchUnApprovedPropertyDetailsById({
          property_id: selectedId,
          endPoint: "land",
        })
      );
    }
  }, [dispatch, selectedId, from]);

  let imagesLink = "";
  let amenities = {};
  if (typeof propertyDetails?.property_image === "string") {
    imagesLink = propertyDetails.property_image
      ? Object.values(JSON.parse(propertyDetails.property_image))
      : [];
  } else {
    imagesLink = propertyDetails?.property_image
      ? Object.values(propertyDetails.property_image)
      : [];
  }
  if (typeof propertyDetails?.amenities === "string") {
    amenities = propertyDetails?.amenities
      ? JSON.parse(propertyDetails?.amenities)
      : {};
  } else {
    amenities = propertyDetails?.amenities ? propertyDetails?.amenities : {};
  }

  const handleOpenModal = (index) => {
    setModalImageIndex(index);
    setModalOpen(true);
  };
  const toggleDetails = () => {
    setShowBasicDetails((prev) => !prev);
  };

  return (
    <Paper
      sx={{
        padding: "16px",
        minWidth: "22rem",
        width: "100%",
        maxWidth: "25rem",
        maxHeight: "25rem",
        height: "100%",
        overflow: "auto",
      }}
    >
      {isLoading ? (
        <Box>
          <CircularProgress />
          <Typography variant="body2">Loading...</Typography>
        </Box>
      ) : propertyDetails ? (
        showBasicDetails ? (
          <>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Property Id : </strong>
              {propertyDetails.property_id}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Property Title : </strong>
              {propertyDetails?.property_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Owner Name : </strong>
              {propertyDetails?.owner_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Owner Contact : </strong>
              {propertyDetails?.owner_phone}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Property Type : </strong>
              {propertyDetails?.property_type}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Property For : </strong> {propertyDetails.property_for}
            </Typography>
            {/* Render other basic details */}
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Listed for : </strong>
              {propertyDetails.listed_for}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Twist : </strong>
              {propertyDetails.twist}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Location : </strong>
              {propertyDetails?.province}, {propertyDetails?.district},
              {propertyDetails?.municipality},{propertyDetails?.ward}
            </Typography>{" "}
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Facing : </strong>
              {propertyDetails.facing}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Area Name : </strong>
              {propertyDetails.area_name}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Landmark : </strong>
              {propertyDetails.landmark}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Price : </strong>
              Rs {formatPrice(propertyDetails.price)},
              {propertyDetails?.price_per}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Price Type : </strong>
              {propertyDetails.price_type}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Land Area : </strong>
              {propertyDetails.land_area}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Images : </strong>
            </Typography>
            <Box>
              {!imagesLink || imagesLink.length === 0 ? (
                <Box
                  src=""
                  alt="No Image"
                  className="main__image__fixed--height"
                >
                  <img
                    src={NoImage}
                    alt="Could not load"
                    style={{ width: "60px", height: "60px" }}
                  />
                </Box>
              ) : (
                <Grid container spacing={1}>
                  {imagesLink?.map((image, index) => (
                    <Grid
                      item
                      onClick={() => handleOpenModal(index)}
                      key={index}
                    >
                      <img
                        src={`${image}`}
                        alt="property"
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Road Access : </strong>
              {propertyDetails.road_access} feet
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Bike Parking : </strong>
              {propertyDetails.parking_bike}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Car Parking : </strong>
              {propertyDetails.parking_car}
            </Typography>
            {/* Ameneties */}
            <SocialMediaDetails
              SocialMediaDetails={propertyDetails?.social_media}
            />
            <Typography variant="body2" sx={{ mb: "0.4rem" }}>
              <strong>Views : </strong>
              {propertyDetails.views}
            </Typography>
            <Box
              sx={{
                display: "flex",
                position: "relative",
                border: "2px solid black",
                padding: "8px",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  mb: "0.1rem",
                  position: "absolute",
                  top: "-10px",
                  left: "10px",
                  backgroundColor: "white",
                }}
              >
                <strong>Ameneties</strong>
              </Typography>

              <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
                <Typography variant="body2" sx={{ mb: "0.1rem" }}>
                  <strong>Water Supply : </strong>
                  {amenities.water_supply ? "Yes" : "No"}
                </Typography>
                <Typography variant="body2" sx={{ mb: "0.1rem" }}>
                  <strong>Sewer : </strong>
                  {amenities.sewer ? "Yes" : "No"}
                </Typography>

                <Typography variant="body2" sx={{ mb: "0.1rem" }}>
                  <strong>Electricity Supply : </strong>
                  {amenities.electricity_supply ? "Yes" : "No"}
                </Typography>
              </Box>
            </Box>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Description : </strong>
              {propertyDetails.description}
            </Typography>
          </>
        )
      ) : (
        <h2>Cannot find data</h2>
      )}
      <Box mt={2}>
        <Button variant="contained" onClick={toggleDetails}>
          {showBasicDetails ? "View More" : "View Less"}
        </Button>
      </Box>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent
          sx={{
            overflow: "hidden",
            scrollbarWidth: "none",
            padding: "0",
            position: "relative",
            width: "100%",
          }}
        >
          <ShowImageDialog
            setModalOpen={setModalOpen}
            imagesLink={imagesLink}
            modalImageIndex={modalImageIndex}
            setModalImageIndex={setModalImageIndex}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default PropertyDetailsLand;
