import React, { useEffect } from "react";
import { Grid, Dialog, DialogContent, Slide, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import RequestMoreInfoCard from "./request_more_info_card";
import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";
import {
  fetchRequestMoreInfoListAsync,
  deleteRequestMoreByIdAsync,
} from "src/redux/requestMoreInfoSlice";
import DeletePopup from "../popup/delete_popup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function RequestMoreInfoList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(null);

  const requestMoreInfoList = useSelector(
    (state) => state.requestMoreInfo.requestMoreInfoList
  );
  const isRequestMoreInfoListLoading = useSelector(
    (state) => state.requestMoreInfo.isRequestMoreInfoListLoading
  );
  const requestMoreInfoListError = useSelector(
    (state) => state.requestMoreInfo.requestMoreInfoListError
  );

  useEffect(() => {
    dispatch(fetchRequestMoreInfoListAsync());
  }, [dispatch]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    dispatch(deleteRequestMoreByIdAsync(selectedId)).then((action) => {
      if (deleteRequestMoreByIdAsync.fulfilled.match(action)) {
        setOpenDeleteDialog(false);
      }
    });
  };

  return (
    <div style={{ marginTop: "1rem" }}>
      <Box sx={{ padding: "2rem 4rem" }}>
        {isRequestMoreInfoListLoading || requestMoreInfoListError ? (
          <ShowLoaderError
            isLoading={isRequestMoreInfoListLoading}
            errorMessage={requestMoreInfoListError}
          />
        ) : (
          <Grid container spacing={2}>
            {requestMoreInfoList?.map((item) => (
              <Grid item xs={12} key={item?.id}>
                <RequestMoreInfoCard
                  item={item}
                  setSelectedId={setSelectedId}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        {" "}
        <DialogContent
          sx={{
            padding: "2rem",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeletePopup
            handleDelete={handleDeleteClick}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RequestMoreInfoList;
