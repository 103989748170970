import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { fetchAgentProvinceDetails } from "src/redux/overviewSlice";
function AgentDetails() {
  const dispatch = useDispatch();

  const agentProvinceDetails = useSelector(
    (state) => state.overview?.agentProvinceDetails
  );

  useEffect(() => {
    dispatch(fetchAgentProvinceDetails());
  }, [dispatch]);

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "4rem",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
          {agentProvinceDetails?.agents?.map((item, index) => (
            <Typography
              sx={{
                fontSize: "1rem",
                lineHeight: "normal",
              }}
            >
              Province {index + 1} ({item?.province}) :
              {item?.agentCount || "No Data"}
            </Typography>
          ))}
        </Box>
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: "1.05rem",
              lineHeight: "normal",
            }}
          >
            Total Active Agents :{agentProvinceDetails?.count || "No Data"}
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default AgentDetails;
