import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchFieldVisitRequestCommentsAsync } from "../../redux/fieldVisitRequestSlice";
function FieldRequestComment() {
  const dispatch = useDispatch();
  const fieldVisitRequestCommentList = useSelector(
    (state) => state.fieldVisitRequest.fieldVisitRequestCommentList
  );
  useEffect(() => {
    dispatch(fetchFieldVisitRequestCommentsAsync());
  }, [dispatch]);

  if (!fieldVisitRequestCommentList)
    return (
      <div>
        <Typography variant="h6">Soon</Typography>
      </div>
    );

  return (
    <div>
      <Box
        sx={{ minHeight: "20rem", border: "1px solid black", padding: "2rem" }}
      >
        {fieldVisitRequestCommentList.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid black",
                padding: "1rem",
                marginBottom: "1rem",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ fontWeight: "bold" }}>{item.name}</Box>
                <Box sx={{ fontWeight: "bold" }}>{item.date}</Box>
              </Box>
              <Box sx={{ marginTop: "1rem" }}>{item.comment}</Box>
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

export default FieldRequestComment;
