import React from "react";
import { useDispatch } from "react-redux";
import {
  DialogContent,
  Dialog,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { resetLocation } from "../../redux/locationSlice";

function EditPropertyPopup(props) {
  const { children, openEditDialog, setOpenEditDialog } = props;
  const dispatch = useDispatch();

  const theme = useTheme();

  const isFullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function handleClose(e, reason) {
    if (reason === "backdropClick") return;
    dispatch(resetLocation());
    setOpenEditDialog(false);
  }
  return (
    <Dialog
      open={openEditDialog}
      onClose={handleClose}
      fullScreen={isFullScreen}
    >
      <IconButton
        onClick={handleClose}
        sx={{ position: "absolute", top: "0", right: "0", zIndex: "1000" }}
      >
        <CloseIcon
          sx={{
            fontSize: "2rem",
            color: "#000000",
          }}
        />
      </IconButton>
      <DialogContent
        sx={{
          padding: "9px",
          background: "#F6EDED",
          boxShadow:
            "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default EditPropertyPopup;
