import React, { useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { fetchBackgroundVideoAsync } from "../../redux/adsSlice";
import AddBackgroundVideo from "./add_background_video";
function BackgroundVideo() {
  const dispatch = useDispatch();

  const errorMessage = useSelector((state) => state.ads.errorMessage);
  const getVideoLoading = useSelector((state) => state.ads.getVideoLoading);
  const currentVideoUrl = useSelector((state) => state.ads.currentVideoUrl);

  useEffect(() => {
    // if (currentVideoUrl === "") {
    dispatch(fetchBackgroundVideoAsync());
    // }
  }, [dispatch]);

  return (
    <Box
      sx={{
        mb: "2rem",
        backgroundColor: "#f4f4f4",
        padding: "2rem 1rem",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <AddBackgroundVideo />

      <Box sx={{ mt: "2rem" }}>
        <Typography variant="h5">Current Video :</Typography>
        {getVideoLoading ? (
          <Box sx={{ mt: "4rem" }}>
            <CircularProgress />
          </Box>
        ) : errorMessage ? (
          <Typography
            variant="h5"
            sx={{
              color: "rgb(200,0,0)",
              mt: "2rem",
              padding: "2rem",
            }}
          >
            {errorMessage}
          </Typography>
        ) : (
          <Box sx={{ marginTop: "2rem" }}>
            <ReactPlayer
              url={currentVideoUrl}
              muted={true}
              loop={true}
              playing={true}
              controls={true}
              style={{ marginTop: "-0.5rem" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default BackgroundVideo;
