import React from "react";
import { Container } from "@mui/material";
import AdminDashboard from "../../component/dashboard/admin_dashboard.components";
function AdminDashboardPage() {
  return (
    <Container maxWidth="false" disableGutters>
      <AdminDashboard />
    </Container>
  );
}

export default AdminDashboardPage;
