import React from "react";
import CustomerListTableBuyer from "./overview_customer_list_table_buyer";
import CustomerListTableSeller from "./overview_customer_list_table_seller";
import CustomerListAll from "./overview_customer_list_table_all";
function OverviewCustomerList() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5rem",
      }}
    >
      <CustomerListTableSeller />
      <CustomerListTableBuyer />
      <CustomerListAll />
    </div>
  );
}

export default OverviewCustomerList;
