import React from "react";
// import ServicesList from "./services_list.test";
import ServiceTableMain from "./services_table_main";
function AdminDashboardServices() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5rem",
      }}
    >
      {/* <ServicesList
        selectedId={selectedId}
        setActionSelected={setActionSelected}
        setSelectedId={setSelectedId}
      /> */}
      <ServiceTableMain />
      {/* <div style={{ width: "100%" }}>
        {actionSelected === "serviceDetails" && selectedId && (
          <ServiceDetail selectedId={selectedId} />
        )}
      </div> */}
    </div>
  );
}

export default AdminDashboardServices;
