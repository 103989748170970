import React, { useState } from "react";
import {
  Box,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";

function ChangeListingPopup({
  selectedId,
  setOpenListingDialog,
  handleChangeListing,
}) {
  const [selectedListing, setSelectedListing] = useState("");

  const handleCloseListingDialog = () => {
    setOpenListingDialog(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">
          Change Listing of id = {selectedId}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedListing}
          onChange={(e) => setSelectedListing(e.target.value)}
        >
          <FormControlLabel value="free" control={<Radio />} label="Free" />
          <FormControlLabel value="top" control={<Radio />} label="Top" />
          <FormControlLabel
            value="featured"
            control={<Radio />}
            label="Feature"
          />
          <FormControlLabel
            value="premium"
            control={<Radio />}
            label="Premium"
          />
        </RadioGroup>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={handleCloseListingDialog}
          variant="contained"
          color="error"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleChangeListing(selectedListing)}
          variant="contained"
          sx={{
            backgroundColor: "black",
            ":hover": {
              backgroundColor: "#1c1c1c",
            },
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
}

export default ChangeListingPopup;
