import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  //Background Video State
  isBackgroundUpdateLoading: false,
  getVideoLoading: false,
  errorMessage: null,
  currentVideoUrl: "",

  //Video Carousel State

  isAddVideoCarouselLoading: false,
  isFetchVideoCarouselLoading: false,
  isDeleteVideoCarouselLoading: false,
  videoCarouselFetchError: "",
  videoCarouselList: [],

  //Testimonial Carousel State

  isAddTestimonialCarouselLoading: false,
  isFetchTestimonialCarouselLoading: false,
  isDeleteTestimonialCarouselLoading: false,
  testimonialFetchError: "",
  testimonialList: [],
};
export const fetchBackgroundVideoAsync = createAsyncThunk(
  "ads/fetchBackgroundVideoAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/ads`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data.link;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const fetchVideoCarouselListAsync = createAsyncThunk(
  "ads/fetchVideoCarouselListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/videoCarousel`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const fetchTestimonialCarouselListAsync = createAsyncThunk(
  "ads/fetchTestimonialListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/testimonial`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const updateBackgroundVideoAsync = createAsyncThunk(
  "ads/updateBackgroundVideoAsync",
  async ({ videoLink }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/ads`,
        { link: videoLink },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Video link updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return videoLink;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const addVideoCarouselAsync = createAsyncThunk(
  "ads/addVideoCarouselAsync",
  async (videoLink, { rejectWithValue }) => {
    //todo fix api
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/videoCarousel`,
        { link: videoLink },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Video link added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return response.data.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const addTestimonialAsync = createAsyncThunk(
  "ads/addTestimonialAsync",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/testimonial`, data, {
        withCredentials: true,
      });
      if (response.status === 200) {
        toast.success("Testimonial added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response.data?.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteTestimonialByIdAsync = createAsyncThunk(
  "ads/deleteTestimonialByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/testimonial/${id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Delete Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return id;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const deleteVideoCarouselByIdAsync = createAsyncThunk(
  "ads/deleteVideoCarouselByIdAsync",
  async (id, { rejectWithValue }) => {
    console.log("id", id);
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/videoCarousel/${id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return id;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const adsSlice = createSlice({
  name: "ads",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBackgroundVideoAsync.pending, (state) => {
        state.getVideoLoading = true;
      })
      .addCase(fetchBackgroundVideoAsync.fulfilled, (state, action) => {
        state.getVideoLoading = false;
        state.currentVideoUrl = action.payload;
        state.errorMessage = "";
      })
      .addCase(fetchBackgroundVideoAsync.rejected, (state, action) => {
        state.getVideoLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(fetchVideoCarouselListAsync.pending, (state) => {
        state.isFetchVideoCarouselLoading = true;
      })
      .addCase(fetchVideoCarouselListAsync.fulfilled, (state, action) => {
        state.isFetchVideoCarouselLoading = false;
        state.videoCarouselList = action.payload;
      })
      .addCase(fetchVideoCarouselListAsync.rejected, (state, action) => {
        state.isFetchVideoCarouselLoading = false;
        state.videoCarouselFetchError = action.payload;
      })
      .addCase(fetchTestimonialCarouselListAsync.pending, (state) => {
        state.isFetchTestimonialCarouselLoading = true;
      })
      .addCase(fetchTestimonialCarouselListAsync.fulfilled, (state, action) => {
        state.isFetchTestimonialCarouselLoading = false;
        state.testimonialList = action.payload;
      })
      .addCase(fetchTestimonialCarouselListAsync.rejected, (state, action) => {
        state.isFetchTestimonialCarouselLoading = false;
        state.testimonialFetchError = action.payload;
      })
      .addCase(updateBackgroundVideoAsync.pending, (state) => {
        state.isBackgroundUpdateLoading = true;
      })
      .addCase(updateBackgroundVideoAsync.fulfilled, (state, action) => {
        state.isBackgroundUpdateLoading = false;
        state.errorMessage = null;
        state.currentVideoUrl = action.payload;
      })
      .addCase(updateBackgroundVideoAsync.rejected, (state, action) => {
        state.isBackgroundUpdateLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(addVideoCarouselAsync.pending, (state) => {
        state.isAddVideoCarouselLoading = true;
      })
      .addCase(addVideoCarouselAsync.fulfilled, (state, action) => {
        state.isAddVideoCarouselLoading = false;

        state.videoCarouselList = [...state.videoCarouselList, action.payload];
      })
      .addCase(addVideoCarouselAsync.rejected, (state) => {
        state.isAddVideoCarouselLoading = false;
      })
      .addCase(addTestimonialAsync.pending, (state) => {
        state.isAddTestimonialCarouselLoading = true;
      })
      .addCase(addTestimonialAsync.fulfilled, (state, action) => {
        state.isAddTestimonialCarouselLoading = false;
        state.testimonialList = [...state.testimonialList, action.payload];
      })
      .addCase(addTestimonialAsync.rejected, (state) => {
        state.isAddTestimonialCarouselLoading = false;
      })

      .addCase(deleteVideoCarouselByIdAsync.pending, (state) => {
        state.isDeleteVideoCarouselLoading = true;
      })
      .addCase(deleteVideoCarouselByIdAsync.fulfilled, (state, action) => {
        state.isDeleteVideoCarouselLoading = false;
        state.videoCarouselList = state.videoCarouselList.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteVideoCarouselByIdAsync.rejected, (state) => {
        state.isDeleteVideoCarouselLoading = false;
      })
      .addCase(deleteTestimonialByIdAsync.pending, (state) => {
        state.isDeleteTestimonialCarouselLoading = true;
      })
      .addCase(deleteTestimonialByIdAsync.fulfilled, (state, action) => {
        state.isDeleteTestimonialCarouselLoading = false;
        state.testimonialList = state.testimonialList.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteTestimonialByIdAsync.rejected, (state) => {
        state.isDeleteTestimonialCarouselLoading = false;
      });
  },
});

export default adsSlice.reducer;
