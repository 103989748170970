import React from "react";
import logo from "../../assets/images/logo.png";
import { Box, Typography, Avatar } from "@mui/material";
import { BASE_URL } from "../../config/base_url";
import { getTime } from "../../utils/get_datetime";
function RenderImage({ message, setOpenImageDialog, setImageUrl }) {
  const isSender = message.sender_id !== Number(0);
  return (
    <React.Fragment>
      <Box
        sx={{
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          alignItems: isSender ? "flex-start" : "flex-end",
          marginRight: isSender ? "4rem" : "0",
          marginLeft: isSender ? "0" : "4rem",
          marginTop: "1.5rem",
        }}
      >
        {isSender ? (
          // SENDER
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Avatar src={logo} sx={{ width: 36, height: 36 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "0.1rem",
              }}
            >
              <Box
                sx={{
                  borderTopRightRadius: "0.3rem",
                  borderBottomRightRadius: "0.3rem",
                  borderBottomLeftRadius: "0.3rem",
                }}
              >
                <img
                  src={`${BASE_URL}/${message.imageURL}`}
                  alt="could not load"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    setImageUrl(`${BASE_URL}/${message.imageURL}`);
                    setOpenImageDialog(true);
                  }}
                />
              </Box>
              <Typography variant="caption" sx={{ color: "#a3a6a8" }}>
                {getTime(message?.createdAt)}
              </Typography>
            </Box>
          </Box>
        ) : (
          // RECEIVER
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                padding: "0.3rem 0.4rem",
                borderTopRightRadius: "0.3rem",
                borderBottomRightRadius: "0.3rem",
                borderBottomLeftRadius: "0.3rem",
                alignSelf: "flex-end",
              }}
            >
              <img
                src={`${BASE_URL}/${message.imageURL}`}
                alt="could not load"
                style={{ width: "100%", height: "100%" }}
                loading="lazy"
                onClick={() => {
                  setImageUrl(`${BASE_URL}/${message.imageURL}`);
                  setOpenImageDialog(true);
                }}
              />
            </Box>
            <Typography variant="caption" sx={{ color: "#a3a6a8" }}>
              {getTime(message?.createdAt)}
            </Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
}

export default RenderImage;
