import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";
import { fetchReadyToPostListAsync } from "../../redux/shootScheduleSlice";

import SearchBar from "./search_bar";
import RenderScheduleList from "./render_scheduled_list";
function ReadyToPostMain({
  handleOpenCommentPopup,
  handleOpenDeletePopup,
  handleOpenDetailPopup,
}) {
  const dispatch = useDispatch();

  const readyToPostList = useSelector(
    (state) => state.shootSchedule.readyToPostList
  );
  const isReadyToPostListLoading = useSelector(
    (state) => state.shootSchedule.isReadyToPostListLoading
  );
  const readyToPostError = useSelector(
    (state) => state.shootSchedule.readyToPostError
  );

  useEffect(() => {
    dispatch(fetchReadyToPostListAsync());
  }, [dispatch]);

  function handleOpenStatusChangePopup(id) {
    toast.success("Property is ready to post");
  }
  const statusIcon = (
    <Tooltip title="Ready">
      <PublishedWithChangesIcon sx={{ color: "green" }} />
    </Tooltip>
  );
  return (
    <Box>
      <Box sx={{ height: "82vh", position: "relative" }}>
        <Box
          sx={{
            width: "35%",
            marginLeft: { sm: "40%", xs: "0", md: "55%" },
            marginBottom: "1%",
            borderRadius: "0.3rem",
            minWidth: "250px",
          }}
        >
          <SearchBar />
        </Box>
        {isReadyToPostListLoading || readyToPostError ? (
          <ShowLoaderError
            isLoading={isReadyToPostListLoading}
            errorMessage={readyToPostError}
          />
        ) : (
          <Box
            sx={{
              height: "68vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <RenderScheduleList
              handleOpenCommentPopup={handleOpenCommentPopup}
              handleOpenDeletePopup={handleOpenDeletePopup}
              shootScheduleList={readyToPostList}
              handleOpenDetailPopup={handleOpenDetailPopup}
              handleOpenStatusChangePopup={handleOpenStatusChangePopup}
              statusIcon={statusIcon}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ReadyToPostMain;
