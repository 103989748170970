import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import "leaflet/dist/leaflet.css";
import markerIcon from "./marker_icon";
import CloseIcon from "@mui/icons-material/Close";

import { Dialog, IconButton, DialogTitle, DialogContent } from "@mui/material";
function ShowMap({ latitude, longitude }) {
  const [openDialog, setOpenDialog] = useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  return (
    <div style={{ position: "relative" }}>
      <IconButton
        sx={{
          position: "absolute",
          right: "0",
          top: "0",
          zIndex: "1000",
        }}
        onClick={handleClickOpen}
      >
        <OpenInFullIcon />
      </IconButton>
      <MapContainer
        center={[latitude, longitude]}
        zoom={16}
        scrollWheelZoom={false}
        style={{ height: "300px", width: "100%" }}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker position={[latitude, longitude]} icon={markerIcon}>
          <Popup>Property Location</Popup>
        </Marker>
      </MapContainer>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Property Location
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {" "}
          <MapContainer
            zoom={13}
            center={[latitude, longitude]}
            style={{ height: "500px", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[latitude, longitude]} icon={markerIcon}>
              <Popup>Property Location</Popup>
            </Marker>
          </MapContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ShowMap;
