import React from "react";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSellerDetailsById } from "../../../redux/overviewSlice";
import SellerPropertiesList from "./seller_detail_table";
// const sellerDetails = {
//   user_id: 9,
//   name: "Amir Poudel",
//   email: "amirpoudel2058@gmail.com",
//   phone_number: "9863505957",
//   property_view_clients: [
//     {
//       property_type: "house",
//       property_id: 18,
//       listed_for: "sale",
//       price: "30000000.00",
//       province: "Bagmati",
//       district: "Kathmandu",
//       municipality: "Kathmandu Metropolitan",
//       area_name: "Thamel",
//     },
//   ],
// };

function OverviewSellerDetails({ selectedId, setOpenDetailsDialog }) {
  const dispatch = useDispatch();
  const sellerDetails = useSelector((state) => state.overview.sellerDetails);
  const isLoading = useSelector((state) => state.overview.isLoading);
  useEffect(() => {
    dispatch(fetchSellerDetailsById({ sellerId: selectedId }));
  }, [selectedId, dispatch]);
  return (
    <Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{ width: 48, height: 48 }}
            />{" "}
            <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
              {sellerDetails?.name}
            </Typography>
            <Typography>{sellerDetails?.email}</Typography>
            <Typography>{sellerDetails?.phone_number}</Typography>
            {/* <Typography>9874124785</Typography> */}
          </Box>
          <div>
            <SellerPropertiesList
              sellerPropertiesList={sellerDetails?.property_view_clients}
            />
          </div>
        </>
      )}
    </Box>
  );
}

export default OverviewSellerDetails;
