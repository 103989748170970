import React, { useMemo } from "react";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  TableRow,
  Typography,
  TablePagination,
} from "@mui/material";

import { getComparator, stableSort } from "../../../utils/dashboard_table";

import EnhancedTableHead from "../../table/EnhancedTableHead";

const headCells = [
  {
    id: "p_id",

    label: "P_ID",
    sort: true,
  },
  {
    id: "address",
    disablePadding: false,
    label: "Address",
    sort: true,
  },
  {
    id: "listed_on",

    disablePadding: false,
    label: "Listed On",
    sort: true,
  },

  {
    id: "price",
    disablePadding: false,
    label: "Price",
    sort: true,
  },
  {
    id: "property_type",
    disablePadding: false,
    label: "Property Type",
    sort: true,
  },
];
function createData(p_id, district, listed_on, price, property_type) {
  return {
    p_id,
    district,
    listed_on,
    price,
    property_type,
  };
}

export default function SellerPropertiesList({ sellerPropertiesList }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("c_id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const rows = useMemo(() => {
    return sellerPropertiesList?.map((property) => {
      return (
        createData(
          property.property_id,
          property.district,
          property.listed_for,
          property.price,
          property.property_type
        ) || []
      );
    });
  }, [sellerPropertiesList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {sellerPropertiesList?.length >= 1 ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    fontWeight: "bold",
                    fontSize: "0.85rem",
                  }}
                  id="Customer List"
                >
                  Added Property
                </Typography>
              </Toolbar>
            </Box>
            <TableContainer sx={{ background: "rgba(246, 237, 237, 0.60)" }}>
              <Table
                sx={{ minWidth: 600 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows?.length}
                  headCells={headCells}
                />
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={index}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>{row.p_id}</TableCell>
                        <TableCell>{row.district}</TableCell>
                        <TableCell>{row.listed_on}</TableCell>
                        <TableCell>{row.price}</TableCell>
                        <TableCell>{row.property_type}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>No Properties</h1>
          </Box>
        )}
      </Paper>
    </Box>
  );
}
