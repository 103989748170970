import React, { useState } from "react";
import { Paper, styled } from "@mui/material";
import ClientChatUserList from "./client_chat_user_list";
import ClientChatContainer from "./client_chat_chat_container";

const ChatContainer = styled(Paper)`
  display: flex;
  height: 79vh;
  width: 100%;
`;

const ClientChat = () => {
  const [activeUserIdName, setActiveUserIdName] = useState("");
  const [activeUserId, setActiveUserId] = useState(null);

  return (
    <ChatContainer>
      <ClientChatUserList
        setActiveUserId={setActiveUserId}
        activeUserId={activeUserId}
        setActiveUserIdName={setActiveUserIdName}
      />
      <ClientChatContainer
        activeUserIdName={activeUserIdName}
        activeUserId={activeUserId}
      />
    </ChatContainer>
  );
};

export default ClientChat;
