import { TableRow, TableCell, Checkbox, Avatar } from "@mui/material";

// ----------------------------------------------------------------------

export default function ServicesTableRow({
  s_id,
  selectedId,
  name,
  profileImage,
  phone_number,
  gender,
  age,
  service_type,
  province,
  district,
  status,
  handleClick,
}) {
  return (
    <TableRow
      hover
      tabIndex={-1}
      sx={{ cursor: "pointer" }}
      role="checkbox"
      selected={selectedId === s_id}
      onClick={() => handleClick(s_id, name, status)}
    >
      <TableCell padding="checkbox">
        <Checkbox color="primary" checked={selectedId === s_id} />
      </TableCell>

      <TableCell sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
        {" "}
        <Avatar
          alt="Remy Sharp"
          src={profileImage}
          sx={{ width: "2rem", height: "2rem" }}
        />
        {name}
      </TableCell>
      {/* <TableCell>{email}</TableCell> */}
      <TableCell>{phone_number}</TableCell>
      <TableCell>{gender}</TableCell>
      <TableCell>{age}</TableCell>
      <TableCell>{service_type}</TableCell>
      <TableCell>{province}</TableCell>
      <TableCell>{district}</TableCell>

      <TableCell>{status}</TableCell>
    </TableRow>
  );
}
