import React from "react";
import {
  Typography,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Avatar,
} from "@mui/material";
import { getDate } from "../../utils/get_datetime";

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        {/* &nbsp; {noCase(notification.description)} */}
        {/* &nbsp;You have new notification from {notification?.user?.name} */}
      </Typography>
    </Typography>
  );

  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}

function NotificationItem({ notification }) {
  const { avatar, title } = renderContent(notification);
  return (
    <ListItemButton
      sx={{
        py: 1.2,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            {getDate(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

export default NotificationItem;
