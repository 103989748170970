import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import { BASE_URL } from "../config/base_url";
import { throwSuccessToast, throwErrorToast } from "src/utils/throw_toast";

const initialState = {
  recentPropertyList: [],
  recentTotalCount: 15,
  recentPropertyLoading: false,
  recentPropertyError: null,
  allProperty: [],
  allPropertyLoading: false,
  allPropertyError: null,
  soldPropertyList: {},

  isAddSoldPropertyLoading: false,
  soldPropertyListLoading: false,
  soldPropertyListError: null,
  unApprovedPropertyList: {},

  unApprovedPropertyListLoading: false,
  unApprovedPropertyListError: null,
  propertyDetails: null,
  propertyComments: [],
  editPropertyData: {},
  editPropertyLoading: false,
  sellerList: [],
  sellerDetails: null,
  buyerList: [],
  buyerDetails: null,
  customerList: [],
  customerDetails: null,
  buyerListLoading: false,
  buyerListError: null,
  sellerListLoading: false,
  sellerListError: null,
  customerListLoading: false,
  customerListError: null,
  agentList: [],
  agentListLoading: false,
  agentListError: null,
  requestList: [],
  requestDetails: {},
  requestListLoading: false,
  requestListError: null,
  isLoading: false,
  customerLoading: false,
  commentLoading: false,
  error: null,

  isAgentProvinceDetailsLoading: false,
  agentProvinceDetails: {},
  agentProvinceDetailsError: null,
};

// ----------------------- START PROPERTY LIST -----------------------

export const fetchRecentProperty = createAsyncThunk(
  "overview/fetchRecentProperty",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/property`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
// export const fetchAllProperty = createAsyncThunk(
//   "overview/fetchAllProperty",
//   async (_, { rejectWithValue }) => {
//     try {
//       const response = await axios.get(`${BASE_URL}/admin/property/all`, {
//         withCredentials: true,
//       });
//       if (response.status === 200) {
//         return response.data;
//       }
//     } catch (err) {
//       const errorMessage =
//         err?.response?.data?.message || "Something went wrong!!";
//       return rejectWithValue(errorMessage);
//     }
//   }
// );
export const fetchSoldPropertyList = createAsyncThunk(
  "overview/fetchSoldPropertyList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/property/sold`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchUnApprovedPropertyList = createAsyncThunk(
  "overview/fetchUnApprovedPropertyList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/property/pending`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchPropertyComments = createAsyncThunk(
  "overview/fetchPropertyComments",
  async ({ property_id, propertyType }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/${propertyType}/comment/${property_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchEditProperty = createAsyncThunk(
  "overview/fetchEditProperty",
  async ({ property_id, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/${endPoint}/${property_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchRecentPropertyDetailsById = createAsyncThunk(
  "overview/fetchRecentPropertyDetailsById",
  async ({ property_id, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/${endPoint}/${property_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchSoldPropertyDetailsById = createAsyncThunk(
  "overview/fetchSoldPropertyDetailsById",
  async ({ property_id, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/${endPoint}/sold/${property_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchUnApprovedPropertyDetailsById = createAsyncThunk(
  "overview/fetchUnApprovedPropertyDetailsById",
  async ({ property_id, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/${endPoint}/pending/${property_id}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const addProperty = createAsyncThunk(
  "overview/addProperty",
  async ({ formData, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/${endPoint}`,
        formData,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Property Added Successfully");
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const addPropertyComment = createAsyncThunk(
  "overview/addPropertyComment",
  async ({ comment, propertyType, property_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/${propertyType}/comment/${property_id}`,
        { comment: comment },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return response.data;
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const addSoldPropertyByIdAsync = createAsyncThunk(
  "overview/addSoldPropertyByIdAsync",
  async ({ propertyId, propertyType }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/${propertyType}/sold/${propertyId}`,
        {},
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Added to sold");
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const editListingType = createAsyncThunk(
  "overview/editListingType",
  async (
    { selectedListing, propertyType, propertyId },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/${propertyType}/listingType/${propertyId}`,
        { listing_type: selectedListing },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Listing Type Updated Successfully");
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Could not change listing!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);

export const editProperty = createAsyncThunk(
  "overview/editProperty",
  async ({ formData, endPoint, property_id }, { rejectWithValue }) => {
    // Display the key/value pairs
    console.log(Array.from(formData.entries()));

    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/${endPoint}/${property_id}`,
        formData,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Property edited successfully");
        return response.data;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const editPropertyAds = createAsyncThunk(
  "overview/editPropertyAds",
  async ({ data, propertyType, propertyId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/${propertyType}/ads/${propertyId}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Property Ads updated");
        return propertyId;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteRecentProperty = createAsyncThunk(
  "overview/deleteRecentProperty",
  async ({ propertyId, propertyType }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/property/${propertyType}/${propertyId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Property delete Successfully");
        return propertyId;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Could not delete!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteUnApprovedProperty = createAsyncThunk(
  "overview/deleteUnApprovedProperty",
  async ({ property_id, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/property/${endPoint}/pending/${property_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Property deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return property_id;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      rejectWithValue(errorMessage);
    }
  }
);

//Delete Image On Edit
export const deletePropertyImage = createAsyncThunk(
  "overview/deletePropertyImage",
  async ({ imageLink, property_id, property_type }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/${property_type}/image/${property_id}`,
        { imageLink },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Image deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return property_id;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Could not delete image";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

// export const deleteSoldProperty = createAsyncThunk(
//   "overview/deleteSoldProperty",
//   async ({ property_id }, { rejectWithValue }) => {}
// );
export const approveCustomerProperty = createAsyncThunk(
  "overview/approveCustomerProperty",
  async ({ property_id, endPoint }, { rejectWithValue }) => {
    const response = await axios.patch(
      `${BASE_URL}/admin/property/${endPoint}/approved/${property_id}`,
      {},
      {
        withCredentials: true,
      }
    );
    if (response.status === 200) {
      toast.success("Property Approved", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return property_id;
    }
    try {
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
// export const searchAllProperty = createAsyncThunk(
//   "overview/searchAllProperty",
//   async ({ listed_for, location, property_type }, { rejectWithValue }) => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/admin/property/?location=${location}&property_type=${property_type}&listed_for=${listed_for}`,
//         {
//           withCredentials: true,
//         }
//       );
//       return response.data;
//     } catch (error) {
//       const errorMessage =
//         error?.response?.data?.message || "Something went wrong!!";
//       return rejectWithValue(errorMessage);
//     }
//   }
// );

export const searchRecentProperty = createAsyncThunk(
  "overview/searchRecentProperty",
  async ({ listed_for, location, property_type }, { rejectWithValue }) => {
    let url = `${BASE_URL}/admin/property?`;
    if (listed_for) url += `listed_for=${listed_for}&`;
    if (location) url += `location=${location}&`;
    if (property_type) url += `property_type=${property_type}`;

    try {
      const response = await axios.get(`${url}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const searchSoldProperty = createAsyncThunk(
  "overview/searchSoldProperty",
  async ({ listed_for, location, property_type }, { rejectWithValue }) => {
    let url = `${BASE_URL}/admin/property/sold?`;
    if (listed_for) url += `listed_for=${listed_for}&`;
    if (location) url += `location=${location}&`;
    if (property_type) url += `property_type=${property_type}`;

    try {
      const response = await axios.get(`${url}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const searchUnApprovedProperty = createAsyncThunk(
  "overview/searchUnApprovedProperty",
  async ({ listed_for, location, property_type }, { rejectWithValue }) => {
    let url = `${BASE_URL}/admin/property/approved?`;
    if (listed_for) url += `listed_for=${listed_for}&`;
    if (location) url += `location=${location}&`;
    if (property_type) url += `property_type=${property_type}`;

    try {
      const response = await axios.get(`${url}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const searchRequestProperty = createAsyncThunk(
  "overview/searchRequestProperty",
  async ({ search }, { rejectWithValue }) => {
    let url = `${BASE_URL}/admin/property/request?`;
    if (search) url += `search=${search}`;

    try {
      const response = await axios.get(`${url}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
// ----------------------- END PROPERTY LIST -----------------------

// ----------------------- START CUSTOMER LIST -----------------------

// fetch customer list
export const fetchCustomerList = createAsyncThunk(
  "overview/fetchCustomerList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/customer`, {
        withCredentials: true,
      });
      return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

//fetch buyer list
export const fetchBuyerList = createAsyncThunk(
  "overview/fetchBuyerList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/customer/buyer`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
//fetch seller list
export const fetchSellerList = createAsyncThunk(
  "overview/fetchSellerList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/customer/seller`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchCustomerDetailsById = createAsyncThunk(
  "overview/fetchCustomerDetailsById",
  async ({ customerId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/customer/${customerId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchSellerDetailsById = createAsyncThunk(
  "overview/fetchSellerDetailsById",
  async ({ sellerId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/customer/seller/${sellerId}`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchBuyerDetailsById = createAsyncThunk(
  "overview/fetchBuyerDetailsById",
  async ({ buyerId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/customer/buyer`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const searchSeller = createAsyncThunk(
  "overview/searchSeller",
  async (searchQuery, { rejectWithValue }) => {
    let url = `${BASE_URL}/seller`;
    try {
      const response = await axios.get(`${url}`);
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const searchBuyer = createAsyncThunk(
  "overview/searchBuyer",
  async (searchQuery, { rejectWithValue }) => {
    let url = `${BASE_URL}/buyer`;
    try {
      const response = await axios.get(`${url}`);
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const searchCustomer = createAsyncThunk(
  "overview/searchCustomer",
  async (searchQuery, { rejectWithValue }) => {
    let url = `${BASE_URL}/buyer`;
    try {
      const response = await axios.get(`${url}`);
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "overview/deleteCustomer",
  async ({ userId, endPoint }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/customer/${endPoint}/${userId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Customer deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return userId;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteBuyer = createAsyncThunk(
  "overview/deleteBuyer",
  async ({ buyerId, endPoint }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/buyer/${endPoint}/${buyerId}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        toast.success("Buyer deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return buyerId;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
);
export const deleteSeller = createAsyncThunk(
  "overview/deleteSeller",
  async ({ sellerId, endPoint }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/seller/${endPoint}/${sellerId}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        toast.success("Seller deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return sellerId;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }
);
// ----------------------- END CUSTOMER LIST -----------------------

// ----------------------- START AGENT LIST -----------------------
export const fetchAgentList = createAsyncThunk(
  "overview/fetchAgentList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/agent`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data?.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAgentDetailsById = createAsyncThunk(
  "overview/fetchAgentDetailsById",
  async ({ agentId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/agent/${agentId}`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const verifyAgentById = createAsyncThunk(
  "overview/verifyAgentById",
  async ({ agentId, status }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/agent/${agentId}`,
        { status: status },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Status updated  Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchAgentProvinceDetails = createAsyncThunk(
  "overview/fetchAgentProvinceDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/agent/count`, {
        withCredentials: true,
      });
      if (response?.status === 200) return response?.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Could not fetch Province Details!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteAgentById = createAsyncThunk(
  "overview/deleteAgentById",
  async ({ agentId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/agent/${agentId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        throwSuccessToast("Agent Deleted Successfully.");
        return agentId;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
// ----------------------- END AGENT LIST -----------------------

// ----------------------- START REQUEST LIST -----------------------

// Send Request Property Form Data
export const fetchRequestList = createAsyncThunk(
  "overview/fetchRequestList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/property/request`, {
        withCredentials: true,
      });
      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchRequestDetailById = createAsyncThunk(
  "overview/fetchRequestDetailById",
  async ({ requestId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/request/${requestId}`,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) return response.data;
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const requestProperty = createAsyncThunk(
  "overview/requestProperty",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/request`,
        data,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Property requested successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteRequestById = createAsyncThunk(
  "overview/deleteRequestById",
  async ({ requestId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/property/request/${requestId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Request deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return requestId;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
// ----------------------- END REQUEST LIST -----------------------

const overviewSlice = createSlice({
  name: "overview",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchRecentProperty.pending, (state) => {
        state.recentPropertyLoading = true;
      })
      .addCase(fetchRecentProperty.fulfilled, (state, action) => {
        const { totalCount, properties } = action.payload;
        state.recentPropertyLoading = false;
        state.recentPropertyList = properties;
        state.recentTotalCount = totalCount;
      })
      .addCase(fetchRecentProperty.rejected, (state, action) => {
        state.recentPropertyLoading = false;
        state.recentPropertyError = action.payload;
      })
      // .addCase(fetchAllProperty.pending, (state) => {
      //   state.allPropertyLoading = true;
      // })
      // .addCase(fetchAllProperty.fulfilled, (state, action) => {
      //   state.allPropertyLoading = false;
      //   state.allProperty = action.payload;
      // })
      // .addCase(fetchAllProperty.rejected, (state, action) => {
      //   state.allPropertyLoading = false;
      //   state.allPropertyError = action.payload;
      // })
      .addCase(fetchSoldPropertyList.pending, (state) => {
        state.soldPropertyListLoading = true;
      })
      .addCase(fetchSoldPropertyList.fulfilled, (state, action) => {
        state.soldPropertyListLoading = false;
        state.soldPropertyList = action.payload;
      })
      .addCase(fetchSoldPropertyList.rejected, (state, action) => {
        state.soldPropertyListLoading = false;
        state.soldPropertyListError = action.payload;
      })
      .addCase(fetchUnApprovedPropertyList.pending, (state) => {
        state.unApprovedPropertyListLoading = true;
      })
      .addCase(fetchUnApprovedPropertyList.fulfilled, (state, action) => {
        state.unApprovedPropertyListLoading = false;
        state.unApprovedPropertyList = action.payload;
      })
      .addCase(fetchUnApprovedPropertyList.rejected, (state, action) => {
        state.unApprovedPropertyListLoading = false;
        state.unApprovedPropertyListError = action.payload;
      })
      .addCase(fetchRecentPropertyDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRecentPropertyDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.propertyDetails = action.payload;
      })
      .addCase(fetchRecentPropertyDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.propertyDetails = null;
        state.error = action.payload;
      })
      .addCase(fetchSoldPropertyDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSoldPropertyDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;

        state.propertyDetails = action.payload;
      })
      .addCase(fetchSoldPropertyDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.propertyDetails = null;
        state.error = action.payload;
      })
      .addCase(fetchUnApprovedPropertyDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchUnApprovedPropertyDetailsById.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.propertyDetails = action.payload;
        }
      )
      .addCase(fetchUnApprovedPropertyDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.propertyDetails = null;
        state.error = action.payload;
      })
      .addCase(fetchEditProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEditProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editPropertyData = action.payload;
      })
      .addCase(fetchEditProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.editPropertyData = null;
        state.error = action.payload;
      })
      .addCase(fetchPropertyComments.pending, (state) => {
        state.commentLoading = true;
      })
      .addCase(fetchPropertyComments.fulfilled, (state, action) => {
        state.commentLoading = false;
        state.propertyComments = action.payload;
      })
      .addCase(fetchPropertyComments.rejected, (state, action) => {
        state.commentLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerList.pending, (state) => {
        state.customerLoading = true;
      })
      .addCase(fetchCustomerList.fulfilled, (state, action) => {
        state.customerLoading = false;
        state.customerList = action.payload?.user;
      })
      .addCase(fetchCustomerList.rejected, (state, action) => {
        state.customerLoading = false;
        state.customerListError = action.payload;
      })
      .addCase(searchCustomer.pending, (state) => {
        state.customerLoading = true;
      })
      .addCase(searchCustomer.fulfilled, (state, action) => {
        state.customerLoading = false;
        state.customerList = action.payload;
      })
      .addCase(searchCustomer.rejected, (state, action) => {
        state.customerLoading = false;
        state.customerListError = action.payload;
      })
      .addCase(fetchCustomerDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomerDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerDetails = action.payload;
      })
      .addCase(fetchCustomerDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchBuyerList.pending, (state) => {
        state.buyerListLoading = true;
      })
      .addCase(fetchBuyerList.fulfilled, (state, action) => {
        state.buyerListLoading = false;
        state.buyerList = action.payload?.user;
      })
      .addCase(fetchBuyerList.rejected, (state, action) => {
        state.buyerListLoading = false;
        state.buyerListError = action.payload;
      })
      .addCase(searchBuyer.pending, (state) => {
        state.buyerListLoading = true;
      })
      .addCase(searchBuyer.fulfilled, (state, action) => {
        state.buyerListLoading = false;
        state.buyerList = action.payload;
      })
      .addCase(searchBuyer.rejected, (state, action) => {
        state.buyerListLoading = false;
        state.buyerListError = action.payload;
      })
      .addCase(fetchBuyerDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBuyerDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.buyerDetails = action.payload;
      })
      .addCase(fetchBuyerDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(fetchSellerList.pending, (state) => {
        state.sellerListLoading = true;
      })
      .addCase(fetchSellerList.fulfilled, (state, action) => {
        state.sellerListLoading = false;
        state.sellerList = action.payload?.user;
      })
      .addCase(fetchSellerList.rejected, (state, action) => {
        state.sellerListLoading = false;
        state.sellerListError = action.payload;
      })
      .addCase(searchSeller.pending, (state) => {
        state.sellerListLoading = true;
      })
      .addCase(searchSeller.fulfilled, (state, action) => {
        state.sellerListLoading = false;
        state.sellerList = action.payload;
      })
      .addCase(searchSeller.rejected, (state, action) => {
        state.sellerListLoading = false;
        state.sellerListError = action.payload;
      })
      .addCase(fetchSellerDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSellerDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sellerDetails = action.payload;
      })
      .addCase(fetchSellerDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAgentList.pending, (state) => {
        state.agentListLoading = true;
      })
      .addCase(fetchAgentList.fulfilled, (state, action) => {
        state.agentListLoading = false;
        state.agentList = action.payload;
      })
      .addCase(fetchAgentList.rejected, (state, action) => {
        state.agentListLoading = false;
        state.agentListError = action.payload;
      })
      .addCase(fetchAgentProvinceDetails.pending, (state) => {
        state.isAgentProvinceDetailsLoading = true;
      })
      .addCase(fetchAgentProvinceDetails.fulfilled, (state, action) => {
        state.isAgentProvinceDetailsLoading = false;
        state.agentProvinceDetails = action.payload;
      })
      .addCase(fetchAgentProvinceDetails.rejected, (state, action) => {
        state.isAgentProvinceDetailsLoading = false;
        state.agentProvinceDetailsError = action.payload;
      })
      .addCase(fetchAgentDetailsById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAgentDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.agentDetails = action.payload;
      })
      .addCase(fetchAgentDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchRequestList.pending, (state) => {
        state.requestListLoading = true;
      })
      .addCase(fetchRequestList.fulfilled, (state, action) => {
        state.requestListLoading = false;
        state.requestList = action.payload;
      })
      .addCase(fetchRequestList.rejected, (state, action) => {
        state.requestListLoading = false;
        state.requestListError = action.payload;
      })
      .addCase(fetchRequestDetailById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRequestDetailById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestDetails = action.payload;
      })
      .addCase(fetchRequestDetailById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProperty.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addProperty.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(addSoldPropertyByIdAsync.pending, (state) => {
        state.isAddSoldPropertyLoading = true;
      })
      .addCase(addSoldPropertyByIdAsync.fulfilled, (state) => {
        state.isAddSoldPropertyLoading = false;
      })
      .addCase(addSoldPropertyByIdAsync.rejected, (state) => {
        state.isAddSoldPropertyLoading = false;
      })
      .addCase(addPropertyComment.pending, (state) => {
        state.commentLoading = true;
      })
      .addCase(addPropertyComment.fulfilled, (state, action) => {
        state.commentLoading = false;
      })
      .addCase(addPropertyComment.rejected, (state, action) => {
        state.commentLoading = false;
        state.error = action.payload;
      })
      .addCase(editProperty.pending, (state) => {
        state.editPropertyLoading = true;
      })
      .addCase(editProperty.fulfilled, (state) => {
        state.editPropertyLoading = false;
      })
      .addCase(editProperty.rejected, (state, action) => {
        state.editPropertyLoading = false;
        state.error = action.payload;
      })
      .addCase(editPropertyAds.pending, (state) => {
        state.editPropertyLoading = true;
      })
      .addCase(editPropertyAds.fulfilled, (state, action) => {
        state.editPropertyLoading = false;
      })
      .addCase(editPropertyAds.rejected, (state, action) => {
        state.editPropertyLoading = false;
      })
      .addCase(deleteRecentProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRecentProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        const deletePropertyId = action.payload;
        state.recentPropertyList = state.recentPropertyList.filter(
          (property) => property.property_id !== deletePropertyId
        );
        state.allProperty = state.allProperty.filter(
          (property) => property.property_id !== deletePropertyId
        );
      })
      .addCase(deleteRecentProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteUnApprovedProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUnApprovedProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        const deletePropertyId = action.payload;
        state.unApprovedPropertyList =
          state.unApprovedPropertyList?.properties?.filter(
            (property) => property.property_id !== deletePropertyId
          );
        state.allProperty = state.allProperty.filter(
          (property) => property.property_id !== deletePropertyId
        );
      })
      .addCase(deleteUnApprovedProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deletePropertyImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePropertyImage.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deletePropertyImage.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isLoading = false;
        const deleteCustomerId = action.payload;
        state.customerList = state.customerList.filter(
          (customer) => customer.customer_id !== deleteCustomerId
        );
      })
      .addCase(deleteBuyer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBuyer.fulfilled, (state, action) => {
        state.isLoading = false;
        const buyerId = action.payload;
        state.buyerList = state.buyerList.filter(
          (buyer) => buyer.customer_id !== buyerId
        );
      })
      .addCase(deleteSeller.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSeller.fulfilled, (state, action) => {
        state.isLoading = false;
        const sellerId = action.payload;
        state.sellerList = state.customerList.filter(
          (seller) => seller.customer_id !== sellerId
        );
      })

      .addCase(deleteAgentById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAgentById.fulfilled, (state, action) => {
        state.isLoading = false;
        const agentId = action.payload;
        state.agentList = state.agentList.filter(
          (agent) => agent.user_id !== agentId
        );
      })
      .addCase(deleteAgentById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(deleteRequestById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRequestById.fulfilled, (state, action) => {
        state.isLoading = false;
        const requestId = action.payload;
        state.requestList = state.requestList.filter(
          (request) => request.id !== requestId
        );
      })
      .addCase(deleteRequestById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(verifyAgentById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyAgentById.fulfilled, (state, action) => {
        state.isLoading = false;
        const agentId = action.payload;
        state.agentList = state.agentList.filter(
          (agent) => agent.agent_id !== agentId
        );
      })
      .addCase(verifyAgentById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(approveCustomerProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveCustomerProperty.fulfilled, (state, action) => {
        state.isLoading = false;
        const property_id = action.payload;
        state.unApprovedPropertyList =
          state.unApprovedPropertyList?.properties?.filter(
            (property) => property.property_id !== property_id
          );
      })
      .addCase(approveCustomerProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(requestProperty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestProperty.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(requestProperty.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(searchRecentProperty.pending, (state) => {
        state.recentPropertyLoading = true;
      })
      .addCase(searchRecentProperty.fulfilled, (state, action) => {
        state.recentPropertyLoading = false;
        state.recentPropertyList = action.payload.properties;
      })
      .addCase(searchRecentProperty.rejected, (state, action) => {
        state.recentPropertyLoading = false;
        state.recentPropertyError = action.payload;
      })
      .addCase(searchRequestProperty.pending, (state) => {
        state.requestListLoading = true;
      })
      .addCase(searchRequestProperty.fulfilled, (state, action) => {
        state.requestListLoading = false;
        state.requestList = action.payload;
      })
      .addCase(searchRequestProperty.rejected, (state, action) => {
        state.requestListLoading = false;
        state.requestListError = action.payload;
      })
      .addCase(searchSoldProperty.pending, (state) => {
        state.soldPropertyListLoading = true;
      })
      .addCase(searchSoldProperty.fulfilled, (state, action) => {
        state.soldPropertyListLoading = false;
        state.soldPropertyList = action.payload;
      })
      .addCase(searchSoldProperty.rejected, (state, action) => {
        state.soldPropertyListLoading = false;
        state.soldPropertyListError = action.payload;
      })
      .addCase(searchUnApprovedProperty.pending, (state) => {
        state.unApprovedPropertyListLoading = true;
      })
      .addCase(searchUnApprovedProperty.fulfilled, (state, action) => {
        state.unApprovedPropertyListLoading = false;
        state.unApprovedPropertyList = action.payload;
      })
      .addCase(searchUnApprovedProperty.rejected, (state, action) => {
        state.unApprovedPropertyListLoading = false;
        state.unApprovedPropertyListError = action.payload;
      });
    // .addCase(searchAllProperty.pending, (state) => {
    //   state.allPropertyLoading = true;
    // })
    // .addCase(searchAllProperty.fulfilled, (state, action) => {
    //   state.allPropertyLoading = false;
    //   state.allProperty = action.payload;
    // })
    // .addCase(searchAllProperty.rejected, (state, action) => {
    //   state.allPropertyLoading = false;
    //   state.allPropertyError = action.payload;
    // });
  },
});

export default overviewSlice.reducer;
