import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config/base_url";
import { throwErrorToast, throwSuccessToast } from "src/utils/throw_toast";
const initialState = {
  notificationDetail: {
    notifications: [
      {
        id: 1,
        user_id: 1,
        user_type: "superAdmin",
        title: "Property Shoot Schedule Request",
        url: "/admin/property/shoot-schedule/6",
        seen: true,
        createdAt: "2023-10-21T08:38:03.000Z",
        updatedAt: "2023-10-21T09:00:00.000Z",
      },
    ],
    notificationCount: 0,
    user: {
      name: "Amir Poudel",
    },
  },
  isNotficationDetailLoading: false,
  notficationDetailError: null,
  isMarkNotificationLoading: false,
  markNotificationError: null,
};

export const fetchNotificationDetailAsync = createAsyncThunk(
  "notification/fetchNotificationDetailAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/notification`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const markNotificationAsReadAsync = createAsyncThunk(
  "notification/markNotificationAsReadAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.patch(`${BASE_URL}/admin/notification`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        throwSuccessToast("Marked Notification as read");

        return response.data;
      }
    } catch (err) {
      console.log("marked all red", err);
      const errorMessage = err?.response?.data?.message || "Unable to mark !!";
      throwErrorToast(errorMessage);
      return rejectWithValue(errorMessage);
    }
  }
);
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotificationDetailAsync.pending, (state) => {
        state.isNotficationDetailLoading = true;
      })
      .addCase(fetchNotificationDetailAsync.fulfilled, (state, action) => {
        state.isNotficationDetailLoading = false;
        state.notificationDetail = action.payload;
        state.notficationDetailError = null;
      })
      .addCase(fetchNotificationDetailAsync.rejected, (state, action) => {
        state.isNotficationDetailLoading = false;
        state.notficationDetailError = action.payload;
      })
      .addCase(markNotificationAsReadAsync.pending, (state) => {
        state.isMarkNotificationLoading = true;
      })
      .addCase(markNotificationAsReadAsync.fulfilled, (state) => {
        state.isMarkNotificationLoading = false;
      })
      .addCase(markNotificationAsReadAsync.rejected, (state, action) => {
        state.isMarkNotificationLoading = false;
        state.markNotificationError = action.payload;
      });
  },
});

export default notificationSlice.reducer;
