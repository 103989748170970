import React from "react";
import { Box } from "@mui/material";
import RequestTable from "./overview_request_table";

function OverviewRequest() {
  return (
    <Box>
      <Box>
        <Box sx={{ mt: "4rem" }}>
          <RequestTable />
        </Box>
      </Box>
    </Box>
  );
}

export default OverviewRequest;
