import React from "react";

import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  Typography,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import PlaceInfoModal from "../place_info_modal/place_info_modal";

// import { convertToDay, convertToFeet } from "../../utils/converter";

function HomeFirstLocationDetails({
  setPropertyDetailsData,
  propertyDetailsData,
  incrementActiveSectionId,
}) {
  const handleChange = (e) => {
    setPropertyDetailsData({
      ...propertyDetailsData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    incrementActiveSectionId();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography
          variant="body2"
          textAlign="start"
          color="black"
          fontSize="1.2rem"
          fontWeight="700"
          sx={{
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            marginBottom: "0.5rem",
          }}
        >
          Location
        </Typography>
        <PlaceInfoModal />
      </Box>
      <Typography
        variant="body2"
        textAlign="start"
        color="black"
        fontSize="1.2rem"
        fontWeight="700"
        sx={{
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          marginBottom: "0.5rem",
          marginTop: "1.2rem",
        }}
      >
        Property Details
      </Typography>

      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel id="demo-simple-select-label">Property For</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="property_for"
              value={propertyDetailsData?.property_for}
              label="Property For"
              defaultValue=""
              onChange={handleChange}
              required
            >
              <MenuItem value="residential">Residential</MenuItem>
              <MenuItem value="commercial">Commercial</MenuItem>
              <MenuItem value="office">Office</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {" "}
          <TextField
            label="Property Title"
            type="text"
            size="small"
            name="property_name"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.property_name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Area(0-0-0-0)"
            type="text"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            name="land_area"
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.land_area}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="Built Up Area (Sq.ft)"
            type="number"
            size="small"
            name="built_up_area"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.built_up_area}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Floor"
            type="number"
            size="small"
            required
            sx={{ width: "100%", maxWidth: "13rem" }}
            name="floor"
            value={propertyDetailsData?.floor}
            InputProps={{
              inputProps: { min: 0, step: 0.001 },
            }}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel id="demo-simple-select-label">House Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="House Type"
              defaultValue=""
              name="house_type"
              onChange={handleChange}
              value={propertyDetailsData?.house_type}
            >
              <MenuItem value="single">Single</MenuItem>
              <MenuItem value="duplex">Duplex</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel id="demo-simple-select-label">Facing</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Facing"
              defaultValue=""
              name="facing"
              value={propertyDetailsData?.facing}
              onChange={handleChange}
            >
              <MenuItem value="north">North</MenuItem>
              <MenuItem value="east">East</MenuItem>
              <MenuItem value="west">West</MenuItem>
              <MenuItem value="south">South</MenuItem>
              <MenuItem value="north-east">North East</MenuItem>
              <MenuItem value="south-east">South East</MenuItem>
              <MenuItem value="north-west">North West</MenuItem>
              <MenuItem value="south-west">South West</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Road Access"
            type="number"
            size="small"
            required
            sx={{ width: "100%", maxWidth: "8rem" }}
            name="road_access"
            onChange={handleChange}
            value={propertyDetailsData?.road_access}
          />
          <FormControl size="small">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              name="road_size_type"
              value={propertyDetailsData?.road_size_type || "feet"}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="meter">mt</MenuItem>
              <MenuItem value="cm">cm</MenuItem>
              <MenuItem value="inch">inch</MenuItem>
              <MenuItem value="feet">ft</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Owner Name"
            type="text"
            size="small"
            required
            name="owner_name"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.owner_name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact"
            type="number"
            size="small"
            required
            name="owner_phone"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.owner_phone}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="submit"
          variant="outlined"
          style={{
            border: "none",
            color: "black",
            fontFamily: "Roboto Serif",
            fontSize: "0.9rem",
            fontWeight: "700",
            textTransform: "none",
          }}
          endIcon={<EastIcon />}
        >
          Next
        </Button>
      </Box>
    </form>
  );
}

export default HomeFirstLocationDetails;
