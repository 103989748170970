import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import { updateBackgroundVideoAsync } from "../../redux/adsSlice";

function AddBackgroundVideo() {
  const dispatch = useDispatch();
  const [videoLink, setVideoLink] = React.useState("");

  const isBackgroundUpdateLoading = useSelector(
    (state) => state.ads.isBackgroundUpdateLoading
  );

  const handleUpdate = () => {
    if (videoLink.trim() === "") {
      return;
    }
    dispatch(updateBackgroundVideoAsync({ videoLink })).then(() =>
      setVideoLink("")
    );
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Typography variant="h4">Include Background Video Link</Typography>
      <TextField
        sx={{ marginTop: "1rem" }}
        label="Video Link"
        variant="standard"
        fullWidth
        value={videoLink}
        autoComplete="off"
        onChange={(e) => setVideoLink(e.target.value)}
      />
      <Button
        variant="contained"
        sx={{
          mt: "1rem",
          backgroundColor: "black",
          ":hover": {
            backgroundColor: "#1c1c1c",
          },
        }}
        onClick={handleUpdate}
        disabled={isBackgroundUpdateLoading ? true : false}
      >
        {isBackgroundUpdateLoading ? "Updating..." : "Update"}
      </Button>
      {isBackgroundUpdateLoading && (
        <Box
          sx={{
            position: "absolute",
            bottom: "-5%",
            left: "8%",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
}

export default AddBackgroundVideo;
