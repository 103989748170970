import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setLocation,
  fetchProvinces,
  fetchMunicipalities,
  fetchDistricts,
  setLandMark,
  setProvinceKey,
  setDistrictKey,
  setWardCount,
  setAreaName,
} from "../../redux/locationSlice";

import {
  styled,
  Grid,
  Box,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

// import { getDistrictOptions } from "../filter_modal/filter_modal_select_options";

//For Filter Modal Select inputs
const SelectContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  gap: "1.8rem",
  alignItems: "center",
}));

function PlaceInfoModal() {
  // const [provniceKey, setProvinceKey] = useState(1);
  // const [districtKey, setDistrictKey] = useState(1);
  // const [wardCount, setWardCount] = useState(0);
  const dispatch = useDispatch();

  const provinceKey = useSelector((state) => state.location.provinceKey);
  const districtKey = useSelector((state) => state.location.districtKey);
  const wardCount = useSelector((state) => state.location.wardCount);
  const location = useSelector((state) => state.location);
  const { provinces, districts, municipalities } = useSelector(
    (state) => state.location
  );
  const selectedProvince = useSelector(
    (state) => state.location.selectedProvince
  );
  const selectedDistrict = useSelector(
    (state) => state.location.selectedDistrict
  );
  const selectedMunicipality = useSelector(
    (state) => state.location.selectedMunicipality
  );
  const selectedWard = useSelector((state) => state.location.selectedWard);
  const selectedLandMark = useSelector(
    (state) => state.location.selectedLandMark
  );
  const selectedAreaName = useSelector(
    (state) => state.location.selectedAreaName
  );
  useEffect(() => {
    if (provinces?.length === 0) {
      dispatch(fetchProvinces());
    }
    if (districts?.length === 0) {
      dispatch(fetchDistricts());
    }
    if (municipalities?.length === 0) {
      dispatch(fetchMunicipalities());
    }
  }, [dispatch, provinces, districts, municipalities]);

  const handleLocationSelect = (event) => {
    const { name, value } = event.target;
    let key;
    if (!value.includes("|")) key = value;
    else key = value.split("|")[0];

    // if (name === "selectedProvince") setProvinceKey(parseInt(key));
    if (name === "selectedProvince") dispatch(setProvinceKey(parseInt(key)));
    // if (name === "selectedDistrict") setDistrictKey(parseInt(key));
    if (name === "selectedDistrict") dispatch(setDistrictKey(parseInt(key)));
    // if (name === "selectedMunicipality") setWardCount(parseInt(key));
    if (name === "selectedMunicipality") dispatch(setWardCount(parseInt(key)));

    dispatch(setLocation({ ...location, [name]: value }));
  };
  const handleLandMarkChange = (event) => {
    dispatch(setLandMark({ selectedLandMark: event.target.value }));
  };
  const handleAreaNameChange = (event) => {
    dispatch(setAreaName({ selectedAreaName: event.target.value }));
  };
  return (
    <>
      <SelectContainer>
        <Grid container spacing={2} rowGap={1}>
          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "100%",
                maxWidth: "13rem",
                height: "1.6rem",
                boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset",
              }}
              size="small"
              required
            >
              <InputLabel id="demo-simple-select-label">Province</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedProvince || ""}
                label="Province"
                name="selectedProvince"
                onChange={handleLocationSelect}
              >
                {provinces?.map((province) => (
                  <MenuItem
                    key={province.id}
                    value={`${province.id}|${province.name}`}
                  >
                    {province.name.replace("Province", "")}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "100%",
                maxWidth: "13rem",
                height: "1.6rem",
                boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset",
              }}
              size="small"
              required
            >
              <InputLabel id="demo-simple-select-label">District</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedDistrict || ""}
                label="District"
                name="selectedDistrict"
                onChange={handleLocationSelect}
              >
                {districts
                  ?.filter((district) => district.province_id === provinceKey)
                  .map((district) => (
                    <MenuItem
                      key={district.id}
                      value={`${district.id}|${district.name}`}
                    >
                      {district.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "100%",
                maxWidth: "13rem",
                height: "1.6rem",
                boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset",
              }}
              size="small"
              required
            >
              <InputLabel id="demo-simple-select-label">
                Municipality
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedMunicipality || ""}
                label="Municipality"
                name="selectedMunicipality"
                onChange={handleLocationSelect}
              >
                {municipalities
                  ?.filter(
                    (municipality) => municipality.district_id === districtKey
                  )
                  .map((municipality) => (
                    <MenuItem
                      key={municipality.id}
                      value={`${municipality.wards}|${municipality.name}`}
                    >
                      {municipality.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              sx={{
                width: "100%",
                maxWidth: "13rem",
                height: "1.6rem",
                boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset",
              }}
              size="small"
            >
              <InputLabel id="demo-simple-select-label">Ward</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedWard || ""}
                label="Ward"
                name="selectedWard"
                onChange={handleLocationSelect}
              >
                {[...Array(wardCount).keys()]?.map((_, index) => (
                  <MenuItem key={index + 1} value={`${index + 1}`}>
                    {index + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Area Name"
              required
              size="small"
              sx={{ width: "100%", maxWidth: "13rem" }}
              onChange={handleAreaNameChange}
              value={selectedAreaName || ""}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            <TextField
              label="LandMark"
              required
              name="selectedLandMark"
              value={selectedLandMark || ""}
              onChange={handleLandMarkChange}
              placeholder="(near by hospital)"
              size="small"
              multiline
              sx={{ width: "100%", maxWidth: "13rem" }}
            />
          </Grid>
        </Grid>
      </SelectContainer>
    </>
  );
}

export default PlaceInfoModal;
