import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  scheduledPropertyList: [],
  isscheduledPropertyListLoading: false,
  scheduledPropertyListError: null,

  shootScheduleCommentList: [],
  shootScheduleCommentError: null,
  shootScheduleCommentLoading: false,

  isAddScheduleLoading: false,
  addScheduleError: null,

  isDeleteScheduleLoading: false,
  deleteScheduleError: null,

  isChangeStatusLoading: false,
  changeStatusError: null,

  isShootCompletedListLoading: false,
  shootCompletedList: [],
  shootCompletedError: null,

  isReadyToPostListLoading: false,
  readyToPostList: [],
  readyToPostError: null,
};

//fetch shoot schedule list
export const fetchShootScheduledListAsync = createAsyncThunk(
  " shootSchedule/fetchShootScheduledListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/shoot-schedule?shoot_status=scheduled`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        if (response.data.length === 0) {
          return rejectWithValue("No scheduled property found");
        }
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchCompletedScheduleListAsync = createAsyncThunk(
  " shootSchedule/fetchCompletedScheduleListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/shoot-schedule?shoot_status=completed`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        if (response.data.length === 0) {
          return rejectWithValue("No scheduled property found");
        }
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchReadyToPostListAsync = createAsyncThunk(
  " shootSchedule/fetchReadyToPostListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/shoot-schedule?shoot_status=ready_to_post`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        if (response.data.length === 0) {
          return rejectWithValue("No scheduled property found");
        }
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchShootScheduleCommentByIdAsync = createAsyncThunk(
  "shootSchedule/fetchShootScheduleCommentByIdAsync",
  async ({ schedule_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/shoot-schedule-comment/${schedule_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const addScheduleAsync = createAsyncThunk(
  "shootSchedule/addScheduleAsync",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/shoot-schedule`,
        data,
        {
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        toast.success("Schedule added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const addScheduleComment = createAsyncThunk(
  "shootSchedule/addScheduleComment",
  async ({ schedule_id, comment }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/shoot-schedule-comment/${schedule_id}`,
        { comment },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const changeStatusScheduledPropertyAsync = createAsyncThunk(
  "shootSchedule/changeStatusScheduledPropertyAsync",
  async ({ schedule_id, status, selectedNavItem }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/shoot-schedule/${schedule_id}`,
        { shoot_status: status },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return { schedule_id, selectedNavItem };
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteScheduleAsync = createAsyncThunk(
  "shootSchedule/deleteScheduleAsync",
  async ({ schedule_id, selectedNavItem }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/property/shoot-schedule/${schedule_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return { schedule_id, selectedNavItem };
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const shootScheduleSlice = createSlice({
  name: "shootSchedule",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchShootScheduledListAsync.pending, (state) => {
        state.isscheduledPropertyListLoading = true;
      })
      .addCase(fetchShootScheduledListAsync.fulfilled, (state, action) => {
        state.isscheduledPropertyListLoading = false;
        state.scheduledPropertyList = action.payload;
        state.scheduledPropertyListError = null;
      })
      .addCase(fetchShootScheduledListAsync.rejected, (state, action) => {
        state.isscheduledPropertyListLoading = false;
        state.scheduledPropertyListError = action.payload;
      })
      .addCase(fetchCompletedScheduleListAsync.pending, (state) => {
        state.isShootCompletedListLoading = true;
      })

      .addCase(fetchCompletedScheduleListAsync.fulfilled, (state, action) => {
        state.isShootCompletedListLoading = false;
        state.shootCompletedList = action.payload;
        state.shootCompletedError = null;
      })
      .addCase(fetchCompletedScheduleListAsync.rejected, (state, action) => {
        state.isShootCompletedListLoading = false;
        state.shootCompletedError = action.payload;
      })
      .addCase(fetchReadyToPostListAsync.pending, (state) => {
        state.isReadyToPostListLoading = true;
      })

      .addCase(fetchReadyToPostListAsync.fulfilled, (state, action) => {
        state.isReadyToPostListLoading = false;
        state.readyToPostList = action.payload;
        state.readyToPostError = null;
      })
      .addCase(fetchReadyToPostListAsync.rejected, (state, action) => {
        state.isReadyToPostListLoading = false;
        state.readyToPostError = action.payload;
      })

      .addCase(fetchShootScheduleCommentByIdAsync.pending, (state) => {
        state.shootScheduleCommentLoading = true;
      })
      .addCase(
        fetchShootScheduleCommentByIdAsync.fulfilled,
        (state, action) => {
          state.shootScheduleCommentLoading = false;
          state.shootScheduleCommentList = action.payload;
          state.shootScheduleCommentError = null;
        }
      )
      .addCase(fetchShootScheduleCommentByIdAsync.rejected, (state, action) => {
        state.shootScheduleCommentLoading = false;
        state.shootScheduleCommentError = action.payload;
      })
      .addCase(addScheduleAsync.pending, (state) => {
        state.isAddScheduleLoading = true;
      })
      .addCase(addScheduleAsync.fulfilled, (state, action) => {
        state.isAddScheduleLoading = false;
        state.scheduledPropertyList = [
          ...state.scheduledPropertyList,
          action.payload.data,
        ];
        //setting to null because if we add new schedule or if there is no data at first and we add new data then we don't want to show error message
        state.scheduledPropertyListError = null;
      })
      .addCase(addScheduleAsync.rejected, (state, action) => {
        state.isAddScheduleLoading = false;
        state.addScheduleError = action.payload;
      })

      .addCase(changeStatusScheduledPropertyAsync.pending, (state) => {
        state.isChangeStatusLoading = true;
      })
      .addCase(
        changeStatusScheduledPropertyAsync.fulfilled,
        (state, action) => {
          state.isChangeStatusLoading = false;

          if (action.payload.selectedNavItem === "Scheduled Property")
            state.scheduledPropertyList = state.scheduledPropertyList.filter(
              (item) => item.id !== action.payload.schedule_id
            );
          else if (action.payload.selectedNavItem === "Shoot Completed")
            state.shootCompletedList = state.shootCompletedList.filter(
              (item) => item.id !== action.payload.schedule_id
            );
          else if (action.payload.selectedNavItem === "Ready To Post")
            state.readyToPostList = state.readyToPostList.filter(
              (item) => item.id !== action.payload.schedule_id
            );
        }
      )
      .addCase(changeStatusScheduledPropertyAsync.rejected, (state, action) => {
        state.isChangeStatusLoading = false;
        state.changeStatusError = action.payload;
      })
      .addCase(deleteScheduleAsync.pending, (state) => {
        state.isDeleteScheduleLoading = true;
      })
      .addCase(deleteScheduleAsync.fulfilled, (state, action) => {
        state.isDeleteScheduleLoading = false;

        if (action.payload.selectedNavItem === "Scheduled Property")
          state.scheduledPropertyList = state.scheduledPropertyList.filter(
            (item) => item.id !== action.payload.schedule_id
          );
        else if (action.payload.selectedNavItem === "Shoot Completed")
          state.shootCompletedList = state.shootCompletedList.filter(
            (item) => item.id !== action.payload.schedule_id
          );
        else if (action.payload.selectedNavItem === "Ready To Post")
          state.readyToPostList = state.readyToPostList.filter(
            (item) => item.id !== action.payload.schedule_id
          );
        if (state.scheduledPropertyList.length === 0) {
          state.scheduledPropertyListError = "No scheduled property found";
        }
        if (state.shootCompletedList.length === 0) {
          state.shootCompletedError = "No scheduled property found";
        }
        if (state.readyToPostList.length === 0) {
          state.readyToPostError = "No scheduled property found";
        }
      })
      .addCase(deleteScheduleAsync.rejected, (state, action) => {
        state.isDeleteScheduleLoading = false;
        state.deleteScheduleError = action.payload;
      });
  },
});
export default shootScheduleSlice.reducer;
