import React from "react";

import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  Typography,
  Select,
  InputLabel,
  Button,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import { convertToFeet } from "../../utils/converter";
import EditPlaceInfo from "./edit_place_info";

// import { convertToDay, convertToFeet } from "../../utils/converter";

function ApartmentFirstLocationDetails({
  propertyDetailsData,
  setPropertyDetailsData,
  incrementActiveSectionId,
  changedData,
  setChangedData,
}) {
  const handleChange = (e) => {
    if (e.target.name === "road_size_type") {
      setPropertyDetailsData({
        ...propertyDetailsData,
        [e.target.name]: e.target.value,
      });
      setChangedData({
        ...changedData,
        road_access: convertToFeet(
          propertyDetailsData.road_access,
          e.target.value
        ),
      });
      return;
    }
    setPropertyDetailsData({
      ...propertyDetailsData,
      [e.target.name]: e.target.value,
    });
    setChangedData({
      ...changedData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    incrementActiveSectionId();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0.8rem",
        }}
      >
        <FormControl fullWidth size="small" sx={{ maxWidth: "8rem" }}>
          <InputLabel>Listed For</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={propertyDetailsData?.listed_for || ""}
            label="Facing"
            defaultValue="sale"
            name="listed_for"
            onChange={handleChange}
          >
            <MenuItem value="sale">Sale</MenuItem>
            <MenuItem value="rent">Rent</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Typography
          variant="body2"
          textAlign="start"
          color="black"
          fontSize="1.2rem"
          fontWeight="700"
          sx={{
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            marginBottom: "0.5rem",
          }}
        >
          Location
        </Typography>
        <EditPlaceInfo
          changedData={changedData}
          setChangedData={setChangedData}
          propertyDetailsData={propertyDetailsData}
          setPropertyDetailsData={setPropertyDetailsData}
        />
      </Box>
      <Typography
        variant="body2"
        textAlign="start"
        color="black"
        fontSize="1.2rem"
        fontWeight="700"
        sx={{
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          marginBottom: "0.5rem",
          marginTop: "1.2rem",
        }}
      >
        Property Details
      </Typography>

      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel id="demo-simple-select-label">Property For</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="property_for"
              defaultValue=""
              name="property_for"
              value={propertyDetailsData?.property_for || ""}
              onChange={handleChange}
            >
              <MenuItem value="residential">Residential</MenuItem>
              <MenuItem value="commercial">Commercial</MenuItem>
              <MenuItem value="office">Office</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Property Title"
            type="text"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            name="property_name"
            value={propertyDetailsData?.property_name || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Area(Sq.Ft)"
            type="number"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            name="property_area"
            value={propertyDetailsData?.property_area || ""}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            label="BHK(B-H-K)"
            type="text"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            name="bhk"
            value={propertyDetailsData?.bhk || ""}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <TextField
            label="Floor"
            type="number"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            InputProps={{
              inputProps: { min: 0 },
            }}
            name="floor"
            value={propertyDetailsData.floor}
            onChange={handleChange}
          />
        </Grid> */}

        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel id="demo-simple-select-label">Facing</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Facing"
              defaultValue=""
              name="facing"
              value={propertyDetailsData?.facing || ""}
              onChange={handleChange}
            >
              <MenuItem value="north">North</MenuItem>
              <MenuItem value="east">East</MenuItem>
              <MenuItem value="west">West</MenuItem>
              <MenuItem value="south">South</MenuItem>
              <MenuItem value="north-east">North East</MenuItem>
              <MenuItem value="south-east">South East</MenuItem>
              <MenuItem value="north-west">North West</MenuItem>
              <MenuItem value="south-west">South West</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Road Access"
            type="number"
            size="small"
            required
            sx={{ width: "100%", maxWidth: "8rem" }}
            onChange={handleChange}
            name="road_access"
            value={propertyDetailsData?.road_access || ""}
          />
          <FormControl size="small">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={propertyDetailsData?.road_size_type || "feet"}
              onChange={handleChange}
              name="road_size_type"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value="meter">mt</MenuItem>
              <MenuItem value="cm">cm</MenuItem>
              <MenuItem value="inch">inch</MenuItem>
              <MenuItem value="feet">ft</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Owner Name"
            type="text"
            size="small"
            required
            name="owner_name"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.owner_name || ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact"
            type="number"
            size="small"
            required
            name="owner_phone"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.owner_phone || ""}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="submit"
          variant="outlined"
          style={{
            border: "none",
            color: "black",
            fontFamily: "Roboto Serif",
            fontSize: "0.9rem",
            fontWeight: "700",
            textTransform: "none",
          }}
          endIcon={<EastIcon />}
        >
          Next
        </Button>
      </Box>
    </form>
  );
}

export default ApartmentFirstLocationDetails;
