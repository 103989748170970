import React from "react";

import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
  Button,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import PlaceInfoModal from "../place_info_modal/place_info_modal";

function LandFirstLocationDetails({
  propertyDetailsData,
  setPropertyDetailsData,
  incrementActiveSectionId,
}) {
  //   const [facing, setFacing] = useState("");
  //   const [propertyArea, setPropertyArea] = useState("");
  //   const [twist, setTwist] = useState("");
  //   const [roadSize, setRoadSize] = useState("");
  //   const [roadSizeType, setRoadSizeType] = useState("feet");
  //   const [type, setType] = useState("");
  //   const [propertyName, setPropertyName] = useState("");

  const handleChange = (e) => {
    setPropertyDetailsData({
      ...propertyDetailsData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    incrementActiveSectionId();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography
          variant="body2"
          textAlign="start"
          color="black"
          fontSize="1.2rem"
          fontWeight="700"
          sx={{
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            marginBottom: "0.5rem",
          }}
        >
          Location
        </Typography>
        <PlaceInfoModal />
      </Box>
      <Typography
        variant="body2"
        textAlign="start"
        color="black"
        fontSize="1.2rem"
        fontWeight="700"
        sx={{
          textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
          marginBottom: "0.5rem",
          marginTop: "1.2rem",
        }}
      >
        Property Details
      </Typography>

      <Grid container spacing={1.5}>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel>Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Type"
              name="property_for"
              value={propertyDetailsData.property_for}
              defaultValue=""
              onChange={handleChange}
            >
              <MenuItem value="plotted">Plotted</MenuItem>
              <MenuItem value="non-plotted">Non-Plotted</MenuItem>
            </Select>
          </FormControl>
        </Grid>{" "}
        <Grid item xs={6}>
          {" "}
          <TextField
            label="Property Title"
            type="text"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            name="property_name"
            value={propertyDetailsData.property_name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Twist(Moda)"
            type="number"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={propertyDetailsData.twist}
            name="twist"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Area(00-00-00-00)"
            type="text"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            name="land_area"
            value={propertyDetailsData.land_area}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Road Access"
            type="number"
            size="small"
            required
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "8rem" }}
            value={propertyDetailsData.road_access}
            name="road_access"
            onChange={handleChange}
          />
          <FormControl size="small">
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={propertyDetailsData.road_size_type || "feet"}
              name="road_size_type"
              onChange={handleChange}
            >
              <MenuItem value="feet">ft</MenuItem>
              <MenuItem value="meter">mt</MenuItem>
              <MenuItem value="cm">cm</MenuItem>
              <MenuItem value="inch">inch</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            size="small"
            sx={{ maxWidth: "13rem" }}
            required
          >
            <InputLabel>Facing</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={propertyDetailsData.facing}
              label="Facing"
              defaultValue=""
              name="facing"
              onChange={handleChange}
            >
              <MenuItem value="north">North</MenuItem>
              <MenuItem value="east">East</MenuItem>
              <MenuItem value="west">West</MenuItem>
              <MenuItem value="south">South</MenuItem>
              <MenuItem value="north-east">Nort East</MenuItem>
              <MenuItem value="south-east">South East</MenuItem>
              <MenuItem value="north-west">North West</MenuItem>
              <MenuItem value="south-west">South West</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Owner Name"
            type="text"
            size="small"
            required
            name="owner_name"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.owner_name}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Contact"
            type="number"
            size="small"
            required
            name="owner_phone"
            InputProps={{
              inputProps: { min: 0 },
            }}
            sx={{ width: "100%", maxWidth: "13rem" }}
            onChange={handleChange}
            value={propertyDetailsData?.owner_phone}
          />
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="submit"
          variant="outlined"
          style={{
            border: "none",
            color: "black",
            fontFamily: "Roboto Serif",
            fontSize: "0.9rem",
            fontWeight: "700",
            textTransform: "none",
          }}
          endIcon={<EastIcon />}
        >
          Next
        </Button>
      </Box>
    </form>
  );
}

export default LandFirstLocationDetails;
