import React from "react";
import { useDispatch } from "react-redux";

import { Box, Button, TextField } from "@mui/material";

import { addTestimonialAsync } from "../../redux/adsSlice";

function AddTestimonialCarousel() {
  const dispatch = useDispatch();
  const [testimonialText, setTestimonialText] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [rating, setRating] = React.useState("");
  const handleAddTestimonial = (e) => {
    e.preventDefault();

    if (testimonialText.trim() === "") return;
    if (customerName.trim() === "") return;

    const data = {
      name: customerName,
      testimonial: testimonialText,
      rating: rating,
    };

    dispatch(addTestimonialAsync(data)).then((action) => {
      if (addTestimonialAsync.fulfilled.match(action)) {
        setTestimonialText("");
      }
    });
  };

  return (
    <Box>
      <form onSubmit={handleAddTestimonial}>
        <TextField
          sx={{ marginTop: "1rem" }}
          label="Testimonial Text"
          variant="standard"
          required
          fullWidth
          value={testimonialText}
          autoComplete="off"
          onChange={(e) => setTestimonialText(e.target.value)}
        />
        <TextField
          sx={{ marginTop: "1rem" }}
          label="Customer Name"
          variant="standard"
          required
          fullWidth
          value={customerName}
          autoComplete="off"
          onChange={(e) => setCustomerName(e.target.value)}
        />
        <TextField
          type="number"
          sx={{ marginTop: "1rem" }}
          label="Rating"
          variant="standard"
          required
          fullWidth
          value={rating}
          autoComplete="off"
          onChange={(e) => setRating(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          size="small"
          sx={{
            mt: "1rem",
            backgroundColor: "black",
            ":hover": {
              backgroundColor: "#1c1c1c",
            },
          }}
        >
          Add Testimonial
        </Button>
      </form>
    </Box>
  );
}

export default AddTestimonialCarousel;
