import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Typography,
  styled,
  Box,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Ceo from "../../assets/images/Ceo.png";
import { fetchClientChatUserListAsync } from "../../redux/chatSlice";

const UsersList = styled(Box)`
  flex: 1;
  padding: 8px 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.45),
    0px -1px 2px 0px rgba(0, 0, 0, 0.45);
  z-index: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const UserItem = styled(Box)`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #f1f1f1;
  }
`;

const UserName = styled(Typography)`
  margin-left: 8px;
  font-size: 0.9rem;
  font-family: "Roboto Serif";
  font-weight: 500;
`;
function ClientChatUserList({
  setActiveUserId,
  activeUserId,
  setActiveUserIdName,
}) {
  const dispatch = useDispatch();
  const clientChatUserList = useSelector(
    (state) => state.chat.clientChatUserList
  );
  useEffect(() => {
    dispatch(fetchClientChatUserListAsync());
  }, [dispatch]);

  const handleUserClick = (userId, userName) => {
    setActiveUserId(userId);
    setActiveUserIdName(userName);
  };

  return (
    <UsersList>
      <TextField
        type="search"
        placeholder="Search"
        fullWidth
        InputProps={{
          style: {
            height: "50px",
            borderRadius: "10px",
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ marginBottom: "0.5rem" }}
      />

      {clientChatUserList?.map((user) => (
        <Box key={user.id}>
          <UserItem
            onClick={() => handleUserClick(user?.user_id, user?.customer?.name)}
            sx={{
              background:
                activeUserId === user?.user_id ? "#f1f1f1" : "transparent",
            }}
          >
            <Avatar src={Ceo} sx={{ width: 45, height: 45 }} />

            <UserName>{user?.customer?.name}</UserName>
          </UserItem>
          <Divider />
        </Box>
      ))}
    </UsersList>
  );
}

export default ClientChatUserList;
