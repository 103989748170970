import { useEffect, useState } from "react";
import { io } from "socket.io-client";
import { BASE_URL } from "../config/base_url";
const useSocket = () => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketInstance = io(`${BASE_URL}/chat/user?sender_id=0`);

    socketInstance.on("connect", () => {
      console.log("SConnected");
    });

    socketInstance.on("error", (error) => {
      console.error("SConnection error:", error);
    });

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
      console.log("SDisconnected");
    };
  }, []);

  return socket;
};

export default useSocket;
