import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Box,
  Rating,
  Typography,
  Collapse,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FeedbackCard({
  item,
  setSelectedId,
  setOpenDeleteDialog,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openDeleteDialog = () => {
    setSelectedId(item.id);
    setOpenDeleteDialog(true);
  };

  return (
    <Card
      sx={{
        maxWidth: 255,
        width: "100%",
        minHeight: { xs: 205 },
        padding: { xs: "0.6rem", sm: "0.8rem" },
        boxShadow: "-1px 2px 5px #fff",
        borderRadius: "5px",
        backgroundColor: "primary.subColor",
        color: "white",
        border: "2px solid #272341",
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          color: "white",
          padding: "0",
          position: "absolute",
          right: "0",
          top: "0",
        }}
        onClick={openDeleteDialog}
      >
        <DeleteIcon
          sx={{
            color: "red",
            transition: "all 0.3s ease-in-out",
            ":hover": {
              transform: "scale(1.09)",
            },
          }}
        />
      </IconButton>

      <Typography
        sx={{
          fontSize: { xs: "10px", sm: "12px", md: "14px" },
          color: "black",
          textTransform: "capitalize",
        }}
      >
        {item?.name}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // border: "1px solid yellow",
          color: "black",
        }}
      >
        <Rating
          name="read-only"
          // value={feedbackComments?.ratingValue}
          value={item?.rating || "No Rating"}
          precision={0.1}
          readOnly
          sx={{
            fontSize: { xs: "10px", sm: "12px" },
            width: "74%",
            display: "flex",
            alignItems: "center",
            color: "black",
            "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
              color: "#ffffff",
            },
            "& .MuiRating-iconFilled .MuiSvgIcon-root": {
              color: "black",
            },
          }}
        />
        <Typography
          sx={{
            width: "26%",
            float: "right",
            fontSize: "12px",
          }}
        >
          <span
            style={{ color: "black", fontWeight: "bold", fontSize: "13px" }}
          >
            {/* {feedbackComments?.ratingValue} */}
            {item?.rating || "(N)"}
          </span>
          /5
        </Typography>
      </Box>
      <CardContent sx={{ padding: "0.2rem" }}>
        <Typography
          variant="body2"
          sx={{ color: "black", fontSize: { xs: "10px", sm: "12px" } }}
        >
          {item?.testimonial.slice(0, 150)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ padding: "0" }}>
        <ExpandMore
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {/* <ExpandMoreIcon sx={{ fill: "white" }} /> */}
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "13px" },
              color: "black",
            }}
          >
            {expanded ? "See Less" : "See More"}
          </Typography>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "12px" },
              color: "black",
            }}
          >
            {item?.testimonial}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
