import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Dialog, DialogContent, Popover } from "@mui/material";

import ScheduledProperty from "./scheduled_property_main";
import ShootCompleted from "./shoot_completed_main";
import ReadyToPost from "./ready_to_post_main";

import ShootScheduleDetailPopup from "./shoot_schedule_detail_popup";
import ShootScheduleDeletePopup from "./shoot_schedule_delete_popup";
import ShootScheduleComment from "./shoot_schedule_comment";

import { deleteScheduleAsync } from "../../redux/shootScheduleSlice";

function ShootScheduleMain({ selectedNavItem }) {
  const dispatch = useDispatch();
  const [id, setId] = React.useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [openDetailPopup, setOpenDetailPopup] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  // const [openStatusChangePopup, setOpenStatusChangePopup] =
  // React.useState(false);
  const [commentAnchorEl, setCommentAnchorEl] = React.useState(null);

  const openCommentAnchor = Boolean(commentAnchorEl);
  const commentAnchorId = openCommentAnchor ? "simple-popover" : undefined;

  const handleDeleteProperty = () => {
    dispatch(
      deleteScheduleAsync({ schedule_id: id, selectedNavItem: selectedNavItem })
    ).then((action) => {
      if (deleteScheduleAsync.fulfilled.match(action)) {
        setOpenDeletePopup(false);
      }
    });
  };
  function handleOpenDetailPopup(item) {
    setSelectedItem(item);
    setOpenDetailPopup(true);
  }
  // function handleOpenStatusChangePopup(id) {
  //   setId(id);
  //   setOpenStatusChangePopup(true);
  // }
  function handleOpenDeletePopup(id) {
    setId(id);
    setOpenDeletePopup(true);
  }

  function handleOpenCommentPopup(e, id) {
    setId(id);
    setCommentAnchorEl(e.currentTarget, id);
  }

  return (
    <Box>
      {selectedNavItem === "Scheduled Property" && (
        <ScheduledProperty
          handleOpenCommentPopup={handleOpenCommentPopup}
          handleOpenDeletePopup={handleOpenDeletePopup}
          handleOpenDetailPopup={handleOpenDetailPopup}
          setId={setId}
          id={id}
        />
      )}
      {selectedNavItem === "Shoot Completed" && (
        <ShootCompleted
          handleOpenCommentPopup={handleOpenCommentPopup}
          handleOpenDeletePopup={handleOpenDeletePopup}
          handleOpenDetailPopup={handleOpenDetailPopup}
          setId={setId}
          id={id}
        />
      )}
      {selectedNavItem === "Ready To Post" && (
        <ReadyToPost
          handleOpenCommentPopup={handleOpenCommentPopup}
          handleOpenDeletePopup={handleOpenDeletePopup}
          handleOpenDetailPopup={handleOpenDetailPopup}
        />
      )}

      <Dialog
        open={openDetailPopup}
        onClose={() => setOpenDetailPopup(false)}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogContent
          sx={{
            padding: "9px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <ShootScheduleDetailPopup selectedItem={selectedItem} />
        </DialogContent>
      </Dialog>
      <Dialog open={openDeletePopup} onClose={() => setOpenDeletePopup(false)}>
        <DialogContent>
          <ShootScheduleDeletePopup
            id={id}
            setOpenDeletePopup={setOpenDeletePopup}
          />
        </DialogContent>
      </Dialog>

      <Dialog open={openDeletePopup} onClose={() => setOpenDeletePopup(false)}>
        <DialogContent>
          <ShootScheduleDeletePopup
            id={id}
            setOpenDeletePopup={setOpenDeletePopup}
            handleDeleteProperty={handleDeleteProperty}
          />
        </DialogContent>
      </Dialog>

      <Popover
        id={commentAnchorId}
        open={openCommentAnchor}
        anchorEl={commentAnchorEl}
        onClose={() => setCommentAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ShootScheduleComment id={id} />
      </Popover>
    </Box>
  );
}

export default ShootScheduleMain;
