import React from "react";

import {
  Grid,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
} from "@mui/material";

import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";

const pricePerSectionOnSale = [
  {
    id: 1,
    value: "",
    label: "",
  },
];
const pricePerSectionOnRent = [
  {
    id: 1,
    value: "day",
    label: "Day",
  },
  {
    id: 2,
    value: "week",
    label: "Week",
  },
  {
    id: 2,
    value: "month",
    label: "Month",
  },
  {
    id: 3,
    value: "year",
    label: "Year",
  },
];

function ApartmentRoomDetails({
  listedFor,
  roomDetailsData,
  setRoomDetailsData,
  incrementActiveSectionId,
  decrementActiveSectionId,
  changedData,
  setChangedData,
}) {
  const handleChange = (e) => {
    e.preventDefault();
    setRoomDetailsData({
      ...roomDetailsData,
      [e.target.name]: e.target.value,
    });
    setChangedData({
      ...changedData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    incrementActiveSectionId();
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            textAlign="start"
            color="black"
            fontSize="1.2rem"
            fontWeight="700"
            sx={{
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
              marginBottom: "0.5rem",
              marginTop: "1.2rem",
            }}
          >
            Room Details
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="number"
            label="Price"
            required
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.price || ""}
            name="price"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl size="small" fullWidth sx={{ maxWidth: "13rem" }}>
            <InputLabel>Per</InputLabel>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={roomDetailsData.price_per || ""}
              name="price_per"
              onChange={handleChange}
            >
              {listedFor === "sale"
                ? pricePerSectionOnSale.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))
                : pricePerSectionOnRent.map((item) => (
                    <MenuItem key={item.id} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl required>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="price_type"
              size="small"
              onChange={handleChange}
              value={roomDetailsData.price_type || ""}
            >
              <FormControlLabel
                value="fixed"
                control={<Radio />}
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>Fixed</Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                  },
                }}
              />
              <FormControlLabel
                value="negotiable"
                control={<Radio />}
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    Negotiable
                  </Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Bedrooms"
            required
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            name="bedroom"
            value={roomDetailsData.bedroom || ""}
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Kitchen"
            required
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.kitchen || ""}
            name="kitchen"
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Living rooms"
            required
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.living_room || ""}
            name="living_room"
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Bathrooms"
            required
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.bathroom || ""}
            name="bathroom"
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Store"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.store_room || ""}
            name="store_room"
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Pooja Room"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            name="pooja_room"
            value={roomDetailsData.pooja_room || ""}
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Extra Room"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.extra_room || ""}
            name="extra_room"
            onChange={handleChange}
          />{" "}
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Servant Quarter"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            value={roomDetailsData.servant_quarter || ""}
            onChange={handleChange}
          />{" "}
        </Grid>
        {listedFor === "rent" && (
          <>
            <Grid item xs={6}>
              <TextField
                type="date"
                id="outlined-basic"
                label="Available From"
                variant="outlined"
                required
                size="small"
                name="availability_from"
                value={roomDetailsData.availability_from || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ width: "100%", maxWidth: "13rem" }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                id="outlined-basic"
                label="Available To"
                variant="outlined"
                required
                size="small"
                name="availability_to"
                value={roomDetailsData.availability_to || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="furnish"
              onChange={handleChange}
              value={roomDetailsData.furnish || ""}
            >
              <FormControlLabel
                value="non-furnished"
                control={<Radio />}
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    Non-Furnished
                  </Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                  },
                }}
              />
              <FormControlLabel
                value="semi-furnished"
                control={<Radio />}
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    Semi-Furnished
                  </Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                  },
                }}
              />
              <FormControlLabel
                value="furnished"
                control={<Radio />}
                label={
                  <Typography sx={{ fontSize: "0.8rem" }}>
                    Fully-Furnished
                  </Typography>
                }
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                  },
                }}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>{" "}
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Button
          variant="outlined"
          style={{
            border: "none",
            color: "black",
            fontFamily: "Roboto Serif",
            fontSize: "0.9rem",
            fontWeight: "700",
            textTransform: "none",
          }}
          startIcon={<WestIcon />}
          onClick={decrementActiveSectionId}
        >
          Back
        </Button>
        <Button
          type="submit"
          variant="outlined"
          style={{
            border: "none",
            color: "black",
            fontFamily: "Roboto Serif",
            fontSize: "0.9rem",
            fontWeight: "700",
            textTransform: "none",
          }}
          endIcon={<EastIcon />}
        >
          Next
        </Button>
      </Box>
    </form>
  );
}

export default ApartmentRoomDetails;
