import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../config/base_url";

const initialState = {
  fieldVisitRequestList: [],
  fieldVisitRequestListLoading: false,
  fieldVisitRequestListError: null,
  fieldVisitRequestCommentList: [],
  fieldVisitRequestCommentListLoading: false,
  fieldVisitRequestCommentListError: null,
  fieldVisitRequestDetails: null,
  fieldVisitRequestDetailsLoading: false,
  fieldVisitRequestDetailsError: null,
  isLoading: false,
  error: null,
};

export const fetchFieldVisitRequestAsync = createAsyncThunk(
  "fieldVisitRequest/fetchFieldVisitRequestAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/field-visit-request`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchFieldVisitRequestCommentsAsync = createAsyncThunk(
  "fieldVisitRequest/fetchFieldVisitRequestCommentsAsync",
  async ({ request_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/field-visit-request-comment/${request_id}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchFieldVisitRequestDetailsByIdAsync = createAsyncThunk(
  "fieldVisitRequest/fetchFieldVisitRequestDetailsByIdAsync",
  async ({ request_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/field-visit-request/${request_id}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const addFieldVisitRequestAsync = createAsyncThunk(
  "fieldVisitRequest/addFieldVisitRequestAsync",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/property/field-visit-request`,
        data,
        { withCredentials: true }
      );
      if (response.status === 200) {
        toast.success("Schedule added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const updateRequestScheduleAsync = createAsyncThunk(
  "fieldVisitRequest/updateRequestScheduleAsync",
  async ({ request_id, schedule_date }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/property/field-visit-request/${request_id}`,
        { schedule_date },
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Schedule updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteFieldVisitRequestAsync = createAsyncThunk(
  "fieldVisitRequest/deleteFieldVisitRequestAsync",
  async ({ request_id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/property/field-visit-request/${request_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Schedule deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return request_id;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const fieldVisitRequestSlice = createSlice({
  name: "fieldVisitRequest",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchFieldVisitRequestAsync.pending, (state) => {
        state.fieldVisitRequestListLoading = true;
      })
      .addCase(fetchFieldVisitRequestAsync.fulfilled, (state, action) => {
        state.fieldVisitRequestListLoading = false;
        state.fieldVisitRequestList = action.payload;
      })
      .addCase(fetchFieldVisitRequestAsync.rejected, (state, action) => {
        state.fieldVisitRequestListLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFieldVisitRequestCommentsAsync.pending, (state) => {
        state.fieldVisitRequestCommentListLoading = true;
      })
      .addCase(
        fetchFieldVisitRequestCommentsAsync.fulfilled,
        (state, action) => {
          state.fieldVisitRequestCommentListLoading = false;
          state.fieldVisitRequestCommentList = action.payload;
        }
      )
      .addCase(
        fetchFieldVisitRequestCommentsAsync.rejected,
        (state, action) => {
          state.fieldVisitRequestCommentListLoading = false;
          state.fieldVisitRequestCommentListError = action.payload;
        }
      )
      .addCase(fetchFieldVisitRequestDetailsByIdAsync.pending, (state) => {
        state.fieldVisitRequestDetailsLoading = true;
      })
      .addCase(
        fetchFieldVisitRequestDetailsByIdAsync.fulfilled,
        (state, action) => {
          state.fieldVisitRequestDetailsLoading = false;
          state.fieldVisitRequestDetails = action.payload;
        }
      )
      .addCase(
        fetchFieldVisitRequestDetailsByIdAsync.rejected,
        (state, action) => {
          state.fieldVisitRequestDetailsLoading = false;
          state.fieldVisitRequestDetailsError = action.payload;
        }
      )

      .addCase(addFieldVisitRequestAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFieldVisitRequestAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addFieldVisitRequestAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateRequestScheduleAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRequestScheduleAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fieldVisitRequestDetails = action.payload;
      })
      .addCase(updateRequestScheduleAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteFieldVisitRequestAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFieldVisitRequestAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.fieldVisitRequestList = state.fieldVisitRequestList.filter(
          (item) => item.field_visit_id !== action.payload
        );
      })
      .addCase(deleteFieldVisitRequestAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});
export default fieldVisitRequestSlice.reducer;
