import { useSelector, useDispatch } from "react-redux";
// @mui
import {
  Box,
  List,
  Button,
  Tooltip,
  Divider,
  Typography,
  IconButton,
  ListSubheader,
  CircularProgress,
} from "@mui/material";
import "../../assets/images/LandingPageHouse.png";
// components
import DoneAllIcon from "@mui/icons-material/DoneAll";
import NotificationItem from "./notification_item";
import {
  fetchNotificationDetailAsync,
  markNotificationAsReadAsync,
} from "../../redux/notificationSlice";
import { useEffect, useState } from "react";

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const [visibleItem, setVisibleItem] = useState(2);
  const notificationDetail = useSelector(
    (state) => state.notification.notificationDetail
  );
  //todo set in usememo
  const totalUnRead = notificationDetail?.notifications?.filter(
    (item) => item.seen === false
  ).length;
  const isNotficationDetailLoading = useSelector(
    (state) => state.notification.isNotficationDetailLoading
  );
  const isMarkNotificationLoading = useSelector(
    (state) => state.notification.isMarkNotificationLoading
  );

  useEffect(() => {
    dispatch(fetchNotificationDetailAsync());
  }, [dispatch]);
  const handleMarkAllAsRead = () => {
    dispatch(markNotificationAsReadAsync());
  };

  const handleViewMore = () => {
    setVisibleItem(notificationDetail?.notifications?.length);
  };

  return (
    <>
      {isNotficationDetailLoading ? (
        <Box
          sx={{
            height: "10rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress />
          <Typography variant="body2" sx={{ color: "text.secondary" }}>
            Loading...
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            maxHeight: "80vh",
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle1">Notifications</Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                You have {notificationDetail.notificationCount} unread messages
              </Typography>
            </Box>

            {totalUnRead > 0 && (
              <Tooltip title=" Mark all as read">
                {isMarkNotificationLoading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    color="primary"
                    onClick={handleMarkAllAsRead}
                    sx={{ backgroundColor: "#f6f9fc" }}
                  >
                    {/* <Iconify icon="eva:done-all-fill" /> */}
                    <DoneAllIcon />
                  </IconButton>
                )}
              </Tooltip>
            )}
          </Box>
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            {notificationDetail?.notifications
              ?.slice(0, visibleItem)
              .map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                />
              ))}
          </List>
          <Divider sx={{ borderStyle: "dashed" }} />

          <Divider sx={{ borderStyle: "dashed" }} />
          {visibleItem !== notificationDetail?.notifications?.length && (
            <Box sx={{ p: 1 }}>
              <Button fullWidth disableRipple onClick={handleViewMore}>
                View More
              </Button>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
