import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Box,
  IconButton,
} from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";

import House from "../../assets/images/House.jpg";
import Apartment from "../../assets/images/Apartment.jpg";
import Land from "../../assets/images/Land.jpg";

import { getDate } from "../../utils/get_datetime";

function ShootScheduleCard({
  item,
  handleOpenDetailPopup,
  handleOpenDeletePopup,
  handleOpenCommentPopup,
  handleOpenStatusChangePopup,
  statusIcon,
}) {
  return (
    <Card
      sx={{
        maxWidth: 320,
        position: "relative",
        boxShadow: "rgba(0, 0, 0, 0.3) 2px 2px 3px",
      }}
    >
      {
        {
          house: (
            <CardMedia
              component="img"
              image={House}
              alt="house image"
              height={"280px"}
            />
          ),
          apartment: (
            <CardMedia
              component="img"
              image={Apartment}
              alt="apartment image"
              height={"280px"}
            />
          ),
          land: (
            <CardMedia
              component="img"
              image={Land}
              alt="land image"
              height={"280px"}
            />
          ),
        }[item?.property_type]
      }

      <CardContent
        sx={{
          backgroundColor: "rgba(247, 217, 217, 0.80)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "100%",
          borderRadius: "0.3rem",
        }}
      >
        <Typography
          sx={{
            color: "black",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {item?.property_type}
        </Typography>
        <Typography sx={{ color: "black", fontWeight: "500" }}>
          {getDate(item?.scheduled_date)}
        </Typography>
        <Typography
          sx={{
            color: "black",
            fontWeight: "500",
            textTransform: "capitalize",
          }}
        >
          {item?.location}
        </Typography>
      </CardContent>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          padding: "0.1rem 0.4rem",
          background: "#FA005A",
          textTransform: "capitalize",
        }}
      >
        <Typography sx={{ color: "white" }}>{item?.listed_for}</Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          right: "0",
          padding: "0.1rem 0.4rem",
          background: "#333",
          borderTopLeftRadius: "0.3rem",
        }}
      >
        <Typography
          sx={{ color: "white", cursor: "pointer" }}
          onClick={() => handleOpenDetailPopup(item)}
        >
          Details
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          left: "0",
          padding: "0.1rem 0.4rem",
          background: "#333",
          borderTopRightRadius: "0.3rem",
        }}
      >
        <Typography
          sx={{ color: "white", cursor: "pointer" }}
          onClick={() => handleOpenDeletePopup(item?.id)}
        >
          Delete
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
        }}
      >
        <IconButton
          onClick={(e) => handleOpenCommentPopup(e, item?.id)}
          sx={{ padding: "0" }}
        >
          <RateReviewIcon
            sx={{ color: "#363636", fontSize: "30px", cursor: "pointer" }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: "0",
          right: "50%",
          transform: "translate(50%,0)",
        }}
      >
        <IconButton
          onClick={(e) => handleOpenStatusChangePopup(item?.id)}
          sx={{ padding: "0", backgroundColor: "white" }}
        >
          {statusIcon}
        </IconButton>
      </Box>
    </Card>
  );
}

export default ShootScheduleCard;
