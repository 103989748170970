import { configureStore } from "@reduxjs/toolkit";
import locationReducer from "./locationSlice";
import overviewReducer from "./overviewSlice";
import staffMgmtReducer from "./staffMgmtSlice";
import authReducer from "./authSlice";
import fieldVisitRequestReducer from "./fieldVisitRequestSlice";
import adsReducer from "./adsSlice";
import blogReducer from "./blogSlice";
import servicesReducer from "./servicesSlice";
import shootScheduleReducer from "./shootScheduleSlice";
import chatReducer from "./chatSlice";
import notificationReducer from "./notificationSlice";
import contactReducer from "./contactSlice";
import homeLoanReducer from "./homeLoanSlice";
import requestMoreInfoReducer from "./requestMoreInfoSlice";
export const store = configureStore({
  reducer: {
    location: locationReducer,
    overview: overviewReducer,
    auth: authReducer,
    staffMgmt: staffMgmtReducer,
    fieldVisitRequest: fieldVisitRequestReducer,
    ads: adsReducer,
    blog: blogReducer,
    services: servicesReducer,
    shootSchedule: shootScheduleReducer,
    chat: chatReducer,
    notification: notificationReducer,
    contact: contactReducer,
    homeLoan: homeLoanReducer,
    requestMoreInfo: requestMoreInfoReducer,
  },
});
