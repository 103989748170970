import React from "react";
import { Dialog, DialogContent, Slide } from "@mui/material";
import LoginForm from "../../component/login/login_form";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
function LoginPage() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <>
      {isAuthenticated && <Navigate to="/" replace={true}></Navigate>}
      <Dialog
        open={true}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",

            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "350px", // Set your width here
            },
          },
        }}
      >
        <DialogContent sx={{ backgroundColor: "#F6EDED", padding: "1.5rem" }}>
          <LoginForm />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default LoginPage;
