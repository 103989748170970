import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAgentDetailsById } from "src/redux/overviewSlice";
function AgentDetailPopup({ selectedId, setOpenDetailsDialog }) {
  const dispatch = useDispatch();

  const agentDetails = useSelector((state) => state?.overview?.agentDetails);

  useEffect(() => {
    dispatch(fetchAgentDetailsById({ agentId: selectedId }));
  }, [dispatch, selectedId]);

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Agent ID : </td>
            <td>{selectedId}</td>
          </tr>
          <tr>
            <td>Agent Name : </td>
            <td>{agentDetails?.name}</td>
          </tr>
          <tr>
            <td> Email : </td>
            <td>{agentDetails?.email}</td>
          </tr>
          <tr>
            <td>Contact : </td>
            <td>{agentDetails?.phone_number}</td>
          </tr>
          <tr>
            <td>Province : </td>
            <td>{agentDetails?.user_agentProfile?.province}</td>
          </tr>
          <tr>
            <td>District : </td>
            <td>{agentDetails?.user_agentProfile?.district}</td>
          </tr>
          <tr>
            <td>Municipality : </td>
            <td>{agentDetails?.user_agentProfile?.municipality}</td>
          </tr>{" "}
          <tr>
            <td>Ward : </td>
            <td>{agentDetails?.user_agentProfile?.ward_number}</td>
          </tr>
          <tr>
            <td>Area Name : </td>
            <td>{agentDetails?.user_agentProfile?.area_name}</td>
          </tr>
          <tr>
            <td>Status : </td>
            <td>{agentDetails?.user_agentProfile?.status}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <p>
          Identification Type :{" "}
          {agentDetails?.user_agentProfile?.identification_type}
        </p>
        <p>
          Identification Number :{" "}
          {agentDetails?.user_agentProfile?.identification_number}
        </p>
        <img
          src={agentDetails?.user_agentProfile?.identification_image}
          alt="Agent Identification"
          style={{ width: "200px", height: "200px" }}
        />
        <img
          src={agentDetails?.user_agentProfile?.profile_image}
          alt="Agent Identification"
          style={{ width: "200px", height: "200px" }}
        />
      </div>
    </div>
  );
}

export default AgentDetailPopup;
