import React from "react";
import { Box, Button, Typography } from "@mui/material";

function SoldPropertiesPopup({
  setOpenSoldDialog,
  handlePropertySold,
  selectedId,
}) {
  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Do you want to mark property as sold? id = {selectedId}
      </Typography>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-evenly" }}
      >
        <Button
          variant="contained"
          onClick={handlePropertySold}
          sx={{
            backgroundColor: "red",
            ":hover": {
              backgroundColor: "red",
            },
          }}
        >
          Yes
        </Button>
        <Button variant="contained" onClick={() => setOpenSoldDialog(false)}>
          No
        </Button>
      </Box>
    </Box>
  );
}

export default SoldPropertiesPopup;
