import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import DeleteBlog from "./delete_blog_popup";
import BlogCard from "./blog_card";
import { fetchBlogAsync } from "../../redux/blogSlice";
function BlogList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const blogList = useSelector((state) => state.blog.blogList);
  const [blogName, setBlogName] = useState("");
  const blogListLoading = useSelector((state) => state.blog.blogListLoading);
  useEffect(() => {
    dispatch(fetchBlogAsync());
  }, [dispatch]);

  return (
    <Box sx={{ position: "relative" }}>
      {blogListLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={4} justifyContent={"center"}>
          {/*  */}
          {blogList.map((blog) => (
            <Grid item key={blog.blog_id}>
              <BlogCard
                blog={blog}
                setSelectedId={setSelectedId}
                setBlogName={setBlogName}
                setOpenDeleteDialog={setOpenDeleteDialog}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogContent
          sx={{
            padding: "18px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteBlog
            blogName={blogName}
            selectedId={selectedId}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default BlogList;
