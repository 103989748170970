import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Paper,
  Avatar,
  Typography,
  styled,
  Box,
  IconButton,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SendIcon from "@mui/icons-material/Send";
// import { socket } from "../../utils/socket";

import Ceo from "../../assets/images/Ceo.png";
// Dummy user data and messages for testing
const users = [
  { id: 1, name: "John Doe", avatar: "/path-to-john-avatar.png" },
  { id: 2, name: "Jane Smith", avatar: "/path-to-jane-avatar.png" },
  // Add more users here
];

const ChatContainer = styled(Paper)`
  display: flex;
  height: 79vh;
  width: 100%;
  justify-content: space-between;
`;

const UsersList = styled(Box)`
  flex: 1;
  padding: 8px 16px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.45),
    0px -1px 2px 0px rgba(0, 0, 0, 0.45);
  z-index: 1;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const UserItem = styled(Box)`
  display: flex;
  align-items: flex-start;
  padding: 8px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#f1f1f1" : "transparent")};

  &:hover {
    background-color: #f1f1f1;
  }
`;

const UserName = styled(Typography)`
  margin-left: 8px;
  font-size: 0.9rem;
  font-family: "Roboto Serif";
  font-weight: 500;
`;

const ChatBody = styled(Box)`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2rem;
`;

const Message = styled(Box)`
  margin-bottom: 1rem;
  width: 100%;
`;

const MessageAvatar = styled(Avatar)`
  margin-right: 8px;
`;

const MessageContainer = styled(Box)`
  // padding: 1rem;
  overflow-y: scroll;
  height: 100%;
`;
const GroupChat = () => {
  const [activeUser, setActiveUser] = useState(null);
  const [message, setMessage] = useState("");

  const previousClientMessages = useSelector(
    (state) => state.chat.previousClientMessages
  );

  // useEffect(() => {
  //   socket.connect();

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   function getUsers() {
  //     socket.on("getUsers", (users) => {
  //       console.log(users);
  //     });
  //   }
  //   getUsers();
  // }, []);

  const handleUserClick = (userId) => {
    setActiveUser(userId);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (message.trim() === "") return;
    setMessage("");
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };

  return (
    <ChatContainer>
      <UsersList>
        <TextField
          id="outlined-search"
          type="search"
          placeholder="Search"
          fullWidth
          InputProps={{
            style: {
              height: "50px",
              borderRadius: "10px",
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ marginBottom: "0.5rem" }}
        />

        {users.map((user) => (
          <Box key={user.id}>
            <UserItem
              // active={activeUser === user.id}
              onClick={() => handleUserClick(user.id)}
            >
              <Avatar src={Ceo} sx={{ width: 45, height: 45 }} />

              <UserName>{user.name}</UserName>
            </UserItem>
            <Divider />
          </Box>
        ))}
      </UsersList>
      <ChatBody>
        <Box
          sx={{
            display: "flex",
            marginBottom: "1rem",
            alignItems: "center",
            gap: "0.5rem",
            backgroundColor: "rgba(249, 171, 171,0.1)",
            padding: "1rem",
          }}
        >
          <Avatar sx={{ width: 40, height: 40 }} src={Ceo} />
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {users.find((user) => user.id === activeUser)?.name}
          </Typography>
        </Box>
        <MessageContainer>
          {previousClientMessages.map((msg, index) => {
            const senderId = msg.senderId;

            return (
              <Message key={index}>
                {senderId === 0 ? (
                  <Box
                    sx={{
                      paddingRight: { md: "50%", xs: "25%" },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: `${
                          msg.text.length > 100 ? "flex-end" : "center"
                        }}`,
                        justifyContent: "flex-start",

                        marginRight: "auto",
                        width: "fit-content",
                      }}
                    >
                      <MessageAvatar
                        src="/path-to-john-avatar.png"
                        sx={{ width: 30, height: 30 }}
                      />
                      <Box
                        sx={{
                          padding: "0.8rem 0.5rem",

                          borderTopRightRadius: "0.3rem",
                          borderBottomRightRadius: "0.3rem",
                          borderBottomLeftRadius: "0.3rem",
                          background: "#F6F6F6",
                        }}
                      >
                        <Typography variant="body2">{msg.text}</Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      paddingLeft: { md: "50%", xs: "25%" },
                    }}
                  >
                    <Box
                      sx={{
                        padding: "0.8rem 0.5rem",
                        background: "#D6E8BF",
                        borderTopRightRadius: "0.3rem",
                        borderBottomRightRadius: "0.3rem",
                        borderBottomLeftRadius: "0.3rem",

                        marginLeft: "auto",
                        width: "fit-content",
                      }}
                    >
                      <Typography variant="body2" sx={{ color: "black" }}>
                        {msg.text}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Message>
            );
          })}
        </MessageContainer>
        <Box
          sx={{
            width: "100%",
            height: "4rem",

            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "0px -4px 5px 0px rgba(0,0,0,0.1)",
          }}
        >
          <input
            type="text"
            placeholder="Write a message"
            style={{
              width: "100%",
              height: "100%",
              paddingLeft: "1rem",
              fontSize: "14px",
              border: "none",
            }}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <IconButton
            sx={{
              color: "white",
              backgroundColor: "inherit",
              transition: "all 0.2s ease-in-out",
              ":hover": {
                transform: "scale(1.1)",
              },
            }}
            type="submit"
            onClick={(e) => sendMessage(e)}
          >
            <SendIcon sx={{ color: "rgb(9, 163, 219)" }} />
          </IconButton>
        </Box>
      </ChatBody>
    </ChatContainer>
  );
};

export default GroupChat;
