import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addFieldVisitRequestAsync,
  fetchFieldVisitRequestAsync,
} from "../../redux/fieldVisitRequestSlice";
function AddFieldvisitRequest({ setAddFieldVisitRequestDialog }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = React.useState("");
  const [email, setEmail] = React.useState("");
  // const [address, setAddress] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [propertyId, setPropertyId] = React.useState("");
  const [propertyType, setPropertyType] = React.useState("house");
  const [requestDate, setRequestDate] = React.useState("");

  //name,email,contact,property_id,property_type-house,land,rent,request_date
  const isLoading = useSelector((state) => state.fieldVisitRequest.isLoading);
  const handleFieldVisitRequest = (e) => {
    e.preventDefault();
    const data = {
      name: firstName,
      email,
      contact,
      property_id: propertyId,
      property_type: propertyType,
      request_date: requestDate,
    };

    dispatch(addFieldVisitRequestAsync({ data })).then((action) => {
      if (addFieldVisitRequestAsync.fulfilled.match(action)) {
        setFirstName("");
        setEmail("");
        // setAddress("");
        setContact("");
        setPropertyId("");
        setPropertyType("house");
        setRequestDate("");
        dispatch(fetchFieldVisitRequestAsync());
        setAddFieldVisitRequestDialog(false);
      }
    });
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            color: "black",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
          variant="body1"
        >
          Add Field Visit Request
        </Typography>
      </Box>
      <hr />
      <Box sx={{ mt: "2rem", mb: "1rem", px: "2rem" }}>
        <form onSubmit={handleFieldVisitRequest}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.1rem",
            }}
          >
            <TextField
              type="text"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {/* <TextField
              id="outlined-basic"
              label="Address"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            /> */}
            <TextField
              label="Contact"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
            <FormControl fullWidth size="small">
              <InputLabel>Property Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={propertyType}
                label="Property Type"
                defaultValue=""
                onChange={(e) => setPropertyType(e.target.value)}
              >
                <MenuItem value="house">House</MenuItem>
                <MenuItem value="land">Land</MenuItem>
                <MenuItem value="apartment">Apartment</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Property Id"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={propertyId}
              onChange={(e) => setPropertyId(e.target.value)}
            />{" "}
            <TextField
              type="date"
              label="Request Date"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={requestDate}
              onChange={(e) => setRequestDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />{" "}
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                ":hover": {
                  backgroundColor: "#121212",
                },
              }}
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "Submitting" : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default AddFieldvisitRequest;
