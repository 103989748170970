import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import { fetchAgentList, verifyAgentById } from "../../../redux/overviewSlice";
// import { deleteStaff } from "../../redux/staffMgmtSlice";
function VerifyAgentPopup({
  selectedId,
  setOpenVerifyDialog,
  selectedIdStatus,
  setSelectedIdStatus,
}) {
  const dispatch = useDispatch();
  const handleVerifyAgent = () => {
    console.log("agent selected id", selectedId, selectedIdStatus);
    if (selectedIdStatus === "verified") return;

    dispatch(verifyAgentById({ agentId: selectedId, status: "verified" })).then(
      (res) => {
        if (verifyAgentById.fulfilled.match(res)) {
          dispatch(fetchAgentList());
          setOpenVerifyDialog(false);
          setSelectedIdStatus("");
        }
      }
    );
  };

  const handleUnVerfiyAgent = () => {
    if (selectedIdStatus === "pending") return;
    dispatch(verifyAgentById({ agentId: selectedId, status: "pending" })).then(
      (res) => {
        if (verifyAgentById.fulfilled.match(res)) {
          dispatch(fetchAgentList());
          setOpenVerifyDialog(false);
          setSelectedIdStatus("");
        }
      }
    );
  };

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Do you want to verify agent {selectedId}?
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleVerifyAgent}
        >
          Verify
        </Button>
        <Button
          sx={{
            backgroundColor: "rgb(44, 70, 242)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(42, 65, 219)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleUnVerfiyAgent}
        >
          Unverify
        </Button>
      </Box>
    </Box>
  );
}

export default VerifyAgentPopup;
