import React, { useState } from "react";
import {
  Box,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Typography,
  Select,
  InputLabel,
} from "@mui/material";

import SocialMediaIcons from "../social_media_icons/social_media_icons";
import PropertyMapImages from "./property_map_images";
import { formatPriceChange, unFormatPriceChange } from "src/utils/formatPrice";

const pricePerSectionOnSale = [
  {
    id: 1,
    value: "per ropani",
    label: "Ropani",
  },
  {
    id: 2,
    value: "per aana",
    label: "Aana",
  },
  {
    id: 3,
    value: "per dhur",
    label: "Dhur",
  },
  {
    id: 4,
    value: "per hatt",
    label: "Hatt",
  },
  {
    id: 5,
    value: "per katha",
    label: "Katha",
  },
];
const pricePerSectionOnRent = [
  {
    id: 1,
    value: "month",
    label: "Month",
  },
  {
    id: 2,
    value: "year",
    label: "Year",
  },
];

function LandSecondOtherDetails({
  listedFor,
  otherDetailsData,
  setOtherDetailsData,
}) {
  // const [description, setDescription] = useState("");
  // const [price, setPrice] = useState(0);
  // const [pricePerSection, setPricePerSection] = useState("per ropani");
  // const [priceType, setPriceType] = useState("");
  const [formattedPrice, setFormattedPrice] = useState("");
  const [amenities, setAminities] = useState({
    water_supply: false,
    sewer: false,
    electricity_supply: false,
  });
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    facebook: "",
    youtube: "",
    instagram: "",
    twitter: "",
    tiktok: "",
  });
  const handleAmenitiesChange = (e) => {
    const { name, checked } = e.target;
    setAminities({ ...amenities, [name]: checked });
    setOtherDetailsData({
      ...otherDetailsData,
      amenities: amenities,
    });
  };

  const handleChange = (event) => {
    event.preventDefault();

    setOtherDetailsData({
      ...otherDetailsData,
      [event.target.name]: event.target.value,
      social_media: socialMediaLinks,
    });
  };

  const handlePriceChange = (e) => {
    const rawValue = e.target.value;
    setOtherDetailsData({
      ...otherDetailsData,
      [e.target.name]: unFormatPriceChange(rawValue),
    });
    setFormattedPrice(formatPriceChange(rawValue));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextField
          type="text"
          label="Price"
          size="small"
          sx={{ width: "100%", maxWidth: "13rem" }}
          value={formattedPrice}
          name="price"
          onChange={handlePriceChange}
          required
        />{" "}
      </Grid>
      <Grid item xs={6}>
        <FormControl size="small" fullWidth sx={{ maxWidth: "13rem" }}>
          <InputLabel>Per</InputLabel>
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={otherDetailsData?.price_per}
            name="price_per"
            onChange={handleChange}
            required
          >
            {listedFor === "sale"
              ? pricePerSectionOnSale.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))
              : pricePerSectionOnRent.map((item) => (
                  <MenuItem key={item.id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Typography
          sx={{
            fontSize: "0.8rem",
            fontWeight: "bold",
          }}
        >
          Price Type
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            size="small"
            name="price_type"
            onChange={handleChange}
            value={otherDetailsData?.price_type}
            required
          >
            <FormControlLabel
              value="fixed"
              control={<Radio />}
              label={<Typography sx={{ fontSize: "0.8rem" }}>Fixed</Typography>}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                },
              }}
            />
            <FormControlLabel
              value="negotiable"
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: "0.8rem" }}>Negotiable</Typography>
              }
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                },
              }}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <SocialMediaIcons
          socialMediaLinks={socialMediaLinks}
          setSocialMediaLinks={setSocialMediaLinks}
        />
      </Grid>
      <Grid item xs={6}>
        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: "0.25rem",
            position: "relative",
            padding: "0.5rem 0.5rem 0.2rem 0.5rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              backgroundColor: "#F6EDED",
              position: "absolute",
              top: "-0.5rem",
              left: "1rem",
            }}
          >
            Amenities
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="water_supply"
                checked={otherDetailsData?.amenities?.water_supply || ""}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>Water Supply</Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="sewer"
                checked={otherDetailsData?.amenities?.sewer || ""}
                onChange={handleAmenitiesChange}
              />
            }
            label={<Typography sx={{ fontSize: "0.75rem" }}>Sewer</Typography>}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="electricity_supply"
                checked={otherDetailsData?.amenities?.electricity_supply || ""}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75em" }}>
                Electricity Supply
              </Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Description"
          multiline
          required
          rows={4}
          size="small"
          sx={{ width: "100%", maxWidth: "13rem" }}
          name="description"
          onChange={handleChange}
          value={otherDetailsData?.description}
        />
      </Grid>
      <PropertyMapImages
        descriptionData={otherDetailsData}
        setDescriptionData={setOtherDetailsData}
      />
    </Grid>
  );
}

export default LandSecondOtherDetails;
