import React from "react";

import { IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

function ShowImageDialog({
  setModalOpen,
  imagesLink,
  modalImageIndex,
  setModalImageIndex,
}) {
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNextClick = () => {
    if (modalImageIndex === imagesLink.length - 1) {
      setModalImageIndex(0);
    } else {
      setModalImageIndex((prev) => prev + 1);
    }
  };
  const handlePrevClick = () => {
    if (modalImageIndex === 0) {
      setModalImageIndex(imagesLink.length - 1);
    } else {
      setModalImageIndex((prev) => prev - 1);
    }
  };

  return (
    <>
      <IconButton
        className="close-modal-button"
        onClick={handleCloseModal}
        aria-label="Close"
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "#292929",
          "&:hover": {
            backgroundColor: "#202020",
          },
          opacity: "0.7",
        }}
      >
        <CloseIcon sx={{ color: "white" }} />
      </IconButton>
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          left: "0",
          transform: "translateY(-50%)",
          backgroundColor: "#292929",
          "&:hover": {
            backgroundColor: "#202020",
          },
          opacity: "0.7",
          borderRadius: "0",
        }}
        onClick={handlePrevClick}
      >
        <ArrowBackIosIcon sx={{ color: "white" }} />
      </IconButton>
      <img
        src={`${imagesLink[modalImageIndex]}` || ""}
        alt={`Full Screen ${modalImageIndex}`}
        style={{
          objectFit: "contain",
          width: "100%",
          maxHeight: "90vh",
          height: "100%",
        }}
      />
      <IconButton
        sx={{
          position: "absolute",
          top: "50%",
          right: "0",
          transform: "translateY(-50%)",
          backgroundColor: "#292929",
          "&:hover": {
            backgroundColor: "#202020",
          },
          opacity: "0.7",
          borderRadius: "0",
        }}
        onClick={handleNextClick}
      >
        <ArrowForwardIosIcon sx={{ color: "white" }} />
      </IconButton>
    </>
  );
}

export default ShowImageDialog;
