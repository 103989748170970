import React from "react";
import { Card, Box, IconButton } from "@mui/material";
import ReactPlayer from "react-player";

import DeleteIcon from "@mui/icons-material/Delete";

function VideoCarouselCard({ item, setSelectedId, setOpenDeleteDialog }) {
  const openDeleteDialog = () => {
    setSelectedId(item.id);
    setOpenDeleteDialog(true);
  };
  return (
    <Card
      sx={{
        maxWidth: 320,
        boxShadow: "rgba(0, 0, 0, 0.3) 2px 2px 3px",
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={openDeleteDialog}>
          <DeleteIcon
            sx={{ color: "red", fontSize: "30px", cursor: "pointer" }}
          />
        </IconButton>
      </Box>
      <ReactPlayer
        url={item?.link}
        muted={true}
        loop={true}
        playing={true}
        controls={true}
        width={"100%"}
        height={"280px"}
      />
    </Card>
  );
}

export default VideoCarouselCard;
