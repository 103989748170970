import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { Grid, Dialog, DialogContent, Slide } from "@mui/material";

import {
  deleteTestimonialByIdAsync,
  fetchTestimonialCarouselListAsync,
} from "../../redux/adsSlice";

import TestimonialCard from "./testimonial_card";
import DeleteCarouselPopup from "./delete_carousel_popup";
import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TestimonialCarouselList() {
  const dispatch = useDispatch();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const testimonialList = useSelector((state) => state.ads.testimonialList);
  const isFetchTestimonialCarouselLoading = useSelector(
    (state) => state.ads.isFetchTestimonialCarouselLoading
  );
  const testimonialFetchError = useSelector(
    (state) => state.ads.testimonialFetchError
  );

  useEffect(() => {
    dispatch(fetchTestimonialCarouselListAsync());
  }, [dispatch]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    dispatch(deleteTestimonialByIdAsync(selectedId)).then((action) => {
      if (deleteTestimonialByIdAsync.fulfilled.match(action)) {
        setOpenDeleteDialog(false);
      }
    });
  };
  return (
    <div>
      {isFetchTestimonialCarouselLoading || testimonialFetchError ? (
        <ShowLoaderError
          isLoading={isFetchTestimonialCarouselLoading}
          errorMessage={testimonialFetchError}
        />
      ) : (
        <Grid container spacing={3}>
          {testimonialList?.map((item) => (
            <Grid item key={item.id}>
              <TestimonialCard
                item={item}
                setSelectedId={setSelectedId}
                setOpenDeleteDialog={setOpenDeleteDialog}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        {" "}
        <DialogContent
          sx={{
            padding: "2rem",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteCarouselPopup
            handleDeleteClick={handleDeleteClick}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TestimonialCarouselList;
