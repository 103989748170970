import React from "react";
import AgentDetails from "./overview_agent_details";
import AgentTable from "./overview_agent_table";

function OverviewAgentList() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5rem",
      }}
    >
      <AgentDetails />
      <AgentTable />
    </div>
  );
}

export default OverviewAgentList;
