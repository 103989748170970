import React, { useState } from "react";
import { useSelector } from "react-redux";

//LIBARIES IMPORT
import { Box, Typography, IconButton } from "@mui/material";
import RequestPropertyHome from "./request_property_home";
import HouseIcon from "@mui/icons-material/House";
import BusinessIcon from "@mui/icons-material/Business";
import LandscapeIcon from "@mui/icons-material/Landscape";
import PlaceInfoModal from "../place_info_modal/place_info_modal";
import RequestPropertyApartment from "./request_property_apartment";
import RequestPropertyLand from "./request_property_land";
import CircularProgress from "@mui/material/CircularProgress";

function RequestPropertyModal() {
  const [selectedItem, setSelectedItem] = useState("home");
  const isLoading = useSelector((state) => state.overview.isLoading);
  return (
    <Box sx={{ position: "relative" }}>
      <Typography
        variant="body1"
        textAlign="center"
        color="black"
        sx={{ fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" } }}
        fontWeight="700"
      >
        Request a Property
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          marginTop: "1rem",
        }}
      >
        <Box>
          <IconButton onClick={() => setSelectedItem("home")}>
            <HouseIcon
              sx={{
                width: "2.6rem",
                height: "2.6rem",
                color: selectedItem === "home" ? "#31628D" : "#000",
              }}
            />
          </IconButton>
          <Typography
            variant="body1"
            textAlign="center"
            color="black"
            sx={{
              marginTop: "-0.5rem",
              borderBottom: selectedItem === "home" ? "3px solid #31628D" : "",
            }}
          >
            Home
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setSelectedItem("apartment")}>
            <BusinessIcon
              sx={{
                width: "2.6rem",
                height: "2.6rem",
                color: selectedItem === "apartment" ? "#31628D" : "#000",
              }}
            />
          </IconButton>
          <Typography
            variant="body1"
            textAlign="center"
            color="black"
            sx={{
              marginTop: "-0.5rem",
              borderBottom:
                selectedItem === "apartment" ? "3px solid #31628D" : "",
            }}
          >
            Apartment
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setSelectedItem("land")}>
            <LandscapeIcon
              sx={{
                width: "2.6rem",
                height: "2.6rem",
                color: selectedItem === "land" ? "#31628D" : "#000",
              }}
            />
          </IconButton>{" "}
          <Typography
            variant="body1"
            textAlign="center"
            color="black"
            sx={{
              marginTop: "-0.5rem",
              borderBottom: selectedItem === "land" ? "3px solid #31628D" : "",
            }}
          >
            Land
          </Typography>
        </Box>
      </Box>

      <Box sx={{ marginTop: "1rem", width: "80%", mx: "auto" }}>
        <Typography
          variant="body2"
          textAlign="start"
          color="black"
          fontSize="1.2rem"
          fontWeight="700"
          sx={{
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            marginBottom: "0.5rem",
          }}
        >
          Location
        </Typography>
        <PlaceInfoModal />
      </Box>

      <Box sx={{ marginTop: "1.2rem", mx: "auto", width: "80%" }}>
        <Typography
          variant="body2"
          textAlign="start"
          color="black"
          fontSize="1.2rem"
          fontWeight="700"
          sx={{
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
            marginBottom: "0.5rem",
          }}
        >
          Property Details
        </Typography>

        {selectedItem === "home" && <RequestPropertyHome />}
        {selectedItem === "apartment" && <RequestPropertyApartment />}
        {selectedItem === "land" && <RequestPropertyLand />}
      </Box>
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <CircularProgress className="custom-circular-progress" />
        </Box>
      )}
    </Box>
  );
}

export default RequestPropertyModal;
