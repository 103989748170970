import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { fetchFieldVisitRequestDetailsByIdAsync } from "../../redux/fieldVisitRequestSlice";
// import { splitLocation } from "../../utils/split_location";
function StaffDetails({ selectedId }) {
  const dispatch = useDispatch();
  const fieldVisitRequestDetails = useSelector(
    (state) => state.fieldVisitRequest.fieldVisitRequestDetails
  );
  useEffect(() => {
    dispatch(
      fetchFieldVisitRequestDetailsByIdAsync({ request_id: selectedId })
    );
  }, [dispatch, selectedId]);

  return (
    <Box sx={{ backgroundColor: "#F6EDED", padding: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="body1">
          <strong>Request_Id : </strong>
          {selectedId}
        </Typography>
        <a
          href={`${fieldVisitRequestDetails?.property_link}`}
          target="_blank"
          rel="noreferrer"
        >
          <Typography variant="body1"> View Details</Typography>
        </a>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <Box>
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography>
                    <strong>C.ID : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>{fieldVisitRequestDetails?.user_id}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    <strong>Full Name : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.user?.name}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td>
                  <Typography>
                    <strong>Email : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.user?.email}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td>
                  <Typography>
                    <strong>Contact : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.user?.phone_number}
                  </Typography>
                </td>
              </tr>

              <tr>
                <td>
                  <Typography>
                    <strong>Request Date : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.request_date?.split("T")[0]}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    <strong>Scheduled Date : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.schedule_date?.split("T")[0]}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    <strong>OTP Code : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.schedule_date
                      ? fieldVisitRequestDetails?.property_field_visit_otp?.otp
                      : "Not Scheduled Yet"}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
        <Box>
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography>
                    <strong>Property Id : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.property_id}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    <strong>Property Type : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.property_type}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    <strong>Location : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.ward}-
                    {fieldVisitRequestDetails?.municipality}
                    {fieldVisitRequestDetails?.district}
                  </Typography>
                </td>
              </tr>{" "}
              <tr>
                <td>
                  <Typography>
                    <strong>Owner Name : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.owner?.name}
                  </Typography>
                </td>
              </tr>{" "}
              <tr>
                <td>
                  <Typography>
                    <strong>Owner email : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.owner?.email}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography>
                    <strong>Contact : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>
                    {fieldVisitRequestDetails?.owner?.phone_number}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
}

export default StaffDetails;
