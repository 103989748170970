import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  isLoading: false,
  blogListLoading: false,
  errorMessage: null,
  blogList: [],
};

export const addBlogAsync = createAsyncThunk(
  "blog/addBlogAsync",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${BASE_URL}/admin/blog`, formData, {
        withCredentials: true,
      });
      if (response.status === 200) {
        toast.success("Blog added successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchBlogAsync = createAsyncThunk(
  "blog/fetchBlogAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/blog`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);
export const deleteBlogAsync = createAsyncThunk(
  "blog/deleteBlogAsync",
  async ({ blog_id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/admin/blog/${blog_id}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        toast.success("Blog deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return blog_id;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const blogListlice = createSlice({
  name: "blog",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogAsync.pending, (state) => {
        state.blogListLoading = true;
      })
      .addCase(fetchBlogAsync.fulfilled, (state, action) => {
        state.blogListLoading = false;
        state.blogList = action.payload;
      })
      .addCase(fetchBlogAsync.rejected, (state, action) => {
        state.blogListLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(addBlogAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBlogAsync.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addBlogAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(deleteBlogAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteBlogAsync.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(deleteBlogAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMessage = action.payload;
      });
  },
});

export default blogListlice.reducer;
