import React from "react";
import RequestDetail from "./request_detail";
import FieldVisitRequestList from "./field_visit_request_list";
import FieldRequestComment from "./request_comments";
import FieldVisitAnalytics from "./analytical_graph";
function AgentDashboardFieldVisit() {
  const [selectedId, setSelectedId] = React.useState(null);
  const [actionSelected, setActionSelected] = React.useState("fieldVisitGraph");

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "5rem",
        }}
      >
        <FieldVisitRequestList
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setActionSelected={setActionSelected}
        />
        <div style={{ width: "100%" }}>
          {actionSelected === "requestDetails" && selectedId && (
            <RequestDetail selectedId={selectedId} />
          )}
          {actionSelected === "fieldVisitGraph" && <FieldVisitAnalytics />}
          {actionSelected === "requestComments" && (
            <FieldRequestComment selectedId={selectedId} />
          )}
        </div>
      </div>
    </div>
  );
}

export default AgentDashboardFieldVisit;
