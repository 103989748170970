import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProvinces,
  fetchMunicipalities,
  fetchDistricts,
} from "../../redux/locationSlice";

import { styled, Grid, Box, TextField, MenuItem } from "@mui/material";
import { splitLocation } from "../../utils/split_location";

//For Filter Modal Select inputs
const SelectContainer = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  gap: "1.8rem",
  alignItems: "center",
}));

function EditPlaceInfo({
  changedData,
  setChangedData,
  propertyDetailsData,
  setPropertyDetailsData,
}) {
  const [provinceKey, setProvinceKey] = useState(1);
  const [districtKey, setDistrictKey] = useState(1);
  const [wardCount, setWardCount] = useState(0);

  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [municipality, setMunicipality] = useState("");
  const [ward, setWard] = useState("");

  const dispatch = useDispatch();

  const { provinces, districts, municipalities } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (provinces?.length === 0) {
      dispatch(fetchProvinces());
    }
    if (districts?.length === 0) {
      dispatch(fetchDistricts());
    }
    if (municipalities?.length === 0) {
      dispatch(fetchMunicipalities());
    }
  }, [dispatch, provinces, districts, municipalities]);

  const handleLocationSelect = (event) => {
    const { name, value } = event.target;
    let key;
    if (!value.includes("|")) key = value;
    else key = value.split("|")[0];

    if (name === "province") setProvinceKey(parseInt(key));
    if (name === "district") setDistrictKey(parseInt(key));
    if (name === "municipality") setWardCount(parseInt(key));

    if (name === "province") setProvince(value);
    if (name === "district") setDistrict(value);
    if (name === "municipality") setMunicipality(value);
    if (name === "ward") setWard(value);
    setChangedData({
      ...changedData,
      [name]: splitLocation(value),
    });
  };

  const handleChange = (e) => {
    setPropertyDetailsData({
      ...propertyDetailsData,
      [e.target.name]: e.target.value,
    });
    setChangedData({
      ...changedData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <SelectContainer>
        <Grid container spacing={2} rowGap={1}>
          <Grid item xs={6}>
            <TextField
              select
              label="Province"
              helperText={`${propertyDetailsData?.province}`}
              size="small"
              name="province"
              value={province || ""}
              onChange={handleLocationSelect}
              sx={{ maxWidth: "13rem", width: "100%" }}
            >
              {provinces?.map((province) => (
                <MenuItem
                  key={province.id}
                  value={`${province.id}|${province.name}`}
                >
                  {province.name.replace("Province", "")}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="District"
              helperText={`${propertyDetailsData?.district}`}
              size="small"
              name="district"
              value={district || ""}
              onChange={handleLocationSelect}
              sx={{ maxWidth: "13rem", width: "100%" }}
            >
              {districts
                ?.filter((district) => district.province_id === provinceKey)
                .map((district) => (
                  <MenuItem
                    key={district.id}
                    value={`${district.id}|${district.name}`}
                  >
                    {district.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              select
              label="Municipality"
              helperText={`${propertyDetailsData?.municipality}`}
              size="small"
              name="municipality"
              value={municipality || ""}
              onChange={handleLocationSelect}
              sx={{ maxWidth: "13rem", width: "100%" }}
            >
              {municipalities
                ?.filter(
                  (municipality) => municipality.district_id === districtKey
                )
                .map((municipality) => (
                  <MenuItem
                    key={municipality.id}
                    value={`${municipality.wards}|${municipality.name}`}
                  >
                    {municipality.name}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-select-currency"
              select
              label="Ward"
              helperText={`${propertyDetailsData?.ward}`}
              size="small"
              name="ward"
              value={ward || ""}
              onChange={handleLocationSelect}
              sx={{
                maxWidth: "13rem",
                width: "100%",
              }}
            >
              {[...Array(wardCount).keys()]?.map((_, index) => (
                <MenuItem key={index + 1} value={`${index + 1}`}>
                  {index + 1}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Area Name"
              required
              size="small"
              name="area_name"
              sx={{ width: "100%", maxWidth: "13rem" }}
              onChange={handleChange}
              value={propertyDetailsData?.area_name || ""}
            />
          </Grid>{" "}
          <Grid item xs={6}>
            <TextField
              label="LandMark"
              required
              name="landmark"
              value={propertyDetailsData?.landmark || ""}
              onChange={handleChange}
              placeholder="(near by hospital)"
              size="small"
              multiline
              sx={{
                width: "100%",
                maxWidth: "13rem",
              }}
            />
          </Grid>
        </Grid>
      </SelectContainer>
    </>
  );
}

export default EditPlaceInfo;
