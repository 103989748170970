import React, { useEffect } from "react";
import { Grid, Dialog, DialogContent, Slide, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import RequestMoreInfoCard from "./home_loan_card";
import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";
import {
  fetchHomeLoanRequestList,
  deleteHomeLoanRequestAsync,
} from "src/redux/homeLoanSlice";
import DeletePopup from "../popup/delete_popup";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HomeLoanList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(null);

  const homeLoanRequestList = useSelector(
    (state) => state.homeLoan.homeLoanRequestList
  );
  const homeLoanRequestListLoading = useSelector(
    (state) => state.homeLoan.homeLoanRequestListLoading
  );
  const homeLoanRequestListError = useSelector(
    (state) => state.homeLoan.homeLoanRequestListError
  );

  useEffect(() => {
    dispatch(fetchHomeLoanRequestList());
  }, [dispatch]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    dispatch(deleteHomeLoanRequestAsync({ id: selectedId })).then((action) => {
      if (deleteHomeLoanRequestAsync.fulfilled.match(action)) {
        setOpenDeleteDialog(false);
      }
    });
  };
  return (
    <div style={{ marginTop: "1rem" }}>
      <Box sx={{ padding: "2rem 4rem" }}>
        {homeLoanRequestListLoading || homeLoanRequestListError ? (
          <ShowLoaderError
            isLoading={homeLoanRequestListLoading}
            errorMessage={homeLoanRequestListError}
          />
        ) : (
          <Grid container spacing={2}>
            {homeLoanRequestList?.map((item) => (
              <Grid item xs={12} key={item?.id}>
                <RequestMoreInfoCard
                  item={item}
                  setSelectedId={setSelectedId}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        {" "}
        <DialogContent
          sx={{
            padding: "2rem",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeletePopup
            handleDelete={handleDeleteClick}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default HomeLoanList;
