import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Container from "@mui/material/Container";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";

import TableNoData from "../../table/table-no-data";
import UserTableHead from "../../table/user-table-head";
import TableEmptyRows from "../../table/table-empty-rows";

import { emptyRows, applyFilter, getComparator } from "../../table/utils";

// import { BASE_URL } from 'src/config/base_url';

import RecentTableRow from "./recent_table_row";
import RecentTableToolbar from "./recent_table_toolbar";
// import useDebounce from "src/hooks/useDebounce";
import {
  fetchRecentProperty,
  searchRecentProperty,
} from "src/redux/overviewSlice";

// ----------------------------------------------------------------------

const serviceHeadLabel = [
  {
    id: "p_id",

    label: "P_ID",
  },
  {
    id: "property_title",
    label: "Property Title",
  },
  {
    id: "location",

    label: "Location",
  },
  {
    id: "posted",
    label: "Posted",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "listingType",
    label: "Listing Type",
  },
  {
    id: "ads",
    label: "Ads",
  },
];

const ROWSPERPAGE = 5;

export default function RecentTableMain() {
  const dispatch = useDispatch();

  const [selectedId, setSelectedId] = useState(null);

  const [selectedType, setSelectedType] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [searchPropertyType, setSearchProperType] = useState("");
  const [searchPropertyListedFor, setSearchPropertyListedFor] = useState("");

  const [page, setPage] = useState(0);

  // const [serverPage, setServerPage] = useState(1);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [rowsPerPage, setRowsPerPage] = useState(ROWSPERPAGE);

  const recentPropertyList = useSelector(
    (state) => state.overview.recentPropertyList
  );

  //   const debouncedSearchQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    dispatch(fetchRecentProperty());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      searchRecentProperty({
        location: searchQuery,
        property_type: searchPropertyType,
        listed_for: searchPropertyListedFor,
      })
    );
  }, [searchPropertyListedFor, searchPropertyType, searchQuery, dispatch]);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === "asc";
    if (id !== "") {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(id);
    }
  };

  const handleClick = (pId, type) => {
    if (selectedId === null) {
      setSelectedId(pId);
      setSelectedType(type);
    } else {
      setSelectedId(null);
      setSelectedType("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    //todo
    // console.log("new page", newPage);
    // if (newPage === 0) {
    //   setServerPage(1);
    // } else if (newPage % 3 === 0) {
    //   setServerPage(newPage / 3 + 1);
    // }
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const dataFiltered = applyFilter({
    inputData: recentPropertyList,
    comparator: getComparator(order, orderBy),
  });

  const notFound = !dataFiltered.length;

  return (
    <Container disableGutters>
      <Card>
        <RecentTableToolbar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          searchPropertyListedFor={searchPropertyListedFor}
          setSearchPropertyListedFor={setSearchPropertyListedFor}
          searchPropertyType={searchPropertyType}
          setSearchProperType={setSearchProperType}
          selectedId={selectedId}
          selectedType={selectedType}
        />

        {/* <Scrollbar sx={{ border: "1px solid red" }}> */}
        <TableContainer sx={{ overflow: "unset" }}>
          <Table sx={{ minWidth: 800 }}>
            <UserTableHead
              order={order}
              orderBy={orderBy}
              rowCount={recentPropertyList.length}
              numSelected={selectedId}
              onRequestSort={handleSort}
              headLabel={serviceHeadLabel}
            />
            <TableBody>
              {dataFiltered
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <RecentTableRow
                    key={row.property_id}
                    pId={row.property_id}
                    index={index}
                    propertyType={row?.property_type}
                    propertyTitle={row?.property_name}
                    location={row?.district}
                    posted={row?.createdAt}
                    listedFor={row?.listed_for}
                    listingType={row?.listing_type}
                    facebook={row?.ads?.facebook}
                    youtube={row?.ads?.youtube}
                    instagram={row?.ads?.instagram}
                    twitter={row?.ads?.twitter}
                    tiktok={row?.ads?.tiktok}
                    selectedId={selectedId}
                    setSelectedId={setSelectedId}
                    handleClick={handleClick}
                  />
                ))}

              <TableEmptyRows
                height={77}
                emptyRows={emptyRows(
                  page,
                  rowsPerPage,
                  recentPropertyList.length
                )}
              />

              {notFound && (
                <TableNoData columnSpan={serviceHeadLabel?.length} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* </Scrollbar> */}

        <TablePagination
          page={page}
          component="div"
          count={recentPropertyList.length}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[ROWSPERPAGE]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}
