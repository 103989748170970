import React from "react";
import { useSelector } from "react-redux";

import { Typography, Box, Button } from "@mui/material";

function ShootScheduledStatusPopup({
  id,
  setOpenStatusChangePopup,
  handleChangeStatus,
}) {
  const isLoading = useSelector((state) => state.shootSchedule.isLoading);

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Do you want to move scheduled property id = {id}?
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleChangeStatus}
          disabled={isLoading}
        >
          {isLoading ? "Deleting" : "Yes"}
        </Button>
        <Button
          sx={{
            backgroundColor: "rgb(44, 70, 242)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(42, 65, 219)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={() => setOpenStatusChangePopup(false)}
        >
          No
        </Button>
      </Box>
    </Box>
  );
}

export default ShootScheduledStatusPopup;
