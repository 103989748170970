import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Button,
  Popover,
  Tooltip,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";

import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
import AppsIcon from "@mui/icons-material/Apps";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ForumIcon from "@mui/icons-material/Forum";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LinkedCameraIcon from "@mui/icons-material/LinkedCamera";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import BarChartIcon from "@mui/icons-material/BarChart";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import MoreIcon from "@mui/icons-material/MoreVert";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import logo from "../../assets/images/logo.png";
import AdminDashboardOverview from "../overview/overview_main";
import AdminDashboardStatistics from "../statistics/admin_dashboard_statistics_main";
import AdminDashboardChat from "../chat/admin_dashboard_chat";
import AgentDashboardFieldVisit from "../field_visit_request/admin_dashboard_field_visit";
import AdminDashboardStaffMgmt from "../staff_management/admin_dashboard_staff_mgmt_main";
import AgentDashboardNotification from "../notification/admin_dashboard_notification_popover";
import AgentDashboardAccount from "./admin_dashboard_account_popover";
import AdminDashboardServices from "../services/services_main";
import AdminDashboardShootSchedule from "../shoot_schedule/shoot_schedule_section";
import AdminDashboardContact from "../contact/contact_main";
import AdminDashboardRequestMoreInfo from "../request_more_info/request_more_info_main";
import AdminDashboardAds from "../ads/admin_dashboard_ads";
import AdminDashboardBlogs from "../blogs/blogs_main";
import AddPropertyPopup from "../add_property/add_property_popup";
import AddPropertyModal from "../add_property/add_property_modal";
import { fetchNotificationDetailAsync } from "../../redux/notificationSlice";
import HomeLoanMain from "../home_loan/home_loan_main";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
var navItems = [
  {
    label: "Property List",
  },

  {
    label: "Customer List",
  },
  {
    label: "Agents",
  },
];

export default function DashboardSidebar() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [menuData, setMenuData] = useState("Overview");
  const [selectedNavItem, setSelectedNavItem] = useState("Property List");
  const [openAddPropertyPopup, setOpenAddPropertyPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const isAccountOpen = Boolean(accountAnchorEl);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationOpen = Boolean(notificationAnchorEl);
  const notificationId = isNotificationOpen ? "simple-popover" : undefined;
  const accountId = isAccountOpen ? "simple-popover" : undefined;
  const notificationDetail = useSelector(
    (state) => state.notification.notificationDetail
  );

  useEffect(() => {
    dispatch(fetchNotificationDetailAsync());
  }, [dispatch]);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changeNavItems = (text) => {
    if (text === "Overview") {
      navItems = [
        {
          label: "Property List",
        },

        {
          label: "Customer List",
        },
        {
          label: "Agents",
        },
      ];
      setSelectedNavItem("Property List");
    } else if (text === "Chat") {
      navItems = [
        {
          label: "Client",
        },
        {
          label: "Staff",
        },
        {
          label: "Groups",
        },
      ];
      setSelectedNavItem("Client");
    } else if (text === "Shoot Schedule") {
      navItems = [
        {
          label: "Scheduled Property",
        },
        {
          label: "Shoot Completed",
        },
        {
          label: "Ready To Post",
        },
      ];
      setSelectedNavItem("Scheduled Property");
    } else {
      navItems = [];
    }
  };
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );
  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={(e) => setNotificationAnchorEl(e.currentTarget)}>
        <IconButton
          size="large"
          aria-label="show notifications"
          color="inherit"
        >
          <Badge
            badgeContent={notificationDetail?.notificationCount || ""}
            color="error"
            invisible={
              notificationDetail?.notificationCount === 0 ? true : false
            }
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={(e) => setAccountAnchorEl(e.currentTarget)}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar
        position="fixed"
        open={open}
        style={{
          background: "#F6EDED",
          height: "4.5rem",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: "#F6EDED",
            paddingRight: "2.5rem !important",
            height: "100%",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              height: "5rem",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ fill: "black" }} />
          </IconButton>
          <Box
            display="flex"
            // backgroundColor="yellow"
            width="100%"
            justifyContent={"space-evenly"}
            alignItems="center"
          >
            <Box
              justifyContent="space-around"
              width="40%"
              alignItems="center"
              sx={{
                display: { xs: "none", md: "flex" },
              }}
            >
              {navItems.map((item, index) => (
                <Typography
                  variant="body2"
                  noWrap
                  sx={{ color: "black", fontSize: "16px", cursor: "pointer" }}
                  key={index}
                  onClick={() => setSelectedNavItem(item.label)}
                  className={
                    selectedNavItem === item.label ? "bottom_border" : ""
                  }
                >
                  {item.label}
                </Typography>
              ))}
            </Box>

            <Box
              sx={{
                display: {
                  xs: "none",
                  md: "flex",
                  gap: "8rem",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                },
              }}
            >
              <Typography
                variant="body2"
                noWrap
                sx={{ color: "black", fontSize: "16px", cursor: "pointer" }}
                onClick={() => {
                  setMenuData("Overview");
                  changeNavItems("Overview");
                  setSelectedNavItem("Request");
                }}
                className={selectedNavItem === "Request" ? "bottom_border" : ""}
              >
                Request{" "}
              </Typography>
              <Button
                style={{
                  color: "black",
                  backgroundColor: "#d9d9d9",
                  fontSize: "12px",
                  fontFamily: "Roboto Serif",
                  letterSpacing: "0.5px",
                  fontWeight: "500",
                  textTransform: "none",
                }}
                variant="contained"
                endIcon={<AddIcon />}
                onClick={() => setOpenAddPropertyPopup(true)}
              >
                Add a Property
              </Button>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: "1rem" }}>
            <IconButton
              size="large"
              aria-label="show notifications"
              color="inherit"
              onClick={(e) => setNotificationAnchorEl(e.currentTarget)}
            >
              <Badge
                badgeContent={notificationDetail?.notificationCount || ""}
                color="error"
                invisible={
                  notificationDetail?.notificationCount === 0 ? true : false
                }
              >
                <NotificationsIcon sx={{ fill: "black" }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={(e) => setAccountAnchorEl(e.currentTarget)}
            >
              <AccountCircle sx={{ fill: "black" }} />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              //   color="inherit"
            >
              <MoreIcon sx={{ fill: "black" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          elevation: 8,
          style: {
            backgroundColor: "#F6EDED",
          },
        }}
      >
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          {open && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: "2.5rem",
              }}
            >
              <img src={logo} alt="logo" />
              <Box>
                <Typography
                  variant="h6"
                  sx={{ color: "#C60F30", fontSize: "0.875rem" }}
                >
                  Welcome
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#149C32",
                    fontWeight: "bold",
                    fontSize: "1.25rem",
                  }}
                >
                  Gopal Baraudi
                </Typography>
              </Box>
            </Box>
          )}
          <IconButton
            onClick={handleDrawerClose}
            elevation={10}
            sx={{
              alignSelf: "flex-end",
              position: "absolute",
              right: "-1.1rem",
              bottom: "-1.2rem",
              border: "1px solid #C4C4C4",
            }}
          >
            <ArrowBackIosNewRoundedIcon
              fontSize="string"
              sx={{
                backgroundColor: "rgb(240, 240, 240)",
                borderRadius: "50%",
                ":hover": {
                  backgroundColor: "rgb(200, 200, 200)",
                },
              }}
            />
          </IconButton>
        </DrawerHeader>
        <br />
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <List>
            {[
              "Overview",
              "Statistics",
              "Staff Mgmt",
              "Chat",
              "Field Visit Request",
              "Shoot Schedule",
              "Services",
              "News/Blogs",
              "Ad With Us",
              "Contact",
              "Home Loan",
              "Request More Info",
            ].map((text, index) => (
              <Tooltip key={text} title={text} placement="right-end">
                <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() => {
                    setMenuData(text);
                    changeNavItems(text);
                  }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                    className={menuData === text ? "square__bracket" : ""}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {index === 0 && <AppsIcon />}

                      {index === 1 && <BarChartIcon />}
                      {index === 2 && <ManageAccountsIcon />}
                      {index === 3 && <ForumIcon />}
                      {index === 4 && <EmojiPeopleIcon />}
                      {index === 5 && <LinkedCameraIcon />}
                      {index === 6 && <Diversity3Icon />}
                      {index === 7 && <NewspaperIcon />}
                      {index === 8 && <LiveTvIcon />}
                      {index === 9 && <ContactPageIcon />}
                      {index === 10 && <AttachMoneyIcon />}
                      {index === 11 && <RequestQuoteIcon />}
                    </ListItemIcon>

                    <ListItemText
                      primary={text}
                      sx={{
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            ))}
            <Divider />

            <Box sx={{ display: { xs: "block", md: "none" } }}>
              {navItems.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 10,
                    }}
                  >
                    <ListItemText
                      primary={item.label}
                      sx={{
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </Box>
            <Divider />
            <Button
              sx={{
                color: "black",
                backgroundColor: "#d9d9d9",
                fontSize: "12px",
                fontFamily: "Inter",
                letterSpacing: "0.5px",
                fontWeight: "500",
                textTransform: "none",
                display: { xs: "flex", md: "none" },
                mx: 8,
                my: 2,
              }}
              variant="contained"
              endIcon={<AddIcon />}
              onClick={() => setOpenAddPropertyPopup(true)}
            >
              Add a Property
            </Button>
          </List>

          <List>
            <ListItem sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>

                <ListItemText
                  primary="Settings"
                  sx={{
                    opacity: open ? 1 : 0,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 6, mt: 8 }}>
        {menuData === "Overview" && (
          <AdminDashboardOverview selectedNavItem={selectedNavItem} />
        )}
        {menuData === "Statistics" && <AdminDashboardStatistics />}
        {menuData === "Staff Mgmt" && <AdminDashboardStaffMgmt />}
        {menuData === "Chat" && (
          <AdminDashboardChat selectedNavItem={selectedNavItem} />
        )}
        {menuData === "Field Visit Request" && <AgentDashboardFieldVisit />}
        {menuData === "Shoot Schedule" && (
          <AdminDashboardShootSchedule selectedNavItem={selectedNavItem} />
        )}
        {menuData === "Services" && <AdminDashboardServices />}
        {menuData === "News/Blogs" && <AdminDashboardBlogs />}
        {menuData === "Ad With Us" && <AdminDashboardAds />}
        {menuData === "Contact" && <AdminDashboardContact />}
        {menuData === "Home Loan" && <HomeLoanMain />}
        {menuData === "Request More Info" && <AdminDashboardRequestMoreInfo />}
        {renderMobileMenu}
        {renderMenu}
        <Popover
          id={notificationId}
          open={isNotificationOpen}
          anchorEl={notificationAnchorEl}
          onClose={(e) => setNotificationAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              mt: 1.5,
              ml: 0.75,
              width: 360,
            },
          }}
        >
          <AgentDashboardNotification />
        </Popover>
        <Popover
          id={accountId}
          open={isAccountOpen}
          anchorEl={accountAnchorEl}
          onClose={() => setAccountAnchorEl(null)}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{
            p: 0,
            mt: 1.5,
            ml: 0.75,

            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          }}
        >
          <AgentDashboardAccount setAccountAnchorEl={setAccountAnchorEl} />
        </Popover>
        <AddPropertyPopup
          s
          openAddPropertyPopup={openAddPropertyPopup}
          setOpenAddPropertyPopup={setOpenAddPropertyPopup}
        >
          <AddPropertyModal setOpenAddPropertyPopup={setOpenAddPropertyPopup} />
        </AddPropertyPopup>
      </Box>
    </Box>
  );
}
