import React from "react";
import { TextField } from "@mui/material";
function SearchBar() {
  return (
    <>
      <TextField
        label="Search"
        placeholder="Search by type(like House) or location..."
        fullWidth
        size="small"
      />
    </>
  );
}

export default SearchBar;
