import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
function PrivateRoutes({ allowedRoles }) {
  const location = useLocation();
  const role = useSelector((state) => state.auth.role);
  return allowedRoles.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}
export default PrivateRoutes;
