import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Divider,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  fetchPropertyComments,
  addPropertyComment,
} from "../../../redux/overviewSlice";
import { getDate } from "../../../utils/get_datetime";

function Comment({ selectedId, selectedType }) {
  const dispatch = useDispatch();
  const [message, setMessage] = React.useState("");
  const [initialFetch, setInitialFetch] = React.useState(true);
  const commentsContainerRef = React.useRef(null);
  const commentLoading = useSelector((state) => state.overview.commentLoading);
  const userId = useSelector((state) => state.auth.userId);
  const comments = useSelector((state) => state.overview.propertyComments);
  useEffect(() => {
    if (initialFetch) {
      try {
        dispatch(
          fetchPropertyComments({
            property_id: selectedId,
            propertyType: selectedType,
          })
        );

        setInitialFetch(false);
      } catch (e) {
        console.log(e);
      }
    }
  }, [dispatch, selectedId, selectedType, initialFetch]);
  useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight;
    }
  }, [comments]);
  const handlePostComment = async () => {
    if (message.trim() === "") return;
    try {
      await dispatch(
        addPropertyComment({
          property_id: selectedId,
          propertyType: selectedType,
          comment: message,
        })
      ).then(() => {
        dispatch(
          fetchPropertyComments({
            property_id: selectedId,
            propertyType: selectedType,
          })
        );
        setMessage("");
      });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Box p={2} width={500}>
      <Typography variant="h5" gutterBottom>
        Comments
      </Typography>
      <hr />

      {commentLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 400 }}
        >
          <CircularProgress />
        </Box>
      ) : comments?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 400 }}
        >
          <Typography variant="h5">No Comments</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            height: 400,
            overflow: "scroll",
            overflowX: "hidden",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
          ref={commentsContainerRef}
        >
          {comments?.map((cmt) => (
            <Box
              key={cmt.comment_id}
              display="flex"
              flexDirection={cmt.admin_id === userId ? "row-reverse" : "row"}
              alignItems="flex-start"
              mb={2}
            >
              <Box
                sx={{
                  maxWidth: "80%",
                  backgroundColor: cmt.admin_id === userId ? "#F6EDED" : "#eee",
                  padding: "16px",
                  borderTopLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              >
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{
                    textAlign: cmt.admin_id === userId ? "end" : "start",
                    fontWeight: "bold",
                  }}
                >
                  {cmt.user_adminAccount.name}
                </Typography>
                <Typography variant="body2">{cmt.comment}</Typography>
                <Typography variant="caption" color="textSecondary">
                  {getDate(cmt?.createdAt)}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Divider />
      <Box mt={2}>
        <Paper variant="outlined">
          <Box p={1}>
            <TextField
              fullWidth
              multiline
              rows={2}
              label="Your Message"
              variant="outlined"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  handlePostComment();
                }
              }}
            />
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePostComment}
              >
                Send
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default Comment;
