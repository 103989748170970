import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  styled,
  Typography,
} from "@mui/material";
import { fetchStaffDetailById } from "../../redux/staffMgmtSlice";
import { BASE_URL } from "../../config/base_url";

import { formatPrice } from "../../utils/formatPrice";

const TitleText = styled(Typography)(({ theme }) => ({
  color: "black",
  width: "100%",
  fontSize: "1rem",
  marginRight: "0.75rem",
  textAlign: "left",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.75rem",
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  color: "black",
  textAlign: "left",
  width: "100%",
  textTransform: "capitalize",
  fontSize: "0.85rem",
  fontWeight: "500",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.68rem",
  },
}));

function StaffDetails({ selectedId }) {
  const dispatch = useDispatch();

  const [openFirstImageDialog, setOpenFirstImageDialog] = useState(false);
  const [openSecondImageDialog, setOpenSecondImageDialog] = useState(false);

  const staffDetails = useSelector((state) => state.staffMgmt.staffDetails);
  const staffDetailLoading = useSelector(
    (state) => state.staffMgmt.staffDetailLoading
  );

  useEffect(() => {
    dispatch(fetchStaffDetailById({ staff_id: selectedId }));
  }, [dispatch, selectedId]);

  return (
    <Box sx={{ backgroundColor: "#F6EDED", padding: "2rem" }}>
      <Typography>
        <strong>S_ID : </strong>
        {selectedId}
      </Typography>
      {staffDetailLoading ? (
        <Box>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box>
              <table className="center_table">
                <tbody>
                  <tr>
                    <td>
                      <TitleText>Full Name : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.name}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Responsibility : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.responsibility}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Email : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.email}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Gender</TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.gender}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Address : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.address}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Contact : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.contact}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Tenure : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.tenure} years</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>PAN No : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.pan_no}</Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Box>
              <table className="center_table">
                <tbody>
                  <tr>
                    <td>
                      <TitleText>Recruited Date : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.recruited_date}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Salary :</TitleText>
                    </td>
                    <td>
                      <Text>Rs. {formatPrice(staffDetails?.salary)}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Qualification : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.qualification}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <TitleText>Date of Birth : </TitleText>
                    </td>
                    <td>
                      <Text>{staffDetails?.date_of_birth}</Text>
                    </td>
                  </tr>
                  {/* <tr>
                <td>
                  <Typography>
                    <strong>Leave : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>04</Typography>
                </td>
              </tr> */}
                  {/* <tr>
                <td>
                  <Typography>
                    <strong>Property Dealed : </strong>
                  </Typography>
                </td>
                <td>
                  <Typography>7</Typography>
                </td>
              </tr> */}
                  <tr>
                    <td>
                      <TitleText>Document : </TitleText>
                    </td>
                    <td>
                      <span onClick={() => setOpenFirstImageDialog(true)}>
                        <img
                          src={`${BASE_URL}/${staffDetails?.documents[0]}`}
                          alt="document"
                          style={{ width: "100px", height: "80px" }}
                        />
                      </span>
                    </td>

                    <td>
                      <span onClick={() => setOpenSecondImageDialog(true)}>
                        <img
                          src={`${BASE_URL}/${staffDetails?.documents[1]}`}
                          alt="document"
                          style={{ width: "100px", height: "80px" }}
                        />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
          <Dialog
            open={openFirstImageDialog}
            onClose={() => setOpenFirstImageDialog(false)}
            maxWidth={"sm"}
            fullWidth
          >
            <DialogContent>
              <img
                src={`${BASE_URL}/${staffDetails?.documents[0]}`}
                alt="document"
                style={{ width: "100%", height: "100%" }}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={openSecondImageDialog}
            onClose={() => setOpenSecondImageDialog(false)}
            maxWidth={"sm"}
            fullWidth
          >
            <DialogContent>
              <img
                src={`${BASE_URL}/${staffDetails?.documents[1]}`}
                alt="document"
                style={{ width: "100%", height: "100%" }}
              />
            </DialogContent>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default StaffDetails;
