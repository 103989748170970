import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { addStaff, fetchStaffList } from "../../redux/staffMgmtSlice";
function AddStaff({ setAddStaffDialog }) {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [responsibility, setResponsibility] = useState("");
  const [gender, setGender] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [sarlary, setSalary] = useState("");
  const [qualification, setQualification] = useState("");
  const [tenure, setTenure] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [recruitedDate, setRecruitedDate] = useState("");
  const [citizenship, setCitizenship] = useState(null);
  const [academic, setAcademic] = useState(null);
  const [academicFileName, setAcademicFileName] = useState("");
  const [citizenshipFileName, setCitizenshipFileName] = useState("");
  const [staffAccess, setStaffAccess] = useState(false);
  function handleCitizenshipChange(e) {
    const file = e.target.files[0];
    setCitizenship(file);
    if (file) {
      setCitizenshipFileName(file.name);
    } else {
      setCitizenshipFileName("");
    }
  }
  function handleAcademicChange(e) {
    const file = e.target.files[0];
    setAcademic(file);
    if (file) {
      setAcademicFileName(file.name);
    } else {
      setAcademicFileName("");
    }
  }

  function handleAddStaff(e) {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      name: fullName,
      email: email,
      address: address,
      contact: contact,
      responsibility: responsibility,
      date_of_birth: dateOfBirth,
      recruited_date: recruitedDate,
      tenure: tenure,
      salary: sarlary,
      qualification: qualification,
      pan_no: panNumber,
      account_access: staffAccess,
    };
    formData.append("documents", citizenship);
    formData.append("documents", academic);
    formData.append("staff", JSON.stringify(data));

    dispatch(addStaff(formData)).then((action) => {
      if (addStaff.fulfilled.match(action)) {
        setFullName("");
        setEmail("");
        setResponsibility("");
        setGender("");
        setContact("");
        setAddress("");
        setPanNumber("");
        setSalary("");
        setQualification("");
        setTenure("");
        setDateOfBirth("");
        setRecruitedDate("");
        setCitizenship(null);
        setAcademic(null);
        setAcademicFileName("");
        setCitizenshipFileName("");
        setStaffAccess(false);
        setAddStaffDialog(false);
        dispatch(fetchStaffList());
      }
    });
  }

  return (
    <Box>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            color: "black",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
          variant="body1"
        >
          Add Staff
        </Typography>
      </Box>
      <hr />
      <Box sx={{ m: "2rem" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                size="small"
                autoComplete="off"
                fullWidth
                required
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="email"
                id="outlined-basic"
                label="Email"
                variant="outlined"
                size="small"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">
                  Responsiblity
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Responsibility"
                  defaultValue=""
                  required
                  value={responsibility}
                  onChange={(e) => setResponsibility(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Technical Department"}>
                    Technical Department
                  </MenuItem>
                  <MenuItem value="Crm">CRM</MenuItem>
                  <MenuItem value="Cinematographer">Cinematographer</MenuItem>
                  <MenuItem value="Manager">Manager</MenuItem>
                  <MenuItem value="Receiptionist">Receiptionist</MenuItem>
                  <MenuItem value="Marketing">Marketing</MenuItem>
                  <MenuItem value="Property Dealer">Property Dealer</MenuItem>
                  <MenuItem value="Host">Host</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                <InputLabel id="demo-select-small-label">Gender</InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label="Gender"
                  defaultValue=""
                  required
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={"Male"}>Male</MenuItem>
                  <MenuItem value={"Female"}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label="Contact"
                variant="outlined"
                size="small"
                fullWidth
                required
                value={contact}
                onChange={(e) => setContact(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Address"
                variant="outlined"
                size="small"
                fullWidth
                required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Pan No"
                variant="outlined"
                size="small"
                fullWidth
                value={panNumber}
                onChange={(e) => setPanNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="number"
                label="Salary"
                variant="outlined"
                size="small"
                fullWidth
                value={sarlary}
                onChange={(e) => setSalary(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Qualification"
                variant="outlined"
                size="small"
                fullWidth
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
              />
            </Grid>{" "}
            <Grid item xs={6}>
              <TextField
                type="number"
                label="Tenure"
                variant="outlined"
                size="small"
                fullWidth
                value={tenure}
                onChange={(e) => setTenure(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="D.O.B"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                type="date"
                label="Recruited Date"
                variant="outlined"
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                value={recruitedDate}
                onChange={(e) => setRecruitedDate(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={staffAccess}
                    onChange={(e) => setStaffAccess(e.target.checked)}
                    color="primary" // Change the color if needed
                  />
                }
                label="Give Staff Access"
              />
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                component="label"
                sx={{ textTransform: "capitalize" }}
              >
                <Typography variant="body2">Upload Citizenship</Typography>
                <input
                  type="file"
                  hidden
                  required
                  onChange={handleCitizenshipChange}
                />
              </Button>
              <Typography sx={{ marginLeft: "0.8rem" }}>
                {citizenshipFileName}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                component="label"
                sx={{ textTransform: "capitalize" }}
              >
                <Typography variant="body2">Academic Document</Typography>
                <input
                  type="file"
                  hidden
                  required
                  onChange={handleAcademicChange}
                />
              </Button>
              <Typography sx={{ marginLeft: "0.8rem" }}>
                {academicFileName}
              </Typography>
            </Grid>
          </Grid>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", mt: "1.5rem" }}
          >
            <Button
              type="submit"
              sx={{
                backgroundColor: "black",
                color: "white",
                ":hover": {
                  backgroundColor: "#333333",
                },
              }}
              onClick={handleAddStaff}
            >
              Add Staff
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default AddStaff;
