import React from "react";
import BackgroundVideo from "./background_video";
import VideoCarousel from "./video_carousel";
import TestimonialCarousel from "./testimonial_carousel";

function AdminDashboardAds() {
  return (
    <div>
      <BackgroundVideo />
      <hr />
      <hr />
      <hr />
      <VideoCarousel />
      <hr />
      <hr />
      <hr />
      <TestimonialCarousel />
    </div>
  );
}

export default AdminDashboardAds;
