import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Typography,
  styled,
  Box,
  IconButton,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Ceo from "../../assets/images/Ceo.png";
import SendIcon from "@mui/icons-material/Send";
// import { socket } from "../../config/socket";
import useSocket from "../../hooks/useSocket";
import CollectionsIcon from "@mui/icons-material/Collections";
import CloseIcon from "@mui/icons-material/Close";
import RenderImage from "./render_image";

import { fetchPreviousClientMessagesByIdAsync } from "../../redux/chatSlice";
import ChatMessage from "./message";
const ChatBody = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 2rem;
  height: 100%;
`;

function ClientChatContainer({ activeUserIdName, activeUserId }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [currentChatMessages, setCurrentChatMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const messageContainerRef = useRef(null);
  const previousClientMessages = useSelector(
    (state) => state.chat.previousClientMessages
  );
  const previousClientMessagesLoading = useSelector(
    (state) => state.chat.previousClientMessagesLoading
  );
  const previousClientMessagesError = useSelector(
    (state) => state.chat.previousClientMessagesError
  );
  const socket = useSocket();
  useEffect(() => {
    if (activeUserId) {
      dispatch(fetchPreviousClientMessagesByIdAsync(activeUserId));
    }
  }, [dispatch, activeUserId]);

  //Listen for incoming messages
  useEffect(() => {
    if (socket) {
      socket.on("message", (data) => {
        setCurrentChatMessages((currentChatMessages) => [
          ...currentChatMessages,
          data,
        ]);
      });
      return () => {
        socket.off("message");
      };
    }
  }, [socket]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [currentChatMessages, previousClientMessages]);
  const sendMessage = (e) => {
    e.preventDefault();
    if (selectedFile) {
      socket.emit("message", {
        image: selectedFile,
        receiver_id: activeUserId,
      });
      setSelectedFile(null);
    } else {
      if (message.trim() === "") return;
      socket.emit("message", { message: message, receiver_id: activeUserId });
      setMessage("");
    }
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageBase64 = e.target.result;
        setSelectedFile(imageBase64);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage(e);
    }
  };
  const renderPreviousChatMessages = useMemo(() => {
    return previousClientMessages?.map((prevMessage) => {
      if (prevMessage.message) {
        return <ChatMessage key={prevMessage.id} message={prevMessage} />;
      } else if (prevMessage.imageURL) {
        return (
          <RenderImage
            key={prevMessage.id}
            message={prevMessage}
            setOpenImageDialog={setOpenImageDialog}
            setImageUrl={setImageUrl}
          />
        );
      } else return null;
    });
  }, [previousClientMessages]);

  const renderCurrentChatMessages = () => {
    return currentChatMessages?.map((prevMessage, index) => {
      if (prevMessage.message) {
        return <ChatMessage key={index} message={prevMessage} />;
      } else if (prevMessage.imageURL) {
        return (
          <RenderImage
            key={index}
            message={prevMessage}
            setOpenImageDialog={setOpenImageDialog}
            setImageUrl={setImageUrl}
          />
        );
      } else return null;
    });
  };
  return (
    <Box sx={{ flex: "3" }}>
      <ChatBody>
        {activeUserId ? (
          previousClientMessagesLoading ? (
            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
              <Typography variant="body2">Loading...</Typography>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  marginBottom: "1rem",
                  alignItems: "center",
                  gap: "0.5rem",
                  backgroundColor: "rgba(249, 171, 171,0.1)",
                  padding: "1rem",
                }}
              >
                <Avatar sx={{ width: 40, height: 40 }} src={Ceo} />
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {activeUserIdName}
                </Typography>
              </Box>
              {previousClientMessagesError ? (
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{ color: "rgb(200,0,0)", mt: "2rem" }}
                  >
                    {previousClientMessagesError}
                  </Typography>{" "}
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      height: "100%",
                      padding: "0.8rem 0.8rem",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      display: "flex",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                    ref={messageContainerRef}
                  >
                    {renderPreviousChatMessages}
                    {renderCurrentChatMessages()}
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "4rem",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: "0px -4px 5px 0px rgba(0,0,0,0.1)",
                    }}
                  >
                    {" "}
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                      <label htmlFor="fileInput">
                        <IconButton
                          component="span"
                          sx={{
                            color: "white",
                            backgroundColor: "inherit",
                            transition: "all 0.2s ease-in-out",
                            ":hover": {
                              transform: "scale(1.1)",
                            },
                          }}
                        >
                          <CollectionsIcon sx={{ color: "rgb(9, 163, 219)" }} />
                        </IconButton>
                      </label>
                    </div>
                    {selectedFile ? (
                      <Box
                        sx={{
                          width: "5rem",
                          height: "100%",
                          mr: "auto",
                          position: "relative",
                        }}
                      >
                        <img
                          src={selectedFile}
                          alt="selected file"
                          style={{ width: "100%", height: "100%" }}
                        />
                        <IconButton
                          onClick={() => setSelectedFile(null)}
                          sx={{
                            position: "absolute",
                            right: "-2px",
                            top: "-2px",
                            padding: "0",
                            background: "grey",
                            transition: "all 0.2s ease-in-out",
                            "&:hover": {
                              background: "black",
                            },
                          }}
                        >
                          <CloseIcon
                            sx={{
                              width: "0.9rem",
                              height: "0.9rem",
                              fill: "white",
                            }}
                          />
                        </IconButton>
                      </Box>
                    ) : (
                      <input
                        type="text"
                        placeholder="Write a message"
                        style={{
                          width: "100%",
                          height: "100%",
                          paddingLeft: "1rem",
                          fontSize: "14px",
                          border: "none",
                        }}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                    )}
                    <IconButton
                      sx={{
                        color: "white",
                        backgroundColor: "inherit",
                        transition: "all 0.2s ease-in-out",
                        ":hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                      type="submit"
                      onClick={(e) => sendMessage(e)}
                    >
                      <SendIcon sx={{ color: "rgb(9, 163, 219)" }} />
                    </IconButton>
                  </Box>
                </>
              )}
            </>
          )
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              Select a user to start chat
            </Typography>
          </Box>
        )}
      </ChatBody>

      <Dialog
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogActions>
          <IconButton onClick={() => setOpenImageDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent sx={{ padding: "0.2rem", height: "100%" }}>
          <img
            src={imageUrl}
            alt="could not load"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "cover" }}
            loading="lazy"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ClientChatContainer;
