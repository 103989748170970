import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";

function SocialMediaDetails({ SocialMediaDetails }) {
  let socialMedia = {};

  if (typeof SocialMediaDetails === "string") {
    socialMedia = SocialMediaDetails ? JSON.parse(SocialMediaDetails) : {};
  } else {
    socialMedia = SocialMediaDetails ? SocialMediaDetails : {};
  }

  return (
    <>
      {socialMedia?.twitter && (
        <Link to={socialMedia?.twitter || ""} target="_blank">
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Twitter : </strong>
            {socialMedia.twitter?.slice(0, 30)}
          </Typography>
        </Link>
      )}
      {socialMedia?.facebook && (
        <Link to={socialMedia?.facebook || ""} target="_blank">
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Facebook : </strong>
            {socialMedia.facebook?.slice(0, 30)}
          </Typography>
        </Link>
      )}
      {socialMedia?.instagram && (
        <Link to={socialMedia?.instagram || ""} target="_blank">
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Instagram : </strong>
            {socialMedia.instagram?.slice(0, 30)}
          </Typography>
        </Link>
      )}
      {socialMedia?.youtube && (
        <Link to={socialMedia?.youtube || ""} target="_blank">
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Youtube : </strong>
            {socialMedia.youtube?.slice(0, 30)}
          </Typography>
        </Link>
      )}
    </>
  );
}

export default SocialMediaDetails;
