import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import {
  deleteCustomer,
  deleteBuyer,
  deleteSeller,
} from "../../../redux/overviewSlice";
function DeleteCustomerPopup({ item, selectedId, setOpenDeleteDialog }) {
  const dispatch = useDispatch();
  const handleDeleteProperty = () => {
    if (item === "customer") {
      dispatch(deleteCustomer({ property_id: selectedId })).then(() => {
        setOpenDeleteDialog(false);
      });
    } else if (item === "buyer") {
      dispatch(deleteBuyer({ property_id: selectedId })).then(() => {
        setOpenDeleteDialog(false);
      });
    } else if (item === "seller") {
      dispatch(deleteSeller({ property_id: selectedId })).then(() => {
        setOpenDeleteDialog(false);
      });
    }
  };

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Do you want to delete property? {selectedId} {item}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleDeleteProperty}
        >
          Yes
        </Button>
        <Button
          sx={{
            backgroundColor: "rgb(44, 70, 242)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(42, 65, 219)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={() => setOpenDeleteDialog(false)}
        >
          No
        </Button>
      </Box>
    </Box>
  );
}

export default DeleteCustomerPopup;
