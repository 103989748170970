import { Container } from "@mui/material";
// import FooterBar from "../footer_bar/footer_bar";
// import AgentDashboardMainSection from "./agent_dashboard_main_section.test";
import Dashboard from "./admin_dashboard";
import "./admin_dashboard.styles.css";
function AdminDashboard() {
  return (
    <Container maxWidth="false" disableGutters>
      <Dashboard />
    </Container>
  );
}

export default AdminDashboard;
