import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  staffList: [],
  staffDetails: null,
  staffDetailLoading: false,
  staffDetailError: null,
  isLoading: false,
  staffListLoading: false,
  error: null,
};

export const fetchStaffList = createAsyncThunk(
  "staffMgmt/fetchStaffList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/staff`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchStaffDetailById = createAsyncThunk(
  "staffMgmt/fetchStaffDetailById",
  async ({ staff_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/staff/${staff_id}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const addStaff = createAsyncThunk(
  "staffMgmt/addStaff",
  async (staffDetails, { rejectWithValue }) => {
    staffDetails.forEach((value, key) => {});
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/staff/register`,
        staffDetails,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Staff Added Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return staffDetails;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteStaff = createAsyncThunk(
  "staffMgmt/deleteStaff",
  async ({ staff_id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/staff/${staff_id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return staff_id;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const giveAdminAccessToStaff = createAsyncThunk(
  "staffMgmt/giveAdminAccessToStaff",
  async ({ staffId }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/staff/account/${staffId}`,
        {},
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Staff Edited Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return "Success";
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const takeAdminAccessToStaff = createAsyncThunk(
  "staffMgmt/takeAdminAccessToStaff",
  async ({ staffId }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/staff/account/${staffId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Staff Edited Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return "Success";
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const editStaff = createAsyncThunk(
  "staffMgmt/editStaff",
  async ({ formData, staffId }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/staff/${staffId}`,
        formData,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Staff Edited Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return "Success";
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const searchStaff = createAsyncThunk(
  "staffMgmt/searchStaff",
  async ({ staffSearchQuery }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/staff?search=${staffSearchQuery}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const staffMgmtSlice = createSlice({
  name: "staffMgmt",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchStaffList.pending, (state) => {
        state.staffListLoading = true;
      })
      .addCase(fetchStaffList.fulfilled, (state, action) => {
        state.staffListLoading = false;
        state.staffList = action.payload;
      })
      .addCase(fetchStaffList.rejected, (state, action) => {
        state.staffListLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchStaffDetailById.pending, (state) => {
        state.staffDetailLoading = true;
      })
      .addCase(fetchStaffDetailById.fulfilled, (state, action) => {
        state.staffDetailLoading = false;
        state.staffDetails = action.payload;
      })
      .addCase(fetchStaffDetailById.rejected, (state, action) => {
        state.staffDetailLoading = false;
        state.staffDetailError = action.payload;
      })
      .addCase(addStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addStaff.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(addStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        //delete staff from staffList
        state.staffList = state.staffList.filter(
          (staff) => staff.admin_id !== action.payload
        );
      })
      .addCase(deleteStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(giveAdminAccessToStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(giveAdminAccessToStaff.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(giveAdminAccessToStaff.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(takeAdminAccessToStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(takeAdminAccessToStaff.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(takeAdminAccessToStaff.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editStaff.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(editStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(searchStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchStaff.fulfilled, (state, action) => {
        state.isLoading = false;
        state.staffList = action.payload;
      })
      .addCase(searchStaff.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { addNewDataToStaffList } = staffMgmtSlice.actions;
export default staffMgmtSlice.reducer;
