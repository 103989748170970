import React from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogContent,
  Box,
  Tooltip,
  Toolbar,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Popover,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";

import HandshakeIcon from "@mui/icons-material/Handshake";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import SearchIcon from "@mui/icons-material/Search";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";

import EditPropertyPopup from "../../edit_property/edit_property_popup";
import EditPropertyModal from "../../edit_property/edit_property_modal";
import ChangeAdsPopup from "./change_ads_popup";
import Comment from "./property_comment";
import ChangeListingPopup from "./change_listing_popup";
import PropertyInfoHome from "./property_details_home";
import SoldPropertiesPopup from "./sold_properties_popup";
import PropertyInfoApartment from "./property_details_apartment";
import PropertyDetailsLand from "./property_details_land";
import DeletePopup from "src/component/popup/delete_popup";
import {
  addSoldPropertyByIdAsync,
  deleteRecentProperty,
  editListingType,
  editPropertyAds,
  fetchRecentProperty,
  fetchSoldPropertyList,
} from "src/redux/overviewSlice";

// ----------------------------------------------------------------------

export default function RecentTableToolbar({
  selectedId,
  setSelectedId,
  searchQuery,
  setSearchQuery,
  searchPropertyListedFor,
  setSearchPropertyListedFor,
  searchPropertyType,
  setSearchProperType,
  selectedType,
}) {
  const dispatch = useDispatch();

  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openListingDialog, setOpenListingDialog] = React.useState(false);
  const [openAdsDialog, setOpenAdsDialog] = React.useState(false);
  const [openSoldDialog, setOpenSoldDialog] = React.useState(false);
  const [commentAnchorEl, setCommentAnchorEl] = React.useState(null);
  const [propertyDetailsAnchorEl, setPropertyDetailsAnchorEl] =
    React.useState(null);

  const openComment = Boolean(commentAnchorEl);
  const commentId = openComment ? "simple-popover" : undefined;

  const openPropertyDetails = Boolean(propertyDetailsAnchorEl);
  const propertyDetailsId = openPropertyDetails ? "simple-popover" : undefined;

  const handleDeleteRecentProperty = () => {
    dispatch(
      deleteRecentProperty({
        propertyId: selectedId,
        propertyType: selectedType,
      })
    ).then((res) => {
      if (deleteRecentProperty.fulfilled.match(res)) {
        setOpenDeleteDialog(false);
        setSelectedId(null);
      }
    });
  };

  const handleChangeListing = (listingType) => {
    if (!listingType) return;
    dispatch(
      editListingType({
        selectedListing: listingType,
        propertyType: selectedType,
        propertyId: selectedId,
      })
    ).then((res) => {
      if (editListingType.fulfilled.match(res)) {
        dispatch(fetchRecentProperty());
        setOpenListingDialog(false);
        setSelectedId(null);
      }
    });
  };

  const handlePropertyAds = (platform, adsStatus) => {
    if (!platform || !adsStatus) return;

    const data = { platform: platform, ads_status: adsStatus };
    dispatch(
      editPropertyAds({
        data: data,
        propertyType: selectedType,
        propertyId: selectedId,
      })
    ).then((res) => {
      if (editPropertyAds.fulfilled.match(res)) {
        dispatch(fetchRecentProperty());
        setOpenAdsDialog(false);
        setSelectedId(null);
      }
    });
  };

  const handlePropertySold = () => {
    dispatch(
      addSoldPropertyByIdAsync({
        propertyId: selectedId,
        propertyType: selectedType,
      })
    ).then((res) => {
      if (addSoldPropertyByIdAsync.fulfilled.match(res)) {
        dispatch(fetchRecentProperty());
        setSelectedId(null);
        setOpenSoldDialog(false);
        dispatch(fetchSoldPropertyList());
      }
    });
  };
  return (
    <Box>
      <Toolbar
        sx={{
          height: 96,
          display: "flex",
          justifyContent: "space-between",
          p: (theme) => theme.spacing(0, 1, 0, 3),
          ...(selectedId && {
            color: "primary.main",
            bgcolor: "primary.lighter",
          }),
        }}
      >
        {selectedId ? (
          <Typography component="div" variant="subtitle1">
            {selectedId} selected
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            Recent Properties
          </Typography>
        )}

        {selectedId ? (
          <Box>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setOpenDeleteDialog(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setOpenEditDialog(true);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Change Listing">
              <IconButton
                onClick={() => {
                  setOpenListingDialog(true);
                }}
              >
                <FeaturedPlayListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Change Ads Status">
              <IconButton
                onClick={() => {
                  setOpenAdsDialog(true);
                }}
              >
                <AdsClickIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Already Sold">
              <IconButton
                onClick={() => {
                  setOpenSoldDialog(true);
                }}
              >
                <HandshakeIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Property Details">
              <IconButton
                onClick={(e) => setPropertyDetailsAnchorEl(e.currentTarget)}
              >
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Comment">
              <IconButton onClick={(e) => setCommentAnchorEl(e.currentTarget)}>
                <MarkUnreadChatAltIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
              <InputLabel id="demo-select-small-label">
                <Typography sx={{ fontSize: "0.9rem", color: "#666666" }}>
                  Property Type
                </Typography>{" "}
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={searchPropertyType}
                label="Property Details"
                onChange={(e) => setSearchProperType(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="house">House</MenuItem>
                <MenuItem value="land">Land</MenuItem>
                <MenuItem value="apartment">Apartment</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
              <InputLabel id="demo-select-small-label">
                <Typography sx={{ fontSize: "0.9rem", color: "#666666" }}>
                  Listed For
                </Typography>
              </InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={searchPropertyListedFor}
                label="Property Status"
                onChange={(e) => setSearchPropertyListedFor(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="sale">On Sale</MenuItem>
                <MenuItem value="rent">On Rent</MenuItem>
              </Select>
            </FormControl>
            <OutlinedInput
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              placeholder="Search..."
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </Box>
        )}
      </Toolbar>
      <EditPropertyPopup
        openEditDialog={openEditDialog}
        setOpenEditDialog={setOpenEditDialog}
      >
        <EditPropertyModal
          setOpenEditDialog={setOpenEditDialog}
          selectedType={selectedType}
          selectedId={selectedId}
        />
      </EditPropertyPopup>

      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeletePopup
            handleDelete={handleDeleteRecentProperty}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openListingDialog}
        onClose={() => {
          setOpenListingDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <ChangeListingPopup
            selectedId={selectedId}
            handleChangeListing={handleChangeListing}
            setOpenListingDialog={setOpenListingDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openAdsDialog}
        onClose={() => {
          setOpenAdsDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <ChangeAdsPopup
            setOpenAdsDialog={setOpenAdsDialog}
            handlePropertyAds={handlePropertyAds}
          />

          {/* <ChangeListingPopup
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            setOpenListingDialog={setOpenListingDialog}
            selectedType={selectedType}
          /> */}
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSoldDialog}
        onClose={() => {
          setOpenSoldDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <SoldPropertiesPopup
            selectedId={selectedId}
            setOpenSoldDialog={setOpenSoldDialog}
            handlePropertySold={handlePropertySold}
          />
        </DialogContent>
      </Dialog>

      <Popover
        id={commentId}
        open={openComment}
        anchorEl={commentAnchorEl}
        onClose={() => {
          setCommentAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Comment selectedId={selectedId} selectedType={selectedType} />
      </Popover>
      <Popover
        id={propertyDetailsId}
        open={openPropertyDetails}
        anchorEl={propertyDetailsAnchorEl}
        onClose={() => {
          setPropertyDetailsAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {selectedType === "house" && (
          <PropertyInfoHome selectedId={selectedId} from="recent" />
        )}
        {selectedType === "apartment" && (
          <PropertyInfoApartment selectedId={selectedId} from="recent" />
        )}
        {selectedType === "land" && (
          <PropertyDetailsLand selectedId={selectedId} from="recent" />
        )}
      </Popover>
    </Box>
  );
}
