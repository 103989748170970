import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  Stack,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "@mui/icons-material/Close";
import { forgetPasswordAsync } from "../../redux/authSlice";
import ChangePassword from "./changePassword_popup";
function ForgetPassword({ setOpenForgetPasswordDialog }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [openChangePasswordPopup, setOpenChangePasswordPopup] = useState(false);
  const [choosenRole, setChoosenRole] = useState("");
  const forgetPasswordLoading = useSelector(
    (state) => state.auth.forgetPasswordLoading
  );
  function handleSubmit(e) {
    e.preventDefault();
    if (!email.trim() || !choosenRole.trim()) return;
    dispatch(forgetPasswordAsync({ email, choosenRole })).then((action) => {
      if (forgetPasswordAsync.fulfilled.match(action)) {
        setOpenChangePasswordPopup(true);
      }
    });
  }
  const handleChangePasswordClose = (e, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    setOpenChangePasswordPopup(false);
  };
  return (
    <Box>
      <Typography variant="body1">Forget Password?</Typography>
      <Box
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={2}
          >
            <TextField
              fullWidth
              label="Email id"
              id="fullWidth"
              type="email"
              placeholder="Your Current Email Id"
              required
              size="small"
              sx={{ boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset" }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl size="small" fullWidth>
              <InputLabel>Choose Role</InputLabel>

              <Select
                labelId="demo-select-small-label"
                value={choosenRole}
                required
                label="Choose Role"
                onChange={(e) => setChoosenRole(e.target.value)}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="staff">Staff</MenuItem>
              </Select>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "rgb(37, 77, 110)" }}
            >
              {forgetPasswordLoading ? "Loading..." : "Submit"}
            </Button>
          </Box>
        </form>
        <Stack
          direction={"row"}
          gap={0.2}
          alignItems={"center"}
          sx={{ marginTop: "1rem", cursor: "pointer" }}
          onClick={() => setOpenForgetPasswordDialog(false)}
        >
          <KeyboardBackspaceIcon sx={{ color: "#337AB7" }} />
          <Typography variant="body2" sx={{ color: "#337AB7" }}>
            Back to Login
          </Typography>
        </Stack>
      </Box>
      <Dialog
        open={openChangePasswordPopup}
        onClose={handleChangePasswordClose}
      >
        <DialogActions>
          <Button onClick={handleChangePasswordClose}>
            <CloseIcon />
          </Button>
        </DialogActions>

        <DialogContent sx={{ p: "1rem 2rem" }}>
          <ChangePassword
            setOpenChangePasswordPopup={setOpenChangePasswordPopup}
            setOpenForgetPassword={setOpenForgetPasswordDialog}
            email={email}
            choosenRole={choosenRole}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ForgetPassword;
