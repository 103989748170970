import React from "react";
import ClientChat from "./client_chat";
import GroupChat from "./group_chat";
import StaffChat from "./staff_chat";
function AdminDashboardChat({ selectedNavItem }) {
  return (
    <>
      {selectedNavItem === "Client" && <ClientChat />}
      {selectedNavItem === "Groups" && <GroupChat />}
      {selectedNavItem === "Staff" && <StaffChat />}
    </>
  );
}

export default AdminDashboardChat;
