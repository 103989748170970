import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  isContactListLoading: false,
  contactList: [],
  contactListError: "",
  isContactDeleteLoading: false,
};
export const fetchContactListAsync = createAsyncThunk(
  "ads/fetchContactListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/contact`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const deleteContactByIdAsync = createAsyncThunk(
  "ads/deleteContactByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`${BASE_URL}/admin/contact/${id}`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return id;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactListAsync.pending, (state) => {
        state.isContactListLoading = true;
      })
      .addCase(fetchContactListAsync.fulfilled, (state, action) => {
        state.isContactListLoading = false;
        state.contactList = action.payload;
      })
      .addCase(fetchContactListAsync.rejected, (state, action) => {
        state.isContactListLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(deleteContactByIdAsync.pending, (state) => {
        state.isContactDeleteLoading = true;
      })
      .addCase(deleteContactByIdAsync.fulfilled, (state, action) => {
        state.isContactDeleteLoading = false;
        state.contactList = state.contactList.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteContactByIdAsync.rejected, (state, action) => {
        state.isContactDeleteLoading = false;
        state.contactListError = action.payload;
      });
  },
});

export default contactSlice.reducer;
