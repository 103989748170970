import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TableRow,
  Typography,
  TablePagination,
  InputAdornment,
  Dialog,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import ThreePIcon from "@mui/icons-material/ThreeP";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { getComparator, stableSort } from "../../utils/dashboard_table";

import DeleteFieldVisitPopup from "./delete_field_visit_request_popup";
import SchduleDatePopup from "./schedule_date_popup";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AddFieldVisitRequest from "./add_field_visit_request";
import { fetchFieldVisitRequestAsync } from "../../redux/fieldVisitRequestSlice";

import EnhancedTableHead from "../table/EnhancedTableHead";

const headCells = [
  {
    id: "fr_id",

    disablePadding: true,
    label: "F.R.ID",
    sort: true,
  },
  {
    id: "full_name",
    disablePadding: false,
    label: "Full Name",
    sort: true,
  },
  {
    id: "requestedOn",

    disablePadding: false,
    label: "Request On",
    sort: true,
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    sort: true,
  },
  {
    id: "p_id",
    disablePadding: false,
    label: "P.ID",
    sort: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sort: true,
  },
];
function createData(fr_id, full_name, requestedOn, email, p_id, status) {
  return {
    fr_id,
    full_name,
    requestedOn,
    email,
    p_id,
    status,
  };
}

export default function FieldVisitRequestList({
  selectedId,
  setSelectedId,
  setActionSelected,
}) {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openVerifyDialog, setOpenVerifyDialog] = React.useState(false);
  const [addFieldVisitRequestDialog, setAddFieldVisitRequestDialog] =
    React.useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("fr_id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchQuery, setSearchQuery] = React.useState("");
  const fieldVisitRequestList = useSelector(
    (state) => state.fieldVisitRequest.fieldVisitRequestList
  );
  const fieldVisitRequestListLoading = useSelector(
    (state) => state.fieldVisitRequest.fieldVisitRequestListLoading
  );

  useEffect(() => {
    dispatch(fetchFieldVisitRequestAsync());
  }, [dispatch]);

  const rows = useMemo(() => {
    return fieldVisitRequestList?.map((request) => {
      return (
        createData(
          request.field_visit_id,
          request.user?.name,
          request.request_date,
          request.user?.email,
          request.property_id,
          request.status
        ) || []
      );
    });
  }, [fieldVisitRequestList]);

  const handleSearch = () => {
    //todo
    // dispatch(
    //   searchProperty({
    //     requestedOn: searchQuery,
    //     property_type: propertyType,
    //     listed_for: propertyStatus,
    //   })
    // );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (fr_id) => {
    if (selectedId === null) {
      setSelectedId(fr_id);
    } else {
      setActionSelected("fieldVisitGraph");
      setSelectedId(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {fieldVisitRequestListLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    gap: "1.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    id="Field Visit Request List"
                  >
                    Field Visit Request List
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddIcon />}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => setAddFieldVisitRequestDialog(true)}
                  >
                    Add Field Visit
                  </Button>
                </Box>
                {selectedId !== null ? (
                  <>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Schdule Date">
                      <IconButton
                        onClick={() => {
                          setOpenVerifyDialog(true);
                        }}
                      >
                        <DateRangeIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View Comments">
                      <IconButton
                        onClick={() => setActionSelected("requestComments")}
                      >
                        <ThreePIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Request Details">
                      <IconButton
                        onClick={() => setActionSelected("requestDetails")}
                      >
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Box display={selectedId ? "none" : "block"}>
                      <TextField
                        sx={{ ml: "auto", mr: 1 }}
                        id="outlined-basic"
                        label="Search"
                        type="text"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        // inputRef={(input) => input && input.focus()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon onClick={() => handleSearch()} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </>
                )}
              </Toolbar>
            </Box>
            {fieldVisitRequestList?.length >= 1 ? (
              <>
                <TableContainer
                  sx={{ background: "rgba(246, 237, 237, 0.60)" }}
                >
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows?.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows?.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={() => handleClick(row.fr_id)}
                            role="checkbox"
                            aria-checked={selectedId === row.fr_id}
                            tabIndex={-1}
                            key={row.fr_id}
                            selected={selectedId === row.fr_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={selectedId === row.fr_id}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">{row?.full_name}</TableCell>
                            <TableCell align="left">
                              {row?.requestedOn?.split("T")[0]}
                            </TableCell>
                            <TableCell align="left">{row?.email}</TableCell>
                            <TableCell align="left">{row?.p_id}</TableCell>
                            <TableCell align="left">{row?.status}</TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>No List</h1>
              </Box>
            )}
          </>
        )}
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteFieldVisitPopup
            selectedId={selectedId}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openVerifyDialog}
        onClose={() => {
          setOpenVerifyDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions
          sx={{
            padding: "0",
            background: "#F6EDED",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2" sx={{ ml: "1.5rem", fontWeight: "bold" }}>
            Reschedule Date ?
          </Typography>

          <IconButton onClick={() => setOpenVerifyDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            padding: "0px 24px 24px 24px",
            background: "#F6EDED",
          }}
        >
          <SchduleDatePopup
            selectedId={selectedId}
            setOpenVerifyDialog={setOpenVerifyDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={addFieldVisitRequestDialog}
        onClose={() => setAddFieldVisitRequestDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            padding: "9px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AddFieldVisitRequest
            setAddFieldVisitRequestDialog={setAddFieldVisitRequestDialog}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
