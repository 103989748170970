import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../config/base_url";

const initialState = {
  serviceListLoading: false,
  serviceListError: null,
  serviceDetail: null,
  serviceDetailLoading: false,
  serviceDetailError: null,
  isLoading: false,
  error: null,
  servicesList: [
    {
      id: 1,
      name: "Ajay Yadav",
      email: "ajayadav@gmail.com",
      phone_number: "9822549988",
      gender: "male",
      age: 29,
      service_type: "house keeping",
      province: "Bagmati",
      district: "Lalitpur",
      municipality: "Lalitpur",
      ward_number: 2,
      profileImage:
        "https://res.cloudinary.com/duxcggyri/image/upload/v1704001931/service/qcaeqga2mg73uqsiohwo.jpg",
      status: "pending",
      createdAt: "2023-12-31T05:52:11.000Z",
      updatedAt: "2023-12-31T05:52:11.000Z",
    },
    {
      id: 2,
      name: "Adarsha Wagle",
      email: "adarshawagle@gmail.com",
      phone_number: "9822549989",
      gender: "male",
      age: 29,
      service_type: "house keeping",
      province: "Bagmati",
      district: "Lalitpur",
      municipality: "Lalitpur",
      ward_number: 2,
      profileImage:
        "https://res.cloudinary.com/duxcggyri/image/upload/v1704002008/service/kaqfy4o46snzkeglsqxc.jpg",
      status: "pending",
      createdAt: "2023-12-31T05:53:28.000Z",
      updatedAt: "2023-12-31T05:53:28.000Z",
    },
  ],
};

export const fetchServiceListAsync = createAsyncThunk(
  "services/fetchServiceListAsync",
  async ({ searchQuery, page }, { rejectWithValue }) => {
    // let tempPage = page;
    try {
      // if (searchQuery) tempPage = 1;
      const response = await axios.get(
        `${BASE_URL}/admin/service/provider?search=${searchQuery}`,

        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchServiceDetailByIdAsync = createAsyncThunk(
  "services/fetchServiceDetailByIdAsync",
  async ({ provider_id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/service/provider/${provider_id}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const deleteServiceAsync = createAsyncThunk(
  "services/deleteServiceAsync",
  async ({ provider_id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/admin/service/provider/${provider_id}`,
        { withCredentials: true }
      );
      if (response.status === 200) {
        toast.success("Service deleted successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return provider_id;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const approveServiceAsync = createAsyncThunk(
  "services/approveServiceAsync",
  async ({ provider_id, status }, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${BASE_URL}/admin/service/provider/${provider_id}`,
        { status },

        { withCredentials: true }
      );
      if (response.status === 200) {
        toast.success(" Status updated successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return { provider_id, status };
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);
export const searchServiceAsync = createAsyncThunk(
  "services/searchServiceAsync",
  async ({ searchQuery = "" }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/service/provider?search=${searchQuery}`
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceListAsync.pending, (state) => {
        state.serviceListLoading = true;
      })
      .addCase(fetchServiceListAsync.fulfilled, (state, action) => {
        state.serviceListLoading = false;
        state.servicesList = action.payload;
      })
      .addCase(fetchServiceListAsync.rejected, (state, action) => {
        state.serviceListLoading = false;
        state.serviceListError = action.payload;
      })
      .addCase(fetchServiceDetailByIdAsync.pending, (state) => {
        state.serviceDetailLoading = true;
      })
      .addCase(fetchServiceDetailByIdAsync.fulfilled, (state, action) => {
        state.serviceDetailLoading = false;
        state.serviceDetail = action.payload;
      })
      .addCase(fetchServiceDetailByIdAsync.rejected, (state, action) => {
        state.serviceDetailLoading = false;
        state.serviceDetailError = action.payload;
      })
      .addCase(approveServiceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(approveServiceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        // const providerId = action.payload.provider_id;
        // const serviceStatus = action.payload.status;
        // state.servicesList = state.servicesList.map((service) => {
        //   if (service.provider_id === providerId) {
        //     service.status = serviceStatus;
        //   }
        //   return service;
        // });
      })
      .addCase(approveServiceAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteServiceAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteServiceAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        const providerId = action.payload;
        state.servicesList = state.servicesList.filter(
          (service) => service.id !== providerId
        );
      })
      .addCase(deleteServiceAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(searchServiceAsync.pending, (state) => {
        state.serviceListLoading = true;
      })
      .addCase(searchServiceAsync.fulfilled, (state, action) => {
        state.serviceListLoading = false;
        state.servicesList = action.payload;
      })
      .addCase(searchServiceAsync.rejected, (state, action) => {
        state.serviceListLoading = false;
        state.serviceListError = action.payload;
      });
  },
});
export default servicesSlice.reducer;
