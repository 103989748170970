import React from "react";
import { Typography } from "@mui/material";
import RequestMoreInfoList from "./request_more_info_list";

function RequestMoreInfoMain() {
  return (
    <div>
      <Typography variant="h4">Request More Info</Typography>
      <RequestMoreInfoList />
    </div>
  );
}

export default RequestMoreInfoMain;
