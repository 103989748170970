import React, { useState } from "react";
import { Paper, styled } from "@mui/material";
import StaffChatUserList from "./staff_chat_user_list";
import StaffChatContainer from "./staff_chat_container";

const ChatContainer = styled(Paper)`
  display: flex;
  height: 79vh;
  width: 100%;
  justify-content: space-between;
`;

const StaffChat = () => {
  const [activeUserIdName, setActiveUserIdName] = useState("");
  const [activeUserId, setActiveUserId] = useState(null);

  return (
    <ChatContainer>
      <StaffChatUserList
        setActiveUserId={setActiveUserId}
        activeUserId={activeUserId}
        setActiveUserIdName={setActiveUserIdName}
      />
      <StaffChatContainer
        activeUserIdName={activeUserIdName}
        activeUserId={activeUserId}
      />
    </ChatContainer>
  );
};

export default StaffChat;
