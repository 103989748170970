import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import {
  approveServiceAsync,
  fetchServiceListAsync,
} from "../../redux/servicesSlice";
function VerifyServicePopup({
  selectedId,
  selectedIdName,
  serviceStatus,
  setOpenVerifyDialog,
  setServiceStatus,
}) {
  const dispatch = useDispatch();
  const handleUnApproveStatus = () => {
    if (serviceStatus === "pending") {
      setOpenVerifyDialog(false);
      return;
    }

    dispatch(
      approveServiceAsync({ provider_id: selectedId, status: "pending" })
    ).then((action) => {
      if (approveServiceAsync.fulfilled.match(action)) {
        dispatch(fetchServiceListAsync({ searchQuery: "" }));
        setServiceStatus("pending");
        setOpenVerifyDialog(false);
      }
    });
  };
  const handleApproveStatus = () => {
    if (serviceStatus === "approved") {
      setOpenVerifyDialog(false);
      return;
    }

    dispatch(
      approveServiceAsync({ provider_id: selectedId, status: "approved" })
    ).then((action) => {
      if (approveServiceAsync.fulfilled.match(action)) {
        dispatch(fetchServiceListAsync({ searchQuery: "" }));
        setServiceStatus("approved");
        setOpenVerifyDialog(false);
      }
    });
  };

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Do you want to verify? {selectedIdName}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleApproveStatus}
        >
          Approve
        </Button>
        <Button
          sx={{
            backgroundColor: "rgb(44, 70, 242)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(42, 65, 219)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleUnApproveStatus}
        >
          UnApprove
        </Button>
      </Box>
    </Box>
  );
}

export default VerifyServicePopup;
