import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";

import WestIcon from "@mui/icons-material/West";
import AddIcon from "@mui/icons-material/Add";

import { addProperty, fetchRecentProperty } from "../../redux/overviewSlice";
import { splitLocation } from "../../utils/split_location";
import { resetLocation } from "../../redux/locationSlice";

import LandFirstLocationDetails from "./land_first_location_details";
import LandSecondOtherDetails from "./land_second_other_details";
import { convertToFeet } from "../../utils/converter";

const MAX_SECTION = 2;

function AddPropertyLand({ listedFor, setOpenAddPropertyPopup }) {
  const dispatch = useDispatch();
  const [activeSectionId, setActiveSectionId] = useState(1);

  const [propertyDetailsData, setPropertyDetailsData] = useState({});
  const [otherDetailsData, setOtherDetailsData] = useState({});

  const selectedProvince = useSelector(
    (state) => state.location.selectedProvince
  );
  const selectedDistrict = useSelector(
    (state) => state.location.selectedDistrict
  );
  const selectedMunicipality = useSelector(
    (state) => state.location.selectedMunicipality
  );
  const selectedWard = useSelector((state) => state.location.selectedWard);
  const selectedLandMark = useSelector(
    (state) => state.location.selectedLandMark
  );
  const selectedAreaName = useSelector(
    (state) => state.location.selectedAreaName
  );
  const isLoading = useSelector((state) => state.overview.isLoading);

  const selectedMap = useSelector((state) => state.location.selectedMap);

  function handleAddProperty(e) {
    e.preventDefault();

    const formData = new FormData();

    const { road_size_type, road_access, ...propertyDetails } =
      propertyDetailsData;
    const { gallery, ...rest } = otherDetailsData;

    for (let i = 0; i < gallery.length; i++) {
      formData.append("image", gallery[i]);
    }
    const selectMap = JSON.parse(selectedMap);
    const convertedRoadAccess = convertToFeet(road_access, road_size_type);
    const data = {
      ...propertyDetails,
      ...rest,
      road_access: convertedRoadAccess,
      listed_for: listedFor,
      province: splitLocation(selectedProvince),
      district: splitLocation(selectedDistrict),
      municipality: splitLocation(selectedMunicipality),
      ward: selectedWard,
      landmark: selectedLandMark,
      area_name: selectedAreaName,
      latitude: selectMap.lat,
      longitude: selectMap.lng,
    };
    formData.append("property", JSON.stringify(data));

    dispatch(addProperty({ formData: formData, endPoint: "land" })).then(
      (action) => {
        if (addProperty.fulfilled.match(action)) {
          dispatch(fetchRecentProperty());
          setOpenAddPropertyPopup(false);
          dispatch(resetLocation());
        }
      }
    );
  }

  function incrementActiveSectionId() {
    if (activeSectionId === MAX_SECTION) return;
    setActiveSectionId(activeSectionId + 1);
  }
  function decrementActiveSectionId() {
    if (activeSectionId === 1) return;
    setActiveSectionId(activeSectionId - 1);
  }

  return (
    <Box>
      {activeSectionId === 1 && (
        <LandFirstLocationDetails
          propertyDetailsData={propertyDetailsData}
          setPropertyDetailsData={setPropertyDetailsData}
          incrementActiveSectionId={incrementActiveSectionId}
        />
      )}
      {activeSectionId === 2 && (
        <LandSecondOtherDetails
          listedFor={listedFor}
          otherDetailsData={otherDetailsData}
          setOtherDetailsData={setOtherDetailsData}
          incrementActiveSectionId={incrementActiveSectionId}
        />
      )}

      <Box
        sx={{
          width: "100%",
          marginTop: "0.5rem",
          display: "flex",
          justifyContent: activeSectionId === 1 ? "flex-end" : "space-between ",
          alignItems: "center",
        }}
      >
        {activeSectionId !== 1 && (
          <Button
            variant="outlined"
            style={{
              border: "none",
              color: "black",
              fontFamily: "Roboto Serif",
              fontSize: "0.9rem",
              fontWeight: "700",
              textTransform: "none",
            }}
            startIcon={<WestIcon />}
            onClick={decrementActiveSectionId}
          >
            Back
          </Button>
        )}
        {activeSectionId === MAX_SECTION && (
          <Button
            variant="outlined"
            style={{
              borderRadius: "2rem",
              color: "white",
              fontFamily: "Roboto Serif",
              fontSize: "0.75rem",

              textTransform: "none",
              backgroundColor: "#000",
            }}
            endIcon={<AddIcon />}
            onClick={handleAddProperty}
            disabled={isLoading}
          >
            Add Property
          </Button>
        )}
      </Box>
    </Box>
  );
}
export default AddPropertyLand;
