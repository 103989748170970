import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
} from "@mui/material";

import {
  editStaff,
  fetchStaffDetailById,
  fetchStaffList,
} from "../../redux/staffMgmtSlice";
function StaffUpdate({ selectedId }) {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [responsibility, setResponsibility] = useState("");
  const [gender, setGender] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [salary, setSalary] = useState("");
  const [qualification, setQualification] = useState("");
  const [tenure, setTenure] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [recruitedDate, setRecruitedDate] = useState("");
  const [citizenship, setCitizenship] = useState(null);
  const [academic, setAcademic] = useState(null);
  const [academicFileName, setAcademicFileName] = useState("");
  const [citizenshipFileName, setCitizenshipFileName] = useState("");

  const staffDetails = useSelector((state) => state.staffMgmt.staffDetails);
  useEffect(() => {
    dispatch(fetchStaffDetailById({ staff_id: selectedId }));
  }, [dispatch, selectedId]);

  useEffect(() => {
    if (staffDetails) {
      setFullName(staffDetails?.name);
      setEmail(staffDetails?.email);
      setResponsibility(staffDetails?.responsibility);
      setGender(staffDetails?.gender);
      setContact(staffDetails?.contact);
      setAddress(staffDetails?.address);
      setPanNumber(staffDetails?.pan_no);
      setSalary(staffDetails?.salary);
      setQualification(staffDetails?.qualification);
      setTenure(staffDetails?.tenure);
      setDateOfBirth(staffDetails?.date_of_birth);
      setRecruitedDate(staffDetails?.recruited_date);
    }
  }, [staffDetails]);
  function handleCitizenshipChange(e) {
    const file = e.target.files[0];
    setCitizenship(file);
    if (file) {
      setCitizenshipFileName(file.name);
    } else {
      setCitizenshipFileName("");
    }
  }
  function handleAcademicChange(e) {
    const file = e.target.files[0];
    setAcademic(file);
    if (file) {
      setAcademicFileName(file.name);
    } else {
      setAcademicFileName("");
    }
  }
  function handleStaffEdit(e) {
    e.preventDefault();
    const formData = new FormData();
    const data = {
      name: fullName,
      email: email,
      address: address,
      contact: contact,
      gender: gender,
      responsibility: responsibility,
      date_of_birth: dateOfBirth,
      recruited_date: recruitedDate,
      tenure: tenure,
      salary: salary,
      qualification: qualification,
      pan_no: panNumber,
    };
    formData.append("documents", citizenship);
    formData.append("documents", academic);
    formData.append("staff", JSON.stringify(data));

    dispatch(editStaff({ formData: formData, staffId: selectedId })).then(
      (action) => {
        if (editStaff.fulfilled.match(action)) {
          setFullName("");
          setEmail("");
          setResponsibility("");
          setGender("");
          setContact("");
          setAddress("");
          setPanNumber("");
          setSalary("");
          setQualification("");
          setTenure("");
          setDateOfBirth("");
          setRecruitedDate("");
          setCitizenship(null);
          setAcademic(null);
          setAcademicFileName("");
          setCitizenshipFileName("");
          dispatch(fetchStaffList());
        }
      }
    );
  }
  return (
    <Box sx={{ backgroundColor: "#F6EDED" }}>
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        Update Profile
      </Typography>
      <hr />
      <Box sx={{ padding: "1.5rem" }}>
        <Typography>
          <strong>S_ID : </strong>
          {selectedId}
        </Typography>
        <Box sx={{ mt: "2rem" }}>
          <form onSubmit={handleStaffEdit}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4}>
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={fullName || ""}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                  <InputLabel id="demo-select-small-label">Gender</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Gender"
                    defaultValue=""
                    value={gender || ""}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Tenure"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={tenure || ""}
                  onChange={(e) => setTenure(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                  <InputLabel id="demo-select-small-label">
                    Responsiblity
                  </InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    label="Responsibility"
                    defaultValue=""
                    value={responsibility || ""}
                    onChange={(e) => setResponsibility(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"Technical Department"}>
                      Technical Department
                    </MenuItem>
                    <MenuItem value="Crm">CRM</MenuItem>
                    <MenuItem value="Cinematographer">Cinematographer</MenuItem>
                    <MenuItem value="Manager">Manager</MenuItem>
                    <MenuItem value="Receiptionist">Receiptionist</MenuItem>
                    <MenuItem value="Marketing">Marketing</MenuItem>
                    <MenuItem value="Property Dealer">Property Dealer</MenuItem>
                    <MenuItem value="Host">Host</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  type="email"
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={email || ""}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Contact"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={contact || ""}
                  onChange={(e) => setContact(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={address || ""}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  id="outlined-basic"
                  label="Pan No"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={panNumber || ""}
                  onChange={(e) => setPanNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  type="number"
                  id="outlined-basic"
                  label="Salary"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={salary || ""}
                  onChange={(e) => setSalary(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  id="outlined-basic"
                  label="Qualification"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={qualification || ""}
                  onChange={(e) => setQualification(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} md={4}>
                <TextField
                  type="date"
                  id="outlined-basic"
                  label="D.O.B"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={dateOfBirth || ""}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Typography variant="body2">Upload Citizenship</Typography>
                  <input
                    type="file"
                    hidden
                    onChange={handleCitizenshipChange}
                  />
                </Button>
                <Typography sx={{ marginLeft: "0.8rem" }}>
                  {citizenshipFileName}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ textTransform: "capitalize" }}
                >
                  <Typography variant="body2">Academic Document</Typography>
                  <input type="file" hidden onChange={handleAcademicChange} />
                </Button>
                <Typography sx={{ marginLeft: "0.8rem" }}>
                  {academicFileName}
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.4rem 1rem",
                  "&:hover": {
                    backgroundColor: "#212121",
                  },
                }}
              >
                Update
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
}

export default StaffUpdate;
