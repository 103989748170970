import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { fetchRequestDetailById } from "../../../redux/overviewSlice";

// import { splitLocation } from "../../../utils/split_location";

const RequestDetailApartmentPopup = ({ selectedId }) => {
  const dispatch = useDispatch();
  const [showBasicDetails, setShowBasicDetails] = useState(true);
  const isLoading = useSelector((state) => state.overview.isLoading);
  const requestDetails = useSelector((state) => state.overview.requestDetails);

  const toggleDetails = () => {
    setShowBasicDetails((prev) => !prev);
  };
  useEffect(() => {
    dispatch(
      fetchRequestDetailById({
        requestId: selectedId,
      })
    );
  }, [dispatch, selectedId]);

  let requestPropertyDetails = "";

  if (typeof requestDetails?.property_details === "string") {
    requestPropertyDetails = requestDetails?.property_details
      ? JSON.parse(requestDetails?.property_details)
      : {};
  } else {
    requestPropertyDetails = requestDetails?.property_details
      ? requestDetails?.property_details
      : {};
  }
  return (
    <Paper
      sx={{
        padding: "16px",
        minWidth: "22rem",
        width: "100%",
        maxWidth: "25rem",
      }}
    >
      {isLoading ? (
        <Box>
          <CircularProgress />
          <Typography variant="body2">Loading...</Typography>
        </Box>
      ) : showBasicDetails ? (
        <>
          <Typography
            variant="body2"
            sx={{ mb: "0.1rem", textTransform: "capitalize" }}
          >
            <strong>Name : </strong>
            {requestDetails?.name}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: "0.1rem", textTransform: "capitalize" }}
          >
            <strong>Needed : </strong>
            {requestPropertyDetails?.needed}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: "0.1rem", textTransform: "capitalize" }}
          >
            <strong>Property Type : </strong>
            {requestDetails?.property_type}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mb: "0.1rem", textTransform: "capitalize" }}
          >
            <strong> Address : </strong>
            {requestDetails?.district}
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong> Phone Number : </strong>
            {requestDetails?.contact}
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong> Email : </strong>
            {requestDetails?.email}
          </Typography>

          {/* Render other basic details */}

          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Location : </strong>
            {requestDetails?.province},{requestDetails?.district},
            {requestDetails?.municipality},{requestDetails?.ward}
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Landmark : </strong>
            {requestPropertyDetails.landmark}
          </Typography>

          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Price : </strong>
            Rs {requestPropertyDetails?.minPrice} - Rs{" "}
            {requestPropertyDetails?.maxPrice}
          </Typography>

          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Property Area : </strong>
            {requestPropertyDetails?.property_area} Aana
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Description : </strong>
            {requestPropertyDetails?.description}
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            sx={{ mb: "0.1rem", textTransform: "capitalize" }}
          >
            <strong>Facing : </strong>
            {requestPropertyDetails?.facing}
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Road Size : </strong>
            {requestPropertyDetails?.road_size} feet
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Floor : </strong>
            {requestPropertyDetails?.floor}
          </Typography>

          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>Furnish : </strong>
            {requestPropertyDetails?.furnish}
          </Typography>
          <Typography variant="body2" sx={{ mb: "0.1rem" }}>
            <strong>BHK : </strong>
            {requestPropertyDetails?.bhk}
          </Typography>

          {/* Ameneties */}
        </>
      )}
      <Box mt={2}>
        <Button variant="contained" onClick={toggleDetails}>
          {showBasicDetails ? "View More" : "View Less"}
        </Button>
      </Box>
    </Paper>
  );
};

export default RequestDetailApartmentPopup;
