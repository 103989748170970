import React from "react";
import { Box, Typography } from "@mui/material";

import TestimonialCarouseList from "./testimonial_carousel_list";
import AddTestimonialCarousel from "./add_testimonial_carousel";

function TestimonialCarousel() {
  return (
    <Box
      sx={{
        my: "2rem",
        backgroundColor: "#f4f4f4",
        padding: "2rem 1rem",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <Typography variant="h4">Testimonial Carousel</Typography>

      <AddTestimonialCarousel />

      <Box sx={{ mt: "2rem" }}>
        <Typography variant="h5">Current Testimonials</Typography>
      </Box>
      <Box sx={{ mt: "2rem" }}>
        <TestimonialCarouseList />
      </Box>
    </Box>
  );
}

export default TestimonialCarousel;
