import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, TextField, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { changePasswordAsync } from "../../redux/authSlice";
function ChangePassword({
  setOpenChangePasswordPopup,
  choosenRole,
  email,
  setOpenForgetPassword,
}) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  const changePasswordLoading = useSelector(
    (state) => state.auth.changePasswordLoading
  );
  function handleSubmit(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password does not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    const data = {
      email,
      password,
      confirmPassword,
      otp,
      choosenRole,
    };
    dispatch(changePasswordAsync(data)).then((action) => {
      if (changePasswordAsync.fulfilled.match(action)) {
        setOpenChangePasswordPopup(false);
        setOpenForgetPassword(false);
      }
    });
  }
  return (
    <Box>
      <Typography variant="body1">
        Please check your email id for otp
      </Typography>

      <Box
        sx={{
          mt: "1rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Box
            display="flex"
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
            gap={2}
          >
            <TextField
              fullWidth
              label="OTP"
              type="number"
              placeholder="Enter OTP"
              min="0"
              size="small"
              sx={{ boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset" }}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              placeholder="Your New Password"
              size="small"
              sx={{ boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset" }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <TextField
              fullWidth
              label="Confirm Password"
              type="password"
              placeholder="Confirm Password"
              size="small"
              sx={{ boxShadow: "3px 5px 10px -8px rgba(0,0,0,0.47) inset" }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <Button
              type="submit"
              variant="contained"
              style={{ backgroundColor: "rgb(37, 77, 110)" }}
            >
              {changePasswordLoading ? "Loading..." : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}

export default ChangePassword;
