import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";
import { throwErrorToast } from "src/utils/throw_toast";

const initialState = {
  isRequestMoreInfoListLoading: false,
  requestMoreInfoList: [
    {
      id: 1,
      user_id: 4,
      property_id: 1,
      property_type: "house",
      description: "i Need House . can you provide me",
      createdAt: "2024-02-04T08:06:48.000Z",
      updatedAt: "2024-02-04T08:06:48.000Z",
      name: "Nepal Real Estate Solution Pvt Ltd",
      email: "nplrealestates@gmail.com",
      phone_number: "98656975428",
    },
  ],
  requestMoreInfoListError: "",
  isRequestMoreInfoDeleteLoading: false,
};
export const fetchRequestMoreInfoListAsync = createAsyncThunk(
  "ads/fetchRequestMoreInfoListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/property/more-info-request
      `,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data?.data;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

export const deleteRequestMoreByIdAsync = createAsyncThunk(
  "ads/deleteRequestMoreByIdAsync",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${BASE_URL}/aadmin/property/more-info-request/${id}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        toast.success("Deleted Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return id;
      }
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Unable to delete";
      throwErrorToast(errMsg);
      return rejectWithValue(errMsg);
    }
  }
);

export const requestMoreInfoSlice = createSlice({
  name: "requestMoreInfo",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchRequestMoreInfoListAsync.pending, (state) => {
        state.isRequestMoreInfoListLoading = true;
      })
      .addCase(fetchRequestMoreInfoListAsync.fulfilled, (state, action) => {
        state.isRequestMoreInfoListLoading = false;
        state.requestMoreInfoList = action.payload;
      })
      .addCase(fetchRequestMoreInfoListAsync.rejected, (state, action) => {
        state.isRequestMoreInfoListLoading = false;
        state.errorMessage = action.payload;
      })
      .addCase(deleteRequestMoreByIdAsync.pending, (state) => {
        state.isRequestMoreInfoDeleteLoading = true;
      })
      .addCase(deleteRequestMoreByIdAsync.fulfilled, (state, action) => {
        state.isRequestMoreInfoDeleteLoading = false;
        state.requestMoreInfoList = state.requestMoreInfoList.filter(
          (item) => item.id !== action.payload
        );
      })
      .addCase(deleteRequestMoreByIdAsync.rejected, (state, action) => {
        state.isRequestMoreInfoDeleteLoading = false;
      });
  },
});

export default requestMoreInfoSlice.reducer;
