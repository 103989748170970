import React from "react";
import { Box } from "@mui/material";
import AgentDashboardBargraph from "./admin_dashboard_statistics_bargraph";
import AgentDashboardPieChart from "./admin_dashboard_statistics_piechart";
import AgentDashboardAnalytics from "./admin_dashboard_statistics_analytics";
function AgentDashboardStatistics() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <AgentDashboardBargraph />
        <AgentDashboardPieChart />
      </Box>
      <br />
      <AgentDashboardAnalytics />
    </div>
  );
}

export default AgentDashboardStatistics;
