import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";

import WestIcon from "@mui/icons-material/West";
import AddIcon from "@mui/icons-material/Add";

import {
  editProperty,
  fetchEditProperty,
  fetchRecentProperty,
} from "../../redux/overviewSlice";
import { setMap } from "../../redux/locationSlice";

import LandFirstLocationDetails from "./land_first_location_details";
import LandSecondOtherDetails from "./land_second_other_details";

const MAX_SECTION = 2;

function EditPropertyLand({ setOpenAddPropertyPopup, selectedId }) {
  const dispatch = useDispatch();
  const [activeSectionId, setActiveSectionId] = useState(1);

  const [propertyDetailsData, setPropertyDetailsData] = useState({});
  const [otherDetailsData, setOtherDetailsData] = useState({});
  const [changedData, setChangedData] = useState({});
  const [propertyImages, setPropertyImages] = useState({});

  const editPropertyData = useSelector(
    (state) => state.overview.editPropertyData
  );

  const selectedMap = useSelector((state) => state.location.selectedMap);

  useEffect(() => {
    dispatch(
      fetchEditProperty({
        property_id: selectedId,
        endPoint: "land",
      })
    );
  }, [dispatch, selectedId]);

  useEffect(() => {
    if (editPropertyData) {
      dispatch(
        setMap({
          lat: editPropertyData?.latitude,
          lng: editPropertyData?.longitude,
        })
      );

      setPropertyDetailsData({
        province: editPropertyData.province,
        district: editPropertyData.district,
        municipality: editPropertyData.municipality,
        ward: editPropertyData.ward,
        landmark: editPropertyData.landmark,
        area_name: editPropertyData.area_name,

        property_name: editPropertyData.property_name,
        property_for: editPropertyData.property_for,
        facing: editPropertyData.facing,
        land_area: editPropertyData.land_area,
        property_area: editPropertyData.property_area,
        twist: editPropertyData.twist,
        road_access: editPropertyData.road_access,
        type: editPropertyData.type,
        owner_name: editPropertyData.owner_name,
        owner_phone: editPropertyData.owner_phone,
        listed_for: editPropertyData.listed_for,
      });

      setPropertyImages({
        property_id: editPropertyData.property_id,
        property_images: editPropertyData.property_image,
        property_type: editPropertyData.property_type,
      });

      setOtherDetailsData({
        price: editPropertyData.price,
        price_type: editPropertyData.price_type,
        price_per: editPropertyData.price_per,
        road_size_type: editPropertyData.road_size_type,
        road_size: editPropertyData.road_size,
        description: editPropertyData.description,
        gallery: [],
        amenities: editPropertyData.amenities,
        social_media: editPropertyData.social_media,
      });
    }
  }, [editPropertyData, dispatch]);

  function handleEditProperty(e) {
    e.preventDefault();
    const formData = new FormData();

    if (
      selectedMap.lat !== editPropertyData.latitude ||
      selectedMap.lng !== editPropertyData.longitude
    ) {
      setChangedData({
        ...changedData,
        latitude: selectedMap.lat || editPropertyData.latitude,
        longitude: selectedMap.lng || editPropertyData.longitude,
      });
    }

    if (Object.keys(changedData).length === 0) return;

    const { gallery, ...rest } = changedData;

    if (gallery) {
      for (let i = 0; i < gallery?.length; i++) {
        formData.append("image", gallery[i]);
      }
    }

    formData.append("property", JSON.stringify(rest));

    dispatch(
      editProperty({
        formData: formData,
        endPoint: "land",
        property_id: selectedId,
      })
    ).then((action) => {
      if (editProperty.fulfilled.match(action)) {
        dispatch(fetchRecentProperty());
        setOpenAddPropertyPopup(false);
      }
    });
  }

  function incrementActiveSectionId() {
    if (activeSectionId === MAX_SECTION) return;
    setActiveSectionId(activeSectionId + 1);
  }
  function decrementActiveSectionId() {
    if (activeSectionId === 1) return;
    setActiveSectionId(activeSectionId - 1);
  }

  return (
    <Box>
      {activeSectionId === 1 && (
        <LandFirstLocationDetails
          propertyDetailsData={propertyDetailsData}
          setPropertyDetailsData={setPropertyDetailsData}
          incrementActiveSectionId={incrementActiveSectionId}
          changedData={changedData}
          setChangedData={setChangedData}
        />
      )}
      {activeSectionId === 2 && (
        <LandSecondOtherDetails
          listedFor={propertyDetailsData?.listed_for}
          otherDetailsData={otherDetailsData}
          setOtherDetailsData={setOtherDetailsData}
          incrementActiveSectionId={incrementActiveSectionId}
          changedData={changedData}
          setChangedData={setChangedData}
          propertyImages={propertyImages}
          setPropertyImages={setPropertyImages}
        />
      )}

      <Box
        sx={{
          width: "100%",
          marginTop: "0.5rem",
          display: "flex",
          justifyContent: activeSectionId === 1 ? "flex-end" : "space-between ",
          alignItems: "center",
        }}
      >
        {activeSectionId !== 1 && (
          <Button
            variant="outlined"
            style={{
              border: "none",
              color: "black",
              fontFamily: "Roboto Serif",
              fontSize: "0.9rem",
              fontWeight: "700",
              textTransform: "none",
            }}
            startIcon={<WestIcon />}
            onClick={decrementActiveSectionId}
          >
            Back
          </Button>
        )}
        {activeSectionId === MAX_SECTION && (
          <Button
            variant="outlined"
            style={{
              borderRadius: "2rem",
              color: "white",
              fontFamily: "Roboto Serif",
              fontSize: "0.75rem",
              textTransform: "none",
              backgroundColor: "#000",
            }}
            endIcon={<AddIcon />}
            onClick={handleEditProperty}
          >
            Edit Property
          </Button>
        )}
      </Box>
    </Box>
  );
}
export default EditPropertyLand;
