import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Dialog, DialogContent, Tooltip } from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";
import {
  fetchCompletedScheduleListAsync,
  changeStatusScheduledPropertyAsync,
} from "../../redux/shootScheduleSlice";

import ShootScheduledStatusPopup from "./change_status_popup";
import SearchBar from "./search_bar";
import RenderScheduleList from "./render_scheduled_list";
function ShootCompletedMain({
  handleOpenCommentPopup,
  handleOpenDeletePopup,
  handleOpenDetailPopup,
  setId,
  id,
}) {
  const dispatch = useDispatch();

  const [openStatusChangePopup, setOpenStatusChangePopup] =
    React.useState(false);

  const shootCompletedList = useSelector(
    (state) => state.shootSchedule.shootCompletedList
  );
  const isShootCompletedListLoading = useSelector(
    (state) => state.shootSchedule.isShootCompletedListLoading
  );
  const shootCompletedError = useSelector(
    (state) => state.shootSchedule.shootCompletedError
  );

  useEffect(() => {
    dispatch(fetchCompletedScheduleListAsync());
  }, [dispatch]);

  function handleOpenStatusChangePopup(id) {
    setId(id);
    setOpenStatusChangePopup(true);
  }

  const handleChangeStatus = () => {
    dispatch(
      changeStatusScheduledPropertyAsync({
        schedule_id: id,
        status: "ready_to_post",
        selectedNavItem: "Shoot Completed",
      })
    ).then((action) => {
      if (changeStatusScheduledPropertyAsync.fulfilled.match(action)) {
        setOpenStatusChangePopup(false);
      }
    });
  };

  const statusIcon = (
    <Tooltip title="Move To Ready Post">
      <PublishedWithChangesIcon sx={{ color: "orange" }} />
    </Tooltip>
  );
  return (
    <Box>
      <Box sx={{ height: "82vh", position: "relative" }}>
        <Box
          sx={{
            width: "35%",
            marginLeft: { sm: "40%", xs: "0", md: "55%" },
            marginBottom: "1%",
            borderRadius: "0.3rem",
            minWidth: "250px",
          }}
        >
          <SearchBar />
        </Box>
        {isShootCompletedListLoading || shootCompletedError ? (
          <ShowLoaderError
            isLoading={isShootCompletedListLoading}
            errorMessage={shootCompletedError}
          />
        ) : (
          <Box
            sx={{
              height: "68vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <RenderScheduleList
              handleOpenCommentPopup={handleOpenCommentPopup}
              handleOpenDeletePopup={handleOpenDeletePopup}
              shootScheduleList={shootCompletedList}
              handleOpenDetailPopup={handleOpenDetailPopup}
              handleOpenStatusChangePopup={handleOpenStatusChangePopup}
              statusIcon={statusIcon}
            />
          </Box>
        )}
      </Box>
      <Dialog
        open={openStatusChangePopup}
        onClose={() => setOpenStatusChangePopup(false)}
      >
        <DialogContent>
          <ShootScheduledStatusPopup
            id={id}
            setOpenStatusChangePopup={setOpenStatusChangePopup}
            handleChangeStatus={handleChangeStatus}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ShootCompletedMain;
