import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Button,
  CircularProgress,
  Box,
  InputAdornment,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
} from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { login } from "../../redux/authSlice";

import ForgetPasswordDialog from "../forget_password/forget_password_popup";

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [choosenRole, setChoosenRole] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [openForgetPasswordDialog, setOpenForgetPasswordDialog] =
    useState(false);
  const isLoading = useSelector((state) => state.auth.isLoading);

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword((prev) => !prev);
  };

  const handleForgetPasswordClose = () => {
    setOpenForgetPasswordDialog(false);
  };
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    dispatch(login({ userName, password, choosenRole })).then((action) => {
      if (login.fulfilled.match(action)) {
        navigate("/");
      }
    });
  };

  return (
    <Box>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(7, 7, 7, 0.04)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <CircularProgress style={{ color: "#007B7F" }} />
        </div>
      )}
      <form onSubmit={handleLoginSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.5rem"
          justifyContent="center"
          alignItems="center"
        >
          <Typography sx={{ fontSize: "1.25rem" }}>
            Login To Your Account
          </Typography>
          <TextField
            type="email"
            label="Username"
            size="small"
            sx={{ width: "100%" }}
            onChange={(e) => setUserName(e.target.value)}
            value={userName || ""}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            type={showPassword ? "text" : "password"}
            label="Password"
            size="small"
            sx={{ width: "100%" }}
            onChange={(e) => setPassword(e.target.value)}
            value={password || ""}
            autoComplete="off"
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormControl size="small" fullWidth>
            <InputLabel>Choose Role</InputLabel>

            <Select
              labelId="demo-select-small-label"
              value={choosenRole}
              required
              label="Choose Role"
              onChange={(e) => setChoosenRole(e.target.value)}
            >
              <MenuItem value="admin">Admin</MenuItem>
              <MenuItem value="staff">Staff</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
            <Button
              type="button"
              sx={{ textTransform: "none", padding: "0", fontSize: "0.75rem" }}
              onClick={() => setOpenForgetPasswordDialog(true)}
            >
              Forget Password?
            </Button>
            <Button
              type="submit"
              variant="contained"
              style={{
                width: "100px",
                alignSelf: "center",
                backgroundColor: "#007B7F",
              }}
              endIcon={<LoginIcon />}
              disabled={isLoading}
            >
              Login
            </Button>
          </Box>
        </Box>
      </form>{" "}
      <Dialog
        open={openForgetPasswordDialog}
        onClose={handleForgetPasswordClose}
      >
        <DialogContent sx={{ p: "2rem" }}>
          <ForgetPasswordDialog
            setOpenForgetPasswordDialog={setOpenForgetPasswordDialog}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default LoginForm;
