import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  clientChatUserList: [],
  clientChatUserListLoading: false,
  clientChatUserListError: null,
  previousClientMessages: [],
  previousClientMessagesLoading: false,
  previousClientMessagesError: null,

  staffChatUserList: [],
  staffChatUserListLoading: false,
  staffChatUserListError: null,
  previousStaffMessages: [],
  previousStaffMessagesLoading: false,
  previousStaffMessagesError: null,

  agentChatUserList: [],
  agentChatUserListLoading: false,
  agentChatuserListError: null,
  previousAgentMessages: [],
  previousAgentMessagesLoading: false,
  previousAgentMessagesError: null,
};

export const fetchClientChatUserListAsync = createAsyncThunk(
  "chat/fetchClientChatUserListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/chat/customer/list`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPreviousClientMessagesByIdAsync = createAsyncThunk(
  "chat/fetchPreviousClientMessagesByIdAsync",
  async (activeUserId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/chat/${activeUserId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchStaffChatUserListAsync = createAsyncThunk(
  "chat/fetchStaffChatUserListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/chat/staff/list`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchPreviousStaffMessagesByIdAsync = createAsyncThunk(
  "chat/fetchPreviousStaffMessagesByIdAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/chat/staff`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);

export const fetchAgentChatUserListAsync = createAsyncThunk(
  "chat/fetchAgentChatUserListAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/chat/agent/list`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      return rejectWithValue(errorMessage);
    }
  }
);
export const fetchPreviousAgentMessagesByIdAsync = createAsyncThunk(
  "chat/fetchPreviousAgentMessagesByIdAsync",
  async (activeUserId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/admin/chat/${activeUserId}`,
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);
export const chatSlice = createSlice({
  name: "chat",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchClientChatUserListAsync.pending, (state) => {
        state.clientChatUserListLoading = true;
      })
      .addCase(fetchClientChatUserListAsync.fulfilled, (state, action) => {
        state.clientChatUserListLoading = false;
        state.clientChatUserList = action.payload;
      })
      .addCase(fetchClientChatUserListAsync.rejected, (state, action) => {
        state.clientChatUserListLoading = false;
        state.clientChatUserListError = action.payload;
      })
      .addCase(fetchPreviousClientMessagesByIdAsync.pending, (state) => {
        state.previousClientMessagesLoading = true;
      })
      .addCase(
        fetchPreviousClientMessagesByIdAsync.fulfilled,
        (state, action) => {
          state.previousClientMessagesLoading = false;
          state.previousClientMessages = action.payload;
        }
      )
      .addCase(
        fetchPreviousClientMessagesByIdAsync.rejected,
        (state, action) => {
          state.previousClientMessagesLoading = false;
          state.previousClientMessagesError = action.payload;
        }
      )
      .addCase(fetchStaffChatUserListAsync.pending, (state) => {
        state.staffChatUserListLoading = true;
      })
      .addCase(fetchStaffChatUserListAsync.fulfilled, (state, action) => {
        state.staffChatUserListLoading = false;
        state.staffChatUserList = action.payload;
      })
      .addCase(fetchStaffChatUserListAsync.rejected, (state, action) => {
        state.staffChatUserListLoading = false;
        state.staffChatUserListError = action.payload;
      })

      .addCase(fetchPreviousStaffMessagesByIdAsync.pending, (state) => {
        state.previousStaffMessagesLoading = true;
      })
      .addCase(
        fetchPreviousStaffMessagesByIdAsync.fulfilled,
        (state, action) => {
          state.previousStaffMessagesLoading = false;
          state.previousStaffMessages = action.payload;
        }
      )
      .addCase(
        fetchPreviousStaffMessagesByIdAsync.rejected,
        (state, action) => {
          state.previousStaffMessagesLoading = false;
          state.previousStaffMessagesError = action.payload;
        }
      )
      .addCase(fetchAgentChatUserListAsync.pending, (state) => {
        state.agentChatUserListLoading = true;
      })
      .addCase(fetchAgentChatUserListAsync.fulfilled, (state, action) => {
        state.agentChatUserListLoading = false;
        state.agentChatUserList = action.payload;
      })
      .addCase(fetchAgentChatUserListAsync.rejected, (state, action) => {
        state.agentChatUserListLoading = false;
        state.agentChatUserListError = action.payload;
      })
      .addCase(fetchPreviousAgentMessagesByIdAsync.pending, (state) => {
        state.previousAgentMessagesLoading = true;
      })
      .addCase(
        fetchPreviousAgentMessagesByIdAsync.fulfilled,
        (state, action) => {
          state.previousAgentMessagesLoading = false;
          state.previousAgentMessages = action.payload;
        }
      )
      .addCase(
        fetchPreviousAgentMessagesByIdAsync.rejected,
        (state, action) => {
          state.previousAgentMessagesLoading = false;
          state.previousAgentMessagesError = action.payload;
        }
      );
  },
});

export default chatSlice.reducer;
