import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  provinceKey: 1,
  provinces: [],
  isProvinceLoading: false,
  provinceError: null,
  districtKey: 1,
  districts: [],
  isDistrictLoading: false,
  districtError: null,
  wardCount: 0,
  municipalities: [],
  isMunicipalityLoading: false,
  municipalityError: null,
  selectedProvince: "",
  selectedDistrict: "",
  selectedMunicipality: "",
  selectedWard: "",
  selectedLandMark: "",
  selectedAreaName: "",
  selectedMap: { lng: "85.307258", lat: "27.670799" },
};

// Async thunk action to fetch provinces
export const fetchProvinces = createAsyncThunk(
  "location/fetchProvinces",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://raw.githubusercontent.com/sagautam5/local-states-nepal/master/dataset/provinces/en.json"
      );
      if (response.status === 200) return response.data;
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

// Async thunk action to fetch districts
export const fetchDistricts = createAsyncThunk(
  "location/fetchDistricts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://raw.githubusercontent.com/sagautam5/local-states-nepal/master/dataset/districts/en.json"
      );
      if (response.status === 200) return response.data;
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

// Async thunk action to fetch municipalities
export const fetchMunicipalities = createAsyncThunk(
  "location/fetchMunicipalities",

  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://raw.githubusercontent.com/sagautam5/local-states-nepal/master/dataset/municipalities/en.json"
      );
      if (response.status === 200) return response.data;
    } catch (err) {
      const errMsg = err?.response?.data?.message || "Something went wrong";
      return rejectWithValue(errMsg);
    }
  }
);

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setProvinceKey: (state, action) => {
      state.provinceKey = action.payload;
    },
    setDistrictKey: (state, action) => {
      state.districtKey = action.payload;
    },
    setWardCount: (state, action) => {
      state.wardCount = action.payload;
    },
    setLocation: (state, action) => {
      const {
        selectedProvince,
        selectedDistrict,
        selectedMunicipality,
        selectedWard,
      } = action.payload;
      state.selectedProvince = selectedProvince;
      state.selectedDistrict = selectedDistrict;
      state.selectedMunicipality = selectedMunicipality;
      state.selectedWard = selectedWard;
    },
    setLandMark: (state, action) => {
      state.selectedLandMark = action.payload.selectedLandMark;
    },
    setAreaName: (state, action) => {
      state.selectedAreaName = action.payload.selectedAreaName;
    },
    setMap: (state, action) => {
      state.selectedMap = action.payload;
    },
    resetLocation: (state) => {
      state.selectedProvince = "";
      state.selectedDistrict = "";
      state.selectedMunicipality = "";
      state.selectedWard = "";
      state.selectedLandMark = "";
      state.selectedAreaName = "";
      state.selectedMap = { lng: "85.307258", lat: "27.670799" };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProvinces.pending, (state) => {
      state.isProvinceLoading = true;
    });
    builder.addCase(fetchProvinces.fulfilled, (state, action) => {
      state.provinces = action.payload;
    });
    builder.addCase(fetchProvinces.rejected, (state, action) => {
      state.provinceError = action.payload;
    });

    builder.addCase(fetchDistricts.pending, (state) => {
      state.isDistrictLoading = true;
    });
    builder.addCase(fetchDistricts.fulfilled, (state, action) => {
      state.districts = action.payload;
    });
    builder.addCase(fetchDistricts.rejected, (state, action) => {
      state.districtError = action.payload;
    });
    builder.addCase(fetchMunicipalities.pending, (state) => {
      state.isMunicipalityLoading = true;
    });
    builder.addCase(fetchMunicipalities.fulfilled, (state, action) => {
      state.municipalities = action.payload;
    });
    builder.addCase(fetchMunicipalities.rejected, (state, action) => {
      state.municipalityError = action.payload;
    });
  },
});

export const {
  setLocation,
  setLandMark,
  setAreaName,
  setMap,
  setProvinceKey,
  setDistrictKey,
  setWardCount,
  resetLocation,
} = locationSlice.actions;
export default locationSlice.reducer;
