import React from "react";

import { Box, Grid, Typography, IconButton } from "@mui/material";
import { toast } from "react-toastify";

import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

import Map from "../map/choose_map_location";

import { compressImage } from "../../utils/image_compressor";

function PropertyMapImages({ descriptionData, setDescriptionData }) {
  const handleImageChange = async (event) => {
    const files = event.target.files;

    try {
      const compressedFiles = await toast.promise(
        Promise.all(
          [...files].map(async (file) => {
            const compressedFile = await compressImage(file);
            return compressedFile;
          })
        ),
        {
          pending: "Compressing images. Please wait...",
          success: "Compression complete!",
          error: "Error compressing images. Please try again.",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        }
      );

      if (compressedFiles) {
        setDescriptionData({
          ...descriptionData,
          gallery: [...compressedFiles],
        });
      }
    } catch (error) {
      console.error("Error compressing images:", error);
    }
  };

  const handleGalleryRemove = (e) => {
    setDescriptionData({
      ...descriptionData,
      gallery: [],
    });
  };

  return (
    <>
      <Grid item xs={6}>
        <Typography>Gallery</Typography>
        <input
          type="file"
          id="imageInput"
          multiple
          accept=" .jpg, .jpeg"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label
          htmlFor="imageInput"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",

            borderRadius: "0.25rem",
            flexDirection: "column",
            width: "fit-content",
            padding: "0.5rem",
          }}
        >
          <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
            Upload Photo
          </Typography>
          <span>
            <PhotoLibraryIcon
              sx={{ color: "black", opacity: "0.5", fontSize: "2rem" }}
            />
          </span>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "2px 4px",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontSize: "0.65rem" }}>
              {descriptionData?.gallery?.length || "0"} photos <br /> uploaded
            </Typography>
            <IconButton onClick={handleGalleryRemove}>
              <DeleteSweepIcon sx={{ color: "#e89eab" }} />
            </IconButton>
          </Box>
        </label>
      </Grid>
      <Grid item xs={6}>
        <Map />
      </Grid>
    </>
  );
}

export default PropertyMapImages;
