import React from "react";
import OverviewPropertyList from "./property_list/overview_property_list";
import OverviewCustomerList from "./customer_list/overview_customer_list";
import OverviewAgents from "./agents_list/overview_agent_list";
import OverviewRequest from "./request_list/overview_request";
function AdminDashboardOverview({ selectedNavItem }) {
  return (
    <>
      {selectedNavItem === "Property List" && <OverviewPropertyList />}
      {selectedNavItem === "Customer List" && <OverviewCustomerList />}
      {selectedNavItem === "Agents" && <OverviewAgents />}
      {selectedNavItem === "Request" && <OverviewRequest />}
    </>
  );
}

export default AdminDashboardOverview;
