import React from "react";
import { useSelector } from "react-redux";

//LIBRARY IMPORT
import { Box, Typography, Avatar } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import EditPropertyHome from "./edit_property_home";
import EditPropertyApartment from "./edit_property_apartment";
import EditPropertyLand from "./edit_property_land";

//ASSETS IMPORT

import logo from "../../assets/images/logo.png";

//STYLE DECLARATION
import "./edit_property.styles.css";
function EditPropertyModal({ setOpenEditDialog, selectedType, selectedId }) {
  const isLoading = useSelector((state) => state.overview.isLoading);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
          my: "0.5rem",
          alignItems: "center",
        }}
      >
        <Avatar alt="NRES" src={logo} />
        <Typography
          variant="body1"
          textAlign="center"
          color="black"
          fontSize="1.5rem"
          fontWeight="700"
        >
          Edit Property
        </Typography>
      </Box>

      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",

            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
        >
          <CircularProgress className="custom-circular-progress" />
        </Box>
      )}
      <Box sx={{ marginTop: "1rem", mx: "auto", width: "80%" }}>
        {selectedType === "house" && (
          <EditPropertyHome
            setOpenAddPropertyPopup={setOpenEditDialog}
            selectedId={selectedId}
          />
        )}
        {selectedType === "apartment" && (
          <EditPropertyApartment
            setOpenAddPropertyPopup={setOpenEditDialog}
            selectedId={selectedId}
          />
        )}
        {selectedType === "land" && (
          <EditPropertyLand
            setOpenAddPropertyPopup={setOpenEditDialog}
            selectedId={selectedId}
          />
        )}
      </Box>
    </Box>
  );
}

export default EditPropertyModal;
