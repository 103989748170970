import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";

import EditSocialMediaLink from "./edit_social_media_link";
import PropertyMapImages from "./property_map_images";

function PropertyDescription({
  setDescriptionData,
  descriptionData,
  setChangedData,
  changedData,
  propertyImages,
  setPropertyImages,
}) {
  const [socialMediaLinks, setSocialMediaLinks] = useState({
    facebook: "",
    youtube: "",
    instagram: "",
    twitter: "",
    tiktok: "",
  });
  const [amenities, setAminities] = useState({
    hot_water: false,
    electricity: false,
    garden: false,
    well: false,
    drainage: false,
    garbage_disposal: false,
    cable_connection: false,
    internet: false,
    reserve_tank: false,
    rental_income: false,
  });

  useEffect(() => {
    let tempAmenities = {};
    let tempSocialMediaLinks = {};
    if (typeof descriptionData?.amenities === "string") {
      tempAmenities = JSON.parse(descriptionData?.amenities);
    } else {
      tempAmenities = descriptionData?.amenities;
    }
    if (typeof descriptionData?.social_media === "string") {
      tempSocialMediaLinks = JSON.parse(descriptionData?.social_media);
    } else {
      tempSocialMediaLinks = descriptionData?.social_media;
    }
    setAminities(tempAmenities);
    setSocialMediaLinks(tempSocialMediaLinks);
  }, [descriptionData]);

  const handleAmenitiesChange = (event) => {
    const { name, checked } = event.target;

    setAminities({
      ...amenities,
      [name]: checked,
    });

    setChangedData({
      ...changedData,
      amenities,
    });
  };

  const handleChange = (e) => {
    e.preventDefault();
    setDescriptionData({
      ...descriptionData,
      [e.target.name]: e.target.value,
    });
    setChangedData({
      ...changedData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          sx={{
            border: "1px solid #ccc",
            borderRadius: "0.25rem",
            position: "relative",
            padding: "0.5rem 0.5rem 0.2rem 0.5rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              backgroundColor: "#F6EDED",
              position: "absolute",
              top: "-0.5rem",
              left: "1rem",
            }}
          >
            Amenities
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="hot_water"
                checked={amenities.hot_water}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>Hot Water</Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="electricity"
                checked={amenities.electricity}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>Electricity</Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="garden"
                checked={amenities.garden}
                onChange={handleAmenitiesChange}
              />
            }
            label={<Typography sx={{ fontSize: "0.75rem" }}>Garden</Typography>}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="well"
                checked={amenities.well}
                onChange={handleAmenitiesChange}
              />
            }
            label={<Typography sx={{ fontSize: "0.75rem" }}>Well</Typography>}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="drainage"
                checked={amenities.drainage}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>Drainage</Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />{" "}
          <FormControlLabel
            control={
              <Checkbox
                name="garbage_disposal"
                checked={amenities.garbage_disposal}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>
                Garbage Disposal
              </Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="cable_connection"
                checked={amenities.cable_connection}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>
                Cable Connection
              </Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="internet"
                checked={amenities.internet}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>Internet</Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="reserve_tank"
                checked={amenities.reserve_tank}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>Reserve Tank</Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="rental_income"
                checked={amenities.rental_income}
                onChange={handleAmenitiesChange}
              />
            }
            label={
              <Typography sx={{ fontSize: "0.75rem" }}>
                Rental Income
              </Typography>
            }
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: 18,
              },
            }}
          />
        </Box>
      </Grid>

      <Grid item xs={6}>
        <TextField
          type="number"
          label="Car Parking"
          size="small"
          sx={{ width: "100%", maxWidth: "13rem" }}
          name="parking_car"
          onChange={handleChange}
          value={descriptionData?.parking_car}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="number"
          label="Bike Parking"
          size="small"
          sx={{ width: "100%", maxWidth: "13rem" }}
          name="parking_bike"
          onChange={handleChange}
          value={descriptionData?.parking_bike}
        />
      </Grid>
      <Grid item xs={12}>
        <EditSocialMediaLink
          changedData={changedData}
          setChangedData={setChangedData}
          socialMediaLinks={socialMediaLinks}
          setSocialMediaLinks={setSocialMediaLinks}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Description"
          multiline
          rows={4}
          size="small"
          sx={{ width: "100%" }}
          name="description"
          onChange={handleChange}
          value={descriptionData?.description || ""}
        />
      </Grid>
      <PropertyMapImages
        setChangedData={setChangedData}
        changedData={changedData}
        propertyImages={propertyImages}
        setPropertyImages={setPropertyImages}
      />
    </Grid>
  );
}

export default PropertyDescription;
