import React from "react";
import { Box, Typography } from "@mui/material";
import VideoCarouselListSection from "./video_carousel_list";
import AddVideoCarousel from "./add_video_carousel";

function VideoCarousel() {
  return (
    <Box
      sx={{
        my: "2rem",
        backgroundColor: "#f4f4f4",
        padding: "2rem 1rem",
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
      }}
    >
      <Typography variant="h4">Video Carousel</Typography>

      <AddVideoCarousel />

      <Box sx={{ mt: "2rem" }}>
        <Typography variant="h5">Current Videos</Typography>
      </Box>
      <Box sx={{ mt: "2rem" }}>
        <VideoCarouselListSection />
      </Box>
    </Box>
  );
}
export default VideoCarousel;
