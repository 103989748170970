//ADD COMMA IN PRICE INPUT FIELD
export const formatPriceChange = (rawValue) => {
  let formattedValue = "";
  const numericValue = parseFloat(rawValue.replace(/,/g, ""));
  if (!isNaN(numericValue)) {
    formattedValue = numericValue.toLocaleString("en-IN");
  } else {
    formattedValue = "";
  }
  return formattedValue;
};
// REMOVE COMMA TO SEND TO BACKEND
export const unFormatPriceChange = (rawValue) => {
  let unFormattedValue = "";
  const numericValue = parseFloat(rawValue.replace(/,/g, ""));
  if (!isNaN(numericValue)) {
    unFormattedValue = numericValue;
  } else {
    unFormattedValue = "";
  }
  return unFormattedValue;
};

// ADD COMMA IN PRICE
export const formatPrice = (value) => {
  if (!value) return value;

  value = value.toString();
  var lastThree = value.substring(value.length - 3);
  var otherNumbers = value.substring(0, value.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
  return res;
};
