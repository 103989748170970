import React, { useEffect } from "react";
import { Grid, Dialog, DialogContent, Slide, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContactListAsync,
  deleteContactByIdAsync,
} from "../../redux/contactSlice";

import ContactDeleteDialog from "./contact_delete_dialog";
import ContactCard from "./contact_card";
import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ContactList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(null);

  const contactList = useSelector((state) => state.contact.contactList);
  const isContactListLoading = useSelector(
    (state) => state.contact.isContactListLoading
  );
  const contactListError = useSelector(
    (state) => state.contact.contactListError
  );

  useEffect(() => {
    dispatch(fetchContactListAsync());
  }, [dispatch]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    dispatch(deleteContactByIdAsync(selectedId)).then((action) => {
      if (deleteContactByIdAsync.fulfilled.match(action)) {
        setOpenDeleteDialog(false);
      }
    });
  };
  return (
    <div style={{ marginTop: "1rem" }}>
      <Box sx={{ padding: "2rem 4rem" }}>
        {isContactListLoading || contactListError ? (
          <ShowLoaderError
            isLoading={isContactListLoading}
            errorMessage={contactListError}
          />
        ) : (
          <Grid container spacing={2}>
            {contactList?.map((item) => (
              <Grid item xs={12} key={item.id}>
                <ContactCard
                  item={item}
                  setSelectedId={setSelectedId}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        {" "}
        <DialogContent
          sx={{
            padding: "2rem",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <ContactDeleteDialog
            handleDeleteClick={handleDeleteClick}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ContactList;
