import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

function ChangeAdsPopup({ setOpenAdsDialog, handlePropertyAds }) {
  const [platform, setPlatform] = useState("");
  const [adStatus, setAdStatus] = useState("");

  return (
    <Box
      sx={{
        width: "20rem",
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <Typography sx={{ color: "black", fontWeight: "500", fontSize: "1rem" }}>
        Change Ads Status
      </Typography>

      <FormControl size="small" fullWidth>
        <InputLabel>Platform</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Instagram"
          value={platform || ""}
          onChange={(e) => setPlatform(e.target.value)}
        >
          <MenuItem value="facebook">Facebook</MenuItem>
          <MenuItem value="instagram">Instagram</MenuItem>
          <MenuItem value="twitter">Twitter</MenuItem>
          <MenuItem value="youtube">Youtube</MenuItem>
          <MenuItem value="tiktok">Tiktok</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" fullWidth>
        <InputLabel>Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          label="Youtube"
          value={adStatus || ""}
          onChange={(e) => setAdStatus(e.target.value)}
        >
          <MenuItem value="planned">Planned</MenuItem>
          <MenuItem value="unplanned">Unplanned</MenuItem>
          <MenuItem value="progress">Progress</MenuItem>
          <MenuItem value="posted">Posted</MenuItem>
        </Select>
      </FormControl>
      <Button
        endIcon={<AddIcon />}
        onClick={() => handlePropertyAds(platform, adStatus)}
        variant="contained"
        sx={{
          backgroundColor: "black",
          "&:hover": {
            backgroundColor: "#333333",
          },
        }}
      >
        Change
      </Button>
    </Box>
  );
}

export default ChangeAdsPopup;
