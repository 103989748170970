import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Dialog, DialogContent, Typography, Tooltip } from "@mui/material";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";
import {
  fetchShootScheduledListAsync,
  changeStatusScheduledPropertyAsync,
} from "../../redux/shootScheduleSlice";

import ShootScheduledStatusPopup from "./change_status_popup";
import AddShootSchedulePopup from "./add_shoot_schedule_popup";
import SearchBar from "./search_bar";
import RenderScheduleList from "./render_scheduled_list";

function ScheduledPropertyMain({
  handleOpenCommentPopup,
  handleOpenDeletePopup,
  handleOpenDetailPopup,
  setId,
  id,
}) {
  const dispatch = useDispatch();

  const [openStatusChangePopup, setOpenStatusChangePopup] =
    React.useState(false);
  const [openAddSchedulePopup, setOpenAddSchedulePopup] = React.useState(false);

  const scheduledPropertyList = useSelector(
    (state) => state.shootSchedule.scheduledPropertyList
  );
  const isscheduledPropertyListLoading = useSelector(
    (state) => state.shootSchedule.isscheduledPropertyListLoading
  );
  const scheduledPropertyListError = useSelector(
    (state) => state.shootSchedule.scheduledPropertyListError
  );

  useEffect(() => {
    dispatch(fetchShootScheduledListAsync());
  }, [dispatch]);

  function handleOpenStatusChangePopup(id) {
    setId(id);
    setOpenStatusChangePopup(true);
  }
  const statusIcon = (
    <Tooltip title="Move to Completed">
      <PublishedWithChangesIcon sx={{ color: "black" }} />
    </Tooltip>
  );

  const handleChangeStatus = () => {
    dispatch(
      changeStatusScheduledPropertyAsync({
        schedule_id: id,
        status: "completed",
        selectedNavItem: "Scheduled Property",
      })
    ).then((action) => {
      if (changeStatusScheduledPropertyAsync.fulfilled.match(action)) {
        setOpenStatusChangePopup(false);
      }
    });
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ height: "82vh", position: "relative" }}>
        <Box
          sx={{
            width: "35%",
            marginLeft: { sm: "40%", xs: "0", md: "55%" },
            marginBottom: "1%",
            borderRadius: "0.3rem",
            minWidth: "250px",
          }}
        >
          <SearchBar />
        </Box>
        {isscheduledPropertyListLoading || scheduledPropertyListError ? (
          <ShowLoaderError
            isLoading={isscheduledPropertyListLoading}
            errorMessage={scheduledPropertyListError}
          />
        ) : (
          <Box
            sx={{
              height: "68vh",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <RenderScheduleList
              handleOpenCommentPopup={handleOpenCommentPopup}
              handleOpenDeletePopup={handleOpenDeletePopup}
              shootScheduleList={scheduledPropertyList}
              handleOpenDetailPopup={handleOpenDetailPopup}
              handleOpenStatusChangePopup={handleOpenStatusChangePopup}
              statusIcon={statusIcon}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          backgroundColor: "black",
          padding: "1rem",
          borderRadius: "0.3rem",
          position: "absolute",
          bottom: "1rem",
          left: "50%",
          transform: "translate(-50%,0)",
          width: "80%",
          mx: "auto",
        }}
      >
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontFamily: "Roboto Serif",
            fontSize: "1.3rem",
            cursor: "pointer",
          }}
          onClick={() => setOpenAddSchedulePopup(true)}
        >
          Schedule Shoot
        </Typography>
      </Box>
      <Dialog
        open={openAddSchedulePopup}
        onClose={() => setOpenAddSchedulePopup(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          sx={{
            padding: "9px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AddShootSchedulePopup
            setOpenAddSchedulePopup={setOpenAddSchedulePopup}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openStatusChangePopup}
        onClose={() => setOpenStatusChangePopup(false)}
      >
        <DialogContent>
          <ShootScheduledStatusPopup
            id={id}
            setOpenStatusChangePopup={setOpenStatusChangePopup}
            handleChangeStatus={handleChangeStatus}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ScheduledPropertyMain;
