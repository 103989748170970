import React from "react";
// import OverviewPropertyListRecentTable from "./overview_property_list_recent_table";
// import OverviewPropertyListTable2 from "./overview_property_list_table2";
import OverviewPropertyListUnapproveTable from "./overview_property_list_unapprove_table";
import OverviewPropertyListSoldTable from "./overview_property_list_sold_table";
import RecentTableMain from "./recent_table_main";
function AdminDashboardOverview() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "5rem",
      }}
    >
      <RecentTableMain />
      {/* <OverviewPropertyListRecentTable /> */}
      <OverviewPropertyListUnapproveTable />
      <OverviewPropertyListSoldTable />
    </div>
  );
}

export default AdminDashboardOverview;
