import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../config/base_url";

const initialState = {
  // user: null,
  isAuthenticated: false,
  role: "",
  isLoading: false,
  userCheckLoading: true,
  errorMsg: "",
  successMsg: "",
  userId: null,

  isForgetPasswordLoading: false,
  isChangePasswordLoading: false,
};

export const checkAuthAsync = createAsyncThunk(
  "auth/checkAuthAsync",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASE_URL}/admin/isLoggedIn`, {
        withCredentials: true,
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";

      return rejectWithValue(errorMessage);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async ({ userName, password, choosenRole }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${choosenRole}/login`,
        {
          email: userName,
          password,
        },
        { withCredentials: true }
      );
      if (response.status === 200) {
        toast.success("Welcome Admin!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/admin/logout`,
        {},
        { withCredentials: true }
      );
      if (response.status === 200) {
        toast.success("Logout Successfull", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        return "";
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong!!";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const forgetPasswordAsync = createAsyncThunk(
  "auth/forgetPasswordAsync",
  async ({ email, choosenRole }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${choosenRole}/forgetPassword?email=${email}`,
        {
          email,
        },
        { withCredentials: true }
      );
      if (response.status === 200) {
        toast.success("Please check your email for new password", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response?.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "auth/changePasswordAsync",
  async (data, { rejectWithValue }) => {
    const { email, password, confirmPassword, choosenRole, otp } = data;
    try {
      const response = await axios.post(
        `${BASE_URL}/${choosenRole}/forgetPassword?email=${email}&token=${otp}`,
        {
          password,
          confirmPassword,
        }
      );
      if (response.status === 200) {
        toast.success("Password Changed Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return response?.data;
      }
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message || "Something went wrong";
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return rejectWithValue(errorMessage);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthenticated(state, action) {
      state.isAuthenticated = true;
      state.role = action.payload.role;
      state.userId = action.payload.user_id;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAuthAsync.pending, (state) => {
        state.userCheckLoading = true;
      })
      .addCase(checkAuthAsync.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.role = action.payload.role;
        state.userId = action.payload.user_id;
        state.userCheckLoading = false;
      })
      .addCase(checkAuthAsync.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.errorMsg = action.payload;
        state.userCheckLoading = false;
      })
      .addCase(forgetPasswordAsync.pending, (state) => {
        state.isForgetPasswordLoading = true;
      })
      .addCase(forgetPasswordAsync.fulfilled, (state) => {
        state.isForgetPasswordLoading = false;
      })
      .addCase(forgetPasswordAsync.rejected, (state) => {
        state.isForgetPasswordLoading = false;
      })
      .addCase(changePasswordAsync.pending, (state) => {
        state.isChangePasswordLoading = true;
      })
      .addCase(changePasswordAsync.fulfilled, (state) => {
        state.isChangePasswordLoading = false;
      })
      .addCase(changePasswordAsync.rejected, (state) => {
        state.isChangePasswordLoading = false;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.role = action.payload.role;
        state.userId = action.payload.user_id;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.errorMsg = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = false;
        state.role = action.payload;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.errorMsg = action.payload;
      });
  },
});

export const { setAuthenticated } = authSlice.actions;
export default authSlice.reducer;
