import React, { useState } from "react";
import { useSelector } from "react-redux";

//LIBRARY IMPORT
import {
  Box,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

//COMPONENT IMPORT
import AddPropertyHome from "./add_property_home";
import AddPropertyApartment from "./add_property_apartment";
import AddPropertyLand from "./add_property_land";

//ASSETS IMPORT
import HouseIcon from "@mui/icons-material/House";
import BusinessIcon from "@mui/icons-material/Business";
import LandscapeIcon from "@mui/icons-material/Landscape";
import logo from "../../assets/images/logo.png";

//STYLE DECLARATION
import "./add_property.styles.css";
function AddPropertyModal({ setOpenAddPropertyPopup }) {
  const [selectedItem, setSelectedItem] = useState("home");
  const [listedFor, setListedFor] = useState("sale");
  const isLoading = useSelector((state) => state.overview.isLoading);
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: "0.5rem",
          my: "0.5rem",
          alignItems: "center",
        }}
      >
        <Avatar alt="NRES" src={logo} />
        <Typography
          variant="body1"
          textAlign="center"
          color="black"
          fontSize="1.5rem"
          fontWeight="700"
        >
          Add Your Property
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "0.8rem",
        }}
      >
        <FormControl fullWidth size="small" sx={{ maxWidth: "8rem" }}>
          <InputLabel>Listed For</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            value={listedFor}
            label="Listed For"
            defaultValue="sale"
            onChange={(e) => setListedFor(e.target.value)}
          >
            <MenuItem value="sale">Sale</MenuItem>
            <MenuItem value="rent">Rent</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box>
          <IconButton onClick={() => setSelectedItem("home")}>
            <HouseIcon
              sx={{
                width: "2.8rem",
                height: "2.8rem",
                color: selectedItem === "home" ? "#31628D" : "#000",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            textAlign="center"
            color="black"
            sx={{
              marginTop: "-0.5rem",
              borderBottom: selectedItem === "home" ? "3px solid #31628D" : "",
            }}
          >
            Home
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setSelectedItem("apartment")}>
            <BusinessIcon
              sx={{
                width: "2.8rem",
                height: "2.8rem",
                color: selectedItem === "apartment" ? "#31628D" : "#000",
              }}
            />
          </IconButton>
          <Typography
            variant="body2"
            textAlign="center"
            color="black"
            sx={{
              marginTop: "-0.5rem",
              borderBottom:
                selectedItem === "apartment" ? "3px solid #31628D" : "",
            }}
          >
            Apartment
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setSelectedItem("land")}>
            <LandscapeIcon
              sx={{
                width: "2.8rem",
                height: "2.8rem",
                color: selectedItem === "land" ? "#31628D" : "#000",
              }}
            />
          </IconButton>{" "}
          <Typography
            variant="body2"
            textAlign="center"
            color="black"
            sx={{
              marginTop: "-0.5rem",
              borderBottom: selectedItem === "land" ? "3px solid #31628D" : "",
            }}
          >
            Land
          </Typography>
        </Box>
      </Box>

      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",

            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
        >
          <CircularProgress className="custom-circular-progress" />
        </Box>
      )}
      <Box sx={{ marginTop: "1rem", mx: "auto", width: "80%" }}>
        {selectedItem === "home" && (
          <AddPropertyHome
            listedFor={listedFor}
            setOpenAddPropertyPopup={setOpenAddPropertyPopup}
          />
        )}
        {selectedItem === "apartment" && (
          <AddPropertyApartment
            listedFor={listedFor}
            setOpenAddPropertyPopup={setOpenAddPropertyPopup}
          />
        )}
        {selectedItem === "land" && (
          <AddPropertyLand
            listedFor={listedFor}
            setOpenAddPropertyPopup={setOpenAddPropertyPopup}
          />
        )}
      </Box>
    </Box>
  );
}

export default AddPropertyModal;
