import React from "react";

import { Grid } from "@mui/material";
import ShootScheduleCard from "./shoot_schedule_card";

function RenderScheduleList({
  handleOpenDeletePopup,
  handleOpenStatusChangePopup,
  handleOpenCommentPopup,
  handleOpenDetailPopup,
  shootScheduleList,
  statusIcon,
}) {
  return (
    <Grid container spacing={4}>
      {shootScheduleList?.map((item) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
          <ShootScheduleCard
            item={item}
            handleOpenDetailPopup={handleOpenDetailPopup}
            handleOpenDeletePopup={handleOpenDeletePopup}
            handleOpenStatusChangePopup={handleOpenStatusChangePopup}
            handleOpenCommentPopup={handleOpenCommentPopup}
            statusIcon={statusIcon}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default RenderScheduleList;
