import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import houseLoading from "../assets/gif/houseLoading.gif";
function RootLayout() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const userCheckLoading = useSelector((state) => state.auth.userCheckLoading);
  return (
    <>
      {userCheckLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",

            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
          }}
        >
          <img
            src={houseLoading}
            alt="House Loading"
            style={{ width: "5rem", height: "5rem" }}
          />
          <Typography variant="body2">Hold On</Typography>
        </Box>
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default RootLayout;
