import React from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addScheduleAsync } from "../../redux/shootScheduleSlice";
import ChooseMapLocation from "../map/choose_map_location";

function AddShootSchedule({ setOpenAddSchedulePopup }) {
  const dispatch = useDispatch();
  const [propertyType, setPropertyType] = React.useState("");
  const [listedFor, setListedFor] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [contact, setContact] = React.useState("");
  const [scheduledDate, setScheduledDate] = React.useState("");
  const selectedMap = useSelector((state) => state.location.selectedMap);

  const isAddScheduleLoading = useSelector(
    (state) => state.shootSchedule.isAddScheduleLoading
  );
  const handleShootSchedule = (e) => {
    e.preventDefault();
    const selectMap = JSON.parse(selectedMap);
    const data = {
      property_type: propertyType,
      listed_for: listedFor,
      location: address,
      owner: ownerName,
      contact: contact,
      scheduled_date: scheduledDate,
      latitude: selectMap.lat,
      longitude: selectMap.lng,
    };
    dispatch(addScheduleAsync(data)).then((action) => {
      if (addScheduleAsync.fulfilled.match(action)) {
        setPropertyType("");
        setListedFor("");
        setOwnerName("");
        setAddress("");
        setContact("");
        setScheduledDate("");
        setOpenAddSchedulePopup(false);
      }
    });
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            color: "black",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
          variant="body1"
        >
          Add Schedule
        </Typography>
      </Box>
      <hr />
      <Box sx={{ mt: "2rem", mb: "1rem", px: "2rem" }}>
        <form onSubmit={handleShootSchedule}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1.1rem",
            }}
          >
            <FormControl fullWidth size="small">
              <InputLabel>Listed For</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={listedFor}
                label="Property"
                onChange={(e) => {
                  setListedFor(e.target.value);
                }}
              >
                <MenuItem value="sale">Sale</MenuItem>
                <MenuItem value="rent">Rent</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Property</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={propertyType}
                label="Property"
                onChange={(e) => {
                  setPropertyType(e.target.value);
                }}
              >
                <MenuItem value="house">House</MenuItem>
                <MenuItem value="apartment">Apartment</MenuItem>
                <MenuItem value="land">Land</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Owner Name"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
            />
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              type="number"
              label="Contact"
              min="0"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
            <TextField
              type="date"
              label="Schedule Date"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={scheduledDate}
              onChange={(e) => setScheduledDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />{" "}
          </Box>{" "}
          <Box sx={{ mt: "1rem" }}>
            <ChooseMapLocation />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                ":hover": {
                  backgroundColor: "#121212",
                },
              }}
              type="submit"
              disabled={isAddScheduleLoading}
            >
              {isAddScheduleLoading ? "Adding..." : "Add Schedule"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default AddShootSchedule;
