import { TableRow, TableCell, Checkbox, Box, Tooltip } from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

import TikTokIcon from "../../ui/tiktok_icon";

import { getDate } from "src/utils/get_datetime";

// ----------------------------------------------------------------------

const getColor = (status) => {
  switch (status) {
    case "unplanned":
      return "#B22222";
    case "posted":
      return "#006400";
    case "progress":
      return "orange";
    case "planned":
      return "#000080";
    default:
      return "#000000";
  }
};

export default function RecentTableRow({
  selectedId,
  pId,
  propertyType,
  propertyTitle,
  location,
  posted,
  listedFor,
  listingType,
  facebook,
  youtube,
  instagram,
  twitter,
  tiktok,
  handleClick,
}) {
  return (
    <TableRow
      hover
      onClick={() => handleClick(pId, propertyType)}
      role="checkbox"
      aria-checked={selectedId === pId}
      tabIndex={-1}
      key={pId}
      selected={selectedId === pId}
      sx={{ cursor: "pointer" }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={selectedId === pId}
          inputProps={{
            "aria-labelledby": pId,
          }}
        />
        {pId}
      </TableCell>

      <TableCell align="center">{propertyTitle}</TableCell>
      <TableCell align="center">{location}</TableCell>
      <TableCell align="center">{getDate(posted)}</TableCell>
      <TableCell align="center">{listedFor}</TableCell>
      <TableCell align="center">{listingType}</TableCell>
      <TableCell align="center">
        <Box>
          <Tooltip title={facebook}>
            <FacebookIcon sx={{ color: getColor(facebook) }} />
          </Tooltip>
          <Tooltip title={youtube}>
            <YouTubeIcon sx={{ color: getColor(youtube) }} />
          </Tooltip>
          <Tooltip title={instagram}>
            <InstagramIcon
              sx={{
                color: getColor(instagram),
              }}
            />
          </Tooltip>
          <Tooltip title={twitter}>
            <TwitterIcon sx={{ color: getColor(twitter) }} />
          </Tooltip>
          <Tooltip title={tiktok}>
            <span>
              <TikTokIcon color={getColor(tiktok)} />
            </span>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
}
