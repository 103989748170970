import { useDispatch } from "react-redux";
// @mui
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
} from "@mui/material";
// components
import Ceo from "../../assets/images/Ceo.png";
import { logout } from "../../redux/authSlice";
// mocks_
const MENU_OPTIONS = [
  {
    label: "Active Hours",
    icon: "eva:home-fill",
    linkTo: "/",
  },
  {
    label: "Settings",
    icon: "eva:person-fill",
    linkTo: "#",
  },
];

function AdminDashboardAccount({ setAccountAnchorEl }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setAccountAnchorEl(null);
  };
  const handleLogout = () => {
    dispatch(logout());
  };
  return (
    <Box>
      <Box
        sx={{
          my: 1.5,
          px: 2.5,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar alt="Remy Sharp" src={Ceo} sx={{ width: 56, height: 56 }} />
        <Typography
          variant="body1"
          sx={{
            backgroundcolor: "primary",
            backgroundImage: `linear-gradient(#FF0440, #FFD700, #838A2E, #FB0489, #868E26, #7C814B, #FF0440)`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat",
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          CEO
        </Typography>
        <Typography variant="subtitle2" noWrap sx={{ fontWeight: "bold" }}>
          Gopal Baraudi
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
          gopalbaraudi@gmail.com
        </Typography>
      </Box>

      <Divider sx={{ borderStyle: "dashed" }} />

      <Stack sx={{ p: 1 }}>
        {MENU_OPTIONS.map((option) => (
          <MenuItem key={option.label} to={option.linkTo} onClick={handleClose}>
            {option.label}
          </MenuItem>
        ))}
      </Stack>

      <Divider sx={{ borderStyle: "dashed" }} />

      <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
        Logout
      </MenuItem>
    </Box>
  );
}
export default AdminDashboardAccount;
