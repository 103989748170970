import imageCompression from "browser-image-compression";

export async function compressImage(imageFile) {
  const options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };
  try {
    // if file size is less than options.maxSizeMB, return original file
    if (imageFile.size / 1024 / 1024 < options.maxSizeMB) {
      return imageFile;
    }

    const compressedFile = await imageCompression(imageFile, options);

    // convert blob to file
    const convertedBlobFile = new File([compressedFile], "image.jpeg", {
      type: "image/jpeg",
      lastModified: Date.now(),
    });

    return convertedBlobFile;
  } catch (error) {
    console.log(error);
  }
}

// import imageCompression from "browser-image-compression";

// export function compressImage(imageFile) {
//   console.log("originalFile instanceof Blob", imageFile instanceof Blob);
//   console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

//   const options = {
//     maxSizeMB: 1.9,
//     maxWidthOrHeight: 1920,
//     useWebWorker: true,
//   };

//   return new Promise((resolve, reject) => {
//     try {
//       imageCompression(imageFile, options)
//         .then((compressedFile) => {
//           console.log(
//             "compressedFile instanceof Blob",
//             compressedFile instanceof Blob
//           );
//           console.log(
//             `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
//           );

//           // Convert compressed Blob to data URL with "image/jpeg" MIME type
//           const reader = new FileReader();
//           reader.onload = (event) => {
//             const dataURL = event.target.result;
//             console.log(dataURL);
//             resolve(dataURL);
//           };
//           reader.readAsDataURL(compressedFile);
//         })
//         .catch((error) => {
//           console.error(error);
//           reject(error);
//         });
//     } catch (error) {
//       console.error(error);
//       reject(error);
//     }
//   });
// }
