import React from "react";
import { DialogContent, Dialog } from "@mui/material";
function RequestPropertyPopup(props) {
  const {
    children,
    openRequestPropertySignupPopup,
    setOpenRequestPropertySignupPopup,
  } = props;
  function handleClose() {
    setOpenRequestPropertySignupPopup(false);
  }
  return (
    <Dialog open={openRequestPropertySignupPopup} onClose={handleClose}>
      <DialogContent
        sx={{
          padding: "9px",
          paddingBottom: "1.2rem",
          background: "#F6EDED",
          boxShadow:
            "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default RequestPropertyPopup;
