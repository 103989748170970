import * as React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import parse from "html-react-parser";
import { getDate } from "../../utils/get_datetime";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function BlogCard({
  blog,
  setOpenDeleteDialog,
  setSelectedId,
  setBlogName,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            N
          </Avatar>
        }
        action={
          <IconButton
            aria-label="settings"
            onClick={() => {
              setBlogName(blog.title);
              setSelectedId(blog.blog_id);
              setOpenDeleteDialog(true);
            }}
            sx={{
              transition: "all 0.7s ease-in-out",
              "&:hover": {
                backgroundColor: "#f5424e",
                color: "white",
              },
            }}
          >
            <DeleteSweepIcon />
          </IconButton>
        }
        title={blog?.title}
        subheader={getDate(blog?.createdAt)}
      />
      <CardMedia
        component="img"
        height="194"
        sx={{  minWidth: "320px", width: "100%" }}
        image={`${blog?.image}`}
        alt={blog?.title}
      />
      <CardContent>
        <Typography variant="h5" color="text.secondary">
          {blog?.title}
        </Typography>
        <div>{parse(blog?.body.slice(0, 100))}</div>
      </CardContent>
      <CardActions disableSpacing>
        <Link
          to={`/https:www.nres.com.np/blog/${blog?.blog_id}`}
          target="_blank"
        >
          <IconButton aria-label="share">
            <OpenInNewIcon />
          </IconButton>
        </Link>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <div>{parse(blog?.body)}</div>
        </CardContent>
      </Collapse>
    </Card>
  );
}
