import React, { useState } from "react";
import { Box, Button, TextField } from "@mui/material";

import { addVideoCarouselAsync } from "../../redux/adsSlice";
import { useDispatch, useSelector } from "react-redux";

function AddVideoCarousel() {
  const dispatch = useDispatch();
  const [videoLink, setVideoLink] = useState("");

  const isAddVideoCarouselLoading = useSelector(
    (state) => state.ads.addVideoCarouselAsync
  );

  const handleAddCarousel = (e) => {
    e.preventDefault();

    if (videoLink.trim() === "") return;

    dispatch(addVideoCarouselAsync(videoLink)).then((action) => {
      if (addVideoCarouselAsync.fulfilled.match(action)) {
        setVideoLink("");
      }
    });
  };

  return (
    <Box>
      <form onSubmit={handleAddCarousel}>
        <TextField
          sx={{ marginTop: "1rem" }}
          label="Video Link"
          variant="standard"
          fullWidth
          value={videoLink}
          autoComplete="off"
          onChange={(e) => setVideoLink(e.target.value)}
        />
        <Button
          type="submit"
          variant="contained"
          size="small"
          sx={{
            mt: "1rem",
            backgroundColor: "black",
            ":hover": {
              backgroundColor: "#1c1c1c",
            },
          }}
          disabled={isAddVideoCarouselLoading}
        >
          {isAddVideoCarouselLoading ? "Adding..." : "Add Video"}{" "}
        </Button>
      </form>
    </Box>
  );
}

export default AddVideoCarousel;
