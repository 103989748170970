import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Dialog, DialogContent, Slide } from "@mui/material";

import {
  fetchVideoCarouselListAsync,
  deleteVideoCarouselByIdAsync,
} from "../../redux/adsSlice";

import VideoCarouselCard from "./video_carousel_card";
import DeleteCarouselPopup from "./delete_carousel_popup";
import ShowLoaderError from "../show_loader_and_error/ShowLoaderError";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function VideoCarouselListSection() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const videoCarouselList = useSelector((state) => state.ads.videoCarouselList);

  const isFetchVideoCarouselLoading = useSelector(
    (state) => state.ads.isFetchVideoCarouselLoading
  );
  const videoCarouselFetchError = useSelector(
    (state) => state.ads.videoCarouselFetchError
  );

  useEffect(() => {
    dispatch(fetchVideoCarouselListAsync());
  }, [dispatch]);

  const handleDeleteClick = (e) => {
    e.preventDefault();
    dispatch(deleteVideoCarouselByIdAsync(selectedId)).then((action) => {
      if (deleteVideoCarouselByIdAsync.fulfilled.match(action)) {
        setOpenDeleteDialog(false);
      }
    });
  };
  return (
    <div>
      {isFetchVideoCarouselLoading || videoCarouselFetchError ? (
        <ShowLoaderError
          isLoading={isFetchVideoCarouselLoading}
          errorMessage={videoCarouselFetchError}
        />
      ) : (
        <Grid container spacing={3}>
          {videoCarouselList?.map((item) => (
            <Grid item key={item.id}>
              <VideoCarouselCard
                item={item}
                setSelectedId={setSelectedId}
                setOpenDeleteDialog={setOpenDeleteDialog}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        TransitionComponent={Transition}
        maxWidth="xs"
      >
        {" "}
        <DialogContent
          sx={{
            padding: "2rem",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteCarouselPopup
            handleDeleteClick={handleDeleteClick}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default VideoCarouselListSection;
