import React from "react";
import AddNews from "./add_blog_popup";
import "./blog.css";
import { Dialog, DialogContent, Button, Slide, Box } from "@mui/material";
import BlogsList from "./blogs_list";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AdminDashboardBlog() {
  const [openAddNews, setOpenAddNews] = React.useState(false);

  return (
    <div>
      <Button
        onClick={() => setOpenAddNews(true)}
        sx={{
          backgroundColor: "#000",
          color: "white",
          marginRight: "1rem",
          padding: "0.5rem 1.2rem",
          transition: "all 0.5s ease",
          letterSpacing: "1.2px",
          "&:hover": {
            backgroundColor: "#383838",
          },
        }}
      >
        Add News/Blogs
      </Button>

      <Box sx={{ marginTop: "5rem" }}>
        <BlogsList />
      </Box>

      <Dialog
        open={openAddNews}
        onClose={() => setOpenAddNews(false)}
        TransitionComponent={Transition}
        maxWidth="md"
      >
        {" "}
        <DialogContent
          sx={{
            padding: "2rem",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AddNews setOpenAddNews={setOpenAddNews} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AdminDashboardBlog;
