import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  Box,
  Typography,
  Collapse,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { getDate } from "src/utils/get_datetime";
const ExpandMore = styled((props) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RequestMoreInfoCard({
  item,
  setSelectedId,
  setOpenDeleteDialog,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const openDeleteDialog = () => {
    setSelectedId(item.id);
    setOpenDeleteDialog(true);
  };

  return (
    <Card
      sx={{
        fullWidth: true,
        width: "100%",
        maxWidth: "lg",
        padding: { xs: "0.6rem", sm: "0.8rem" },
        boxShadow: "-1px 2px 5px #fff",
        borderRadius: "5px",
        backgroundColor: "primary.subColor",
        color: "white",
        border: "1px solid gray",
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          color: "white",
          padding: "0",
          position: "absolute",
          right: "0",
          top: "0",
        }}
        onClick={openDeleteDialog}
      >
        <DeleteIcon
          sx={{
            color: "red",
            transition: "all 0.3s ease-in-out",
            ":hover": {
              transform: "scale(1.09)",
            },
          }}
        />
      </IconButton>

      <Typography
        sx={{
          fontSize: "1rem",
          color: "black",
          textTransform: "capitalize",
        }}
      >
        <strong>Property Title : </strong> {item?.name}
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          color: "black",
          textTransform: "capitalize",
        }}
      >
        <strong>Property Id : </strong> {item?.property_id}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // border: "1px solid yellow",
          color: "black",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "0.85rem",
            flexGrow: "1",
            textTransform: "capitalize",
          }}
        >
          <strong>Property Type : </strong> {item?.property_type}
        </Typography>
        <Box
          sx={{
            width: "26%",
            float: "right",
          }}
        >
          <Typography
            sx={{
              fontSize: "0.85rem",
            }}
          >
            <strong>Contact: </strong>
            {item?.phone_number}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.85rem",
            }}
          >
            <strong>Email : </strong> {item?.email}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.85rem",
            }}
          >
            <strong>Date : </strong> {getDate(item?.createdAt)}
          </Typography>
        </Box>
      </Box>
      <CardContent sx={{ padding: "0.2rem", mt: "1rem" }}>
        <Typography variant="body2" sx={{ color: "black", fontSize: "14px" }}>
          {/* {item?.testimonial.slice(0, 150)} */}
          <strong>Description : </strong>
          <br />
          {item?.description?.slice(0, 150)}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ padding: "0" }}>
        <ExpandMore
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          {/* <ExpandMoreIcon sx={{ fill: "white" }} /> */}
          <Typography
            sx={{
              fontSize: { xs: "10px", sm: "13px" },
              color: "black",
            }}
          >
            {expanded ? "See Less" : "See More"}
          </Typography>
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            sx={{
              fontSize: "14px",
              color: "black",
            }}
          >
            {/* {item?.testimonial} */}
            {item?.description}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
