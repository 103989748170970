import React, { useState } from "react";
import {
  Box,
  Typography,
  DialogTitle,
  DialogContentText,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TikTokIcon from "../ui/tiktok_icon";
function SocialMediaIcons({ socialMediaLinks, setSocialMediaLinks }) {
  const [clickedMedia, setClickedMedia] = useState("");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: "700", fontSize: "0.875rem" }}
        >
          Social Media:
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
          <IconButton
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              color: socialMediaLinks?.facebook ? "" : "#000",
            }}
            onClick={() => {
              setClickedMedia("facebook");
              handleClickOpen();
            }}
          >
            <FacebookIcon style={{ fontSize: "1.3rem" }} />
          </IconButton>
          <IconButton
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              color: socialMediaLinks?.youtube ? "" : "#000",
            }}
            onClick={() => {
              setClickedMedia("youtube");
              handleClickOpen();
            }}
          >
            <YouTubeIcon style={{ fontSize: "1.3rem" }} />
          </IconButton>
          <IconButton
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              color: socialMediaLinks?.instagram ? "" : "#000",
            }}
            onClick={() => {
              setClickedMedia("instagram");
              handleClickOpen();
            }}
          >
            <InstagramIcon style={{ fontSize: "1.3rem" }} />
          </IconButton>
          <IconButton
            sx={{
              width: "1.5rem",
              height: "1.5rem",
              color: socialMediaLinks?.twitter ? "" : "#000",
            }}
            onClick={() => {
              setClickedMedia("twitter");
              handleClickOpen();
            }}
          >
            <TwitterIcon style={{ fontSize: "1.3rem" }} />
          </IconButton>
          <IconButton
            sx={{
              color: "#fff",
              width: "auto",
              height: "auto",
              padding: "0px",
            }}
            onClick={() => {
              setClickedMedia("tiktok");
              handleClickOpen();
            }}
          >
            <TikTokIcon color={socialMediaLinks?.tiktok ? "#aaa" : "#000"} />
          </IconButton>
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Provide Link</DialogTitle>
        <DialogContent>
          <DialogContentText>{`Provide the link of ${clickedMedia}`}</DialogContentText>
          {clickedMedia === "youtube" && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Youtube Link"
              type="email"
              fullWidth
              variant="standard"
              value={socialMediaLinks?.youtube || ""}
              onChange={(e) =>
                setSocialMediaLinks({
                  ...socialMediaLinks,
                  youtube: e.target.value,
                })
              }
            />
          )}
          {clickedMedia === "instagram" && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Instagram Link"
              type="email"
              fullWidth
              variant="standard"
              value={socialMediaLinks?.instagram || ""}
              onChange={(e) =>
                setSocialMediaLinks({
                  ...socialMediaLinks,
                  instagram: e.target.value,
                })
              }
            />
          )}
          {clickedMedia === "twitter" && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Twitter Link"
              type="email"
              fullWidth
              variant="standard"
              value={socialMediaLinks?.twitter || ""}
              onChange={(e) =>
                setSocialMediaLinks({
                  ...socialMediaLinks,
                  twitter: e.target.value,
                })
              }
            />
          )}
          {clickedMedia === "facebook" && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Facebook Link"
              type="email"
              fullWidth
              variant="standard"
              value={socialMediaLinks?.facebook || ""}
              onChange={(e) =>
                setSocialMediaLinks({
                  ...socialMediaLinks,
                  facebook: e.target.value,
                })
              }
            />
          )}
          {clickedMedia === "tiktok" && (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Tiktok Link"
              type="email"
              fullWidth
              variant="standard"
              value={socialMediaLinks?.tiktok || ""}
              onChange={(e) =>
                setSocialMediaLinks({
                  ...socialMediaLinks,
                  tiktok: e.target.value,
                })
              }
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default SocialMediaIcons;
