import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";
import {
  approveCustomerProperty,
  fetchRecentProperty,
  fetchUnApprovedPropertyList,
} from "../../../redux/overviewSlice";

function ApproveCustomerPropertyPopup({
  selectedId,
  setOpenApproveDialog,
  setselectedId,
  selectedIdName,
}) {
  const dispatch = useDispatch();
  const handleApproveCustomer = () => {
    dispatch(
      approveCustomerProperty({
        property_id: selectedId,
        endPoint: selectedIdName,
      })
    ).then((action) => {
      if (approveCustomerProperty.fulfilled.match(action)) {
        setselectedId(null);
        setOpenApproveDialog(false);
        dispatch(fetchUnApprovedPropertyList());
        dispatch(fetchRecentProperty());
      }
    });
  };

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Do you want to approve property? id = {selectedId}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={handleApproveCustomer}
        >
          Yes
        </Button>
        <Button
          sx={{
            backgroundColor: "rgb(44, 70, 242)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(42, 65, 219)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={() => setOpenApproveDialog(false)}
        >
          No
        </Button>
      </Box>
    </Box>
  );
}

export default ApproveCustomerPropertyPopup;
