import React from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, Button } from "@mui/material";

import {
  fetchStaffList,
  giveAdminAccessToStaff,
  takeAdminAccessToStaff,
} from "../../redux/staffMgmtSlice";

function AdminAcessPopup({ staffId, setOpenAdminAcessDialog }) {
  const dispatch = useDispatch();

  const giveAdminAccess = (e) => {
    e.preventDefault();
    dispatch(giveAdminAccessToStaff({ staffId })).then((action) => {
      if (giveAdminAccessToStaff.fulfilled.match(action)) {
        setOpenAdminAcessDialog(false);
        dispatch(fetchStaffList());
      }
    });
  };
  const takeAdminAccess = (e) => {
    e.preventDefault();
    dispatch(takeAdminAccessToStaff({ staffId })).then((action) => {
      if (takeAdminAccessToStaff.fulfilled.match(action)) {
        setOpenAdminAcessDialog(false);
        dispatch(fetchStaffList());
      }
    });
  };

  return (
    <Box sx={{ minWidth: "14rem" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontWeight: "bold" }}>
          Do you want to Change Admin Access?
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          marginTop: "1rem",
        }}
      >
        <Button
          sx={{
            backgroundColor: "rgb(242, 44, 93)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(209, 36, 79)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={giveAdminAccess}
        >
          Give
        </Button>
        <Button
          sx={{
            backgroundColor: "rgb(44, 70, 242)",
            color: "white",
            ":hover": {
              transform: "scale(1.05)",
              backgroundColor: "rgb(42, 65, 219)",
              transition: "all 0.2s ease-in-out",
            },
          }}
          onClick={takeAdminAccess}
        >
          Take
        </Button>
      </Box>
    </Box>
  );
}

export default AdminAcessPopup;
