import React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchRequestList, requestProperty } from "../../redux/overviewSlice";

function RequestedByPopup({ requestData, setOpen }) {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [contact, setContact] = React.useState("");

  const isLoading = useSelector((state) => state.overview.isLoading);
  const handleRequest = (e) => {
    e.preventDefault();
    const data = {
      ...requestData,
      name: firstName,
      email,
      address,
      phone_number: contact,
    };
    dispatch(requestProperty({ data })).then((action) => {
      if (requestProperty.fulfilled.match(action)) {
        dispatch(fetchRequestList());
        setOpen(false);
      }
    });
  };
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            color: "black",
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "bold",
          }}
          variant="body1"
        >
          Requested By
        </Typography>
      </Box>
      <hr />
      <Box sx={{ mt: "2rem", mb: "1rem", px: "2rem" }}>
        <form onSubmit={handleRequest}>
          <Box
            sx={{
              mx: "auto",
              display: "flex",
              flexDirection: "column",
              gap: "2rem",
            }}
          >
            <TextField
              type="text"
              label="First Name"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="Address"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
            <TextField
              
              label="Contact"
              variant="outlined"
              fullWidth
              required
              size="small"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "black",
                ":hover": {
                  backgroundColor: "#121212",
                },
              }}
              type="submit"
            >
              {isLoading ? "Submitting" : "Submit"}
            </Button>
          </Box>
        </form>
      </Box>
    </div>
  );
}

export default RequestedByPopup;
