import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";

import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import SearchIcon from "@mui/icons-material/Search";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CloseIcon from "@mui/icons-material/Close";

import DeleteServicesPopup from "./delete_services_popup";
import VerifyServicePopup from "./verify_service_popup";

// ----------------------------------------------------------------------

export default function UserTableToolbar({
  selectedId,
  selectedIdName,
  serviceStatus,
  setServiceStatus,
  searchQuery,
  setSearchQuery,
}) {
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openVerifyDialog, setOpenVerifyDialog] = React.useState(false);
  const [serviceInfoAnchorEl, setServiceInfoAnchorEl] = React.useState(null);
  const openServiceInfoPopup = Boolean(serviceInfoAnchorEl);

  const serviceInfoPopupId = openServiceInfoPopup
    ? "simple-popover"
    : undefined;

  const openServiceInfoPopover = (e) => {
    setServiceInfoAnchorEl(e.currentTarget);
  };

  return (
    <Box>
      <Toolbar
        sx={{
          height: 96,
          display: "flex",
          justifyContent: "space-between",
          p: (theme) => theme.spacing(0, 1, 0, 3),
          ...(selectedId && {
            color: "primary.main",
            bgcolor: "primary.lighter",
          }),
        }}
      >
        {selectedId ? (
          <Typography component="div" variant="subtitle1">
            {selectedId} selected
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
          >
            Services List
          </Typography>
        )}

        {selectedId ? (
          <Box>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setOpenDeleteDialog(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Verify Service">
              <IconButton
                onClick={() => {
                  setOpenVerifyDialog(true);
                }}
              >
                <VerifiedUserIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="View Info">
              <IconButton onClick={openServiceInfoPopover}>
                <InfoRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : (
          <OutlinedInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            placeholder="Search..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        )}
      </Toolbar>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteServicesPopup
            selectedId={selectedId}
            selectedIdName={selectedIdName}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openVerifyDialog}
        onClose={() => {
          setOpenVerifyDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogActions
          sx={{
            padding: "0",
            background: "#F6EDED",
          }}
        >
          <IconButton onClick={() => setOpenVerifyDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <DialogContent
          sx={{
            padding: "0px 24px 24px 24px",
            background: "#F6EDED",
          }}
        >
          <VerifyServicePopup
            selectedId={selectedId}
            serviceStatus={serviceStatus}
            selectedIdName={selectedIdName}
            setOpenVerifyDialog={setOpenVerifyDialog}
            setServiceStatus={setServiceStatus}
          />
        </DialogContent>
      </Dialog>{" "}
      <Popover
        id={serviceInfoPopupId}
        open={openServiceInfoPopup}
        anchorEl={serviceInfoAnchorEl}
        onClose={() => {
          setServiceInfoAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <Comment selectedId={selectedId} selectedIdName={selectedIdName} /> */}
      </Popover>
    </Box>
  );
}
