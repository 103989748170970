import React from "react";
import { Typography } from "@mui/material";
import HomeLoanList from "./home_loan_list";
// import HomeLoanRequestList from "./home_loan_request_list";

function HomeLoanMain() {
  return (
    <div>
      <Typography variant="h4">Home Loan</Typography>
      <HomeLoanList />
    </div>
  );
}

export default HomeLoanMain;
