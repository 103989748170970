import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Typography,
  Button,
  Box,
  Grid,
  CircularProgress,
  Dialog,
  DialogContent,
} from "@mui/material";
import {
  fetchRecentPropertyDetailsById,
  fetchSoldPropertyDetailsById,
  fetchUnApprovedPropertyDetailsById,
} from "../../../redux/overviewSlice";

// import { splitLocation } from "../../../utils/split_location";

import NoImage from "../../../assets/images/NoImage.jpg";

import ShowImageDialog from "../../image_dialog/show_image_dialog";
import { formatPrice } from "../../../utils/formatPrice";
import AmenitiesDetails from "./amenites_details";
import SocialMediaDetails from "./social_media_details";

const PropertyDetailsHome = ({ selectedId, from }) => {
  const dispatch = useDispatch();
  const [showBasicDetails, setShowBasicDetails] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);
  const isLoading = useSelector((state) => state.overview.isLoading);
  const toggleDetails = () => {
    setShowBasicDetails((prev) => !prev);
  };
  const propertyDetails = useSelector(
    (state) => state.overview.propertyDetails
  );
  useEffect(() => {
    if (from === "recent") {
      dispatch(
        fetchRecentPropertyDetailsById({
          property_id: selectedId,
          endPoint: "house",
        })
      );
    } else if (from === "sold") {
      dispatch(
        fetchSoldPropertyDetailsById({
          property_id: selectedId,
          endPoint: "house",
        })
      );
    } else if (from === "unapprove") {
      dispatch(
        fetchUnApprovedPropertyDetailsById({
          property_id: selectedId,
          endPoint: "house",
        })
      );
    }
  }, [dispatch, selectedId, from]);

  let imagesLink = "";

  if (typeof propertyDetails?.property_image === "string") {
    imagesLink = propertyDetails.property_image
      ? Object.values(JSON.parse(propertyDetails.property_image))
      : [];
  } else {
    imagesLink = propertyDetails?.property_image
      ? Object.values(propertyDetails.property_image)
      : [];
  }

  const handleOpenModal = (index) => {
    setModalImageIndex(index);
    setModalOpen(true);
  };

  return (
    <Paper
      sx={{
        padding: "16px",
        minWidth: "22rem",
        width: "100%",
        maxWidth: "25rem",
        maxHeight: "25rem",
        height: "100%",
        overflow: "auto",
      }}
    >
      {isLoading ? (
        <Box>
          <CircularProgress />
          <Typography variant="body2">Loading...</Typography>
        </Box>
      ) : propertyDetails ? (
        showBasicDetails ? (
          <>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Property Id : </strong>
              {propertyDetails.property_id}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Owner Name : </strong>
              {propertyDetails?.owner_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Owner Phone : </strong>
              {propertyDetails?.owner_phone}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Property Title : </strong>
              {propertyDetails?.property_name}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Property Type : </strong>
              {propertyDetails?.property_type}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Built up area : </strong>
              {propertyDetails?.built_up_area} sq.ft
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Property For : </strong> {propertyDetails?.property_for}
            </Typography>

            {/* Render other basic details */}
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Listed for : </strong>
              {propertyDetails?.listed_for}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Location : </strong>
              {propertyDetails?.province}, {propertyDetails?.district},
              {propertyDetails?.municipality}, {propertyDetails?.ward}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Facing : </strong>
              {propertyDetails?.facing}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Area Name : </strong>
              {propertyDetails?.area_name}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>LandMark : </strong>
              {propertyDetails?.landmark}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Price : </strong>
              Rs {formatPrice(propertyDetails?.price)}{" "}
              {propertyDetails?.price_per}
            </Typography>

            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Price Type : </strong>
              {propertyDetails?.price_type}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Property Area : </strong>
              {propertyDetails?.property_area} Aana
            </Typography>

            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Images : </strong>
            </Typography>
            <Box>
              {!imagesLink || imagesLink.length === 0 ? (
                <Box
                  src=""
                  alt="No Image"
                  className="main__image__fixed--height"
                >
                  <img
                    src={NoImage}
                    alt="Could not load"
                    style={{ width: "60px", height: "60px" }}
                  />
                </Box>
              ) : (
                <Grid container spacing={1}>
                  {imagesLink?.map((image, index) => (
                    <Grid
                      item
                      onClick={() => handleOpenModal(index)}
                      key={index}
                    >
                      <img
                        src={`${image}`}
                        alt="property"
                        style={{ width: "60px", height: "60px" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Box>
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Total Floor : </strong>
              {propertyDetails.floor}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Bedrooms : </strong>
              {propertyDetails.bedroom}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Kitchen : </strong>
              {propertyDetails.kitchen}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Bathrooms Attached : </strong>
              {propertyDetails.bathroom}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Living Room : </strong>
              {propertyDetails.living_room}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Store : </strong>
              {propertyDetails.store}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Servant Quarter : </strong>
              {propertyDetails.servant_quater}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Pooja Room : </strong>
              {propertyDetails.pooja_room}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Extra Room : </strong>
              {propertyDetails.extra_room}
            </Typography>
            <Typography
              variant="body2"
              sx={{ mb: "0.1rem", textTransform: "capitalize" }}
            >
              <strong>Furnished : </strong>
              {propertyDetails?.furnish}
            </Typography>

            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Road Access : </strong>
              {propertyDetails.road_access} feet
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Pillar : </strong>
              {propertyDetails.pillar}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Phase Line : </strong>
              {propertyDetails.phase_line}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Material Used : </strong>
              {propertyDetails.material_used}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Bike Parking : </strong>
              {propertyDetails.parking_bike}
            </Typography>
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Car Parking : </strong>
              {propertyDetails.parking_car}
            </Typography>
            {/* Ameneties */}
            <SocialMediaDetails
              socialMediaDetails={propertyDetails.social_media}
            />
            <Typography variant="body2" sx={{ mb: "0.4rem" }}>
              <strong>Views : </strong>
              {propertyDetails.views}
            </Typography>
            <AmenitiesDetails propertyAmenities={propertyDetails.amenities} />
            <Typography variant="body2" sx={{ mb: "0.1rem" }}>
              <strong>Description : </strong>
              {propertyDetails?.description}
            </Typography>
          </>
        )
      ) : (
        <h2> Cannot find data</h2>
      )}
      <Box mt={2}>
        <Button variant="contained" onClick={toggleDetails}>
          {showBasicDetails ? "View More" : "View Less"}
        </Button>
      </Box>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent
          sx={{
            overflow: "hidden",
            scrollbarWidth: "none",
            padding: "0",
            position: "relative",
            width: "100%",
          }}
        >
          <ShowImageDialog
            setModalOpen={setModalOpen}
            imagesLink={imagesLink}
            modalImageIndex={modalImageIndex}
            setModalImageIndex={setModalImageIndex}
          />
        </DialogContent>
      </Dialog>
    </Paper>
  );
};

export default PropertyDetailsHome;
