import React, { useState } from "react";

import {
  Box,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { toast } from "react-toastify";

import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

import Map from "../map/choose_map_location";

import { compressImage } from "../../utils/image_compressor";

import ShowImageDialog from "../image_dialog/show_image_dialog";
import { useDispatch } from "react-redux";
import { deletePropertyImage } from "../../redux/overviewSlice";

function PropertyMapImages({
  setChangedData,
  changedData,
  setPropertyImages,
  propertyImages,
}) {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImageIndex, setModalImageIndex] = useState(0);

  const handleImageChange = async (event) => {
    const files = event.target.files;

    try {
      const compressedFiles = await toast.promise(
        Promise.all(
          [...files].map(async (file) => {
            const compressedFile = await compressImage(file);
            return compressedFile;
          })
        ),
        {
          pending: "Compressing images. Please wait...",
          success: "Compression complete!",
          error: "Error compressing images. Please try again.",
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        }
      );

      if (compressedFiles) {
        setChangedData({
          ...changedData,
          gallery: [...compressedFiles, ...(changedData?.gallery || [])],
        });
      }
    } catch (error) {
      console.error("Error compressing images:", error);
    }
  };

  const handleGalleryRemove = (e) => {
    setChangedData({
      ...changedData,
      gallery: [],
    });
  };

  const handleOpenModal = (index) => {
    setModalImageIndex(index);
    setModalOpen(true);
  };

  const handleImageDelete = () => {
    const { property_id, property_type } = propertyImages;
    const imageLink = propertyImages.property_images[modalImageIndex];

    dispatch(
      deletePropertyImage({ imageLink, property_id, property_type })
    ).then((res) => {
      if (deletePropertyImage.fulfilled.match(res)) {
        // Filter images after delete success
        const filteredImages = imagesLink.filter(
          (_, index) => index !== modalImageIndex
        );
        setPropertyImages({
          ...propertyImages,
          property_images: JSON.stringify(filteredImages),
        });
        setModalOpen(false); // C
      }
    });
  };

  let imagesLink = "";

  if (typeof propertyImages?.property_images === "string") {
    imagesLink = propertyImages?.property_images
      ? Object.values(JSON.parse(propertyImages?.property_images))
      : [];
  } else {
    imagesLink = propertyImages?.property_images
      ? Object.values(propertyImages?.property_images)
      : [];
  }

  return (
    <>
      <Grid item xs={6}>
        <Typography>Gallery</Typography>
        <input
          type="file"
          id="imageInput"
          multiple
          accept=" .jpg, .jpeg"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label
          htmlFor="imageInput"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "1px solid #ccc",

            borderRadius: "0.25rem",
            flexDirection: "column",
            width: "fit-content",
            padding: "0.5rem",
          }}
        >
          <Typography variant="body2" sx={{ fontSize: "0.75rem" }}>
            Upload Photo
          </Typography>
          <span>
            <PhotoLibraryIcon
              sx={{ color: "black", opacity: "0.5", fontSize: "2rem" }}
            />
          </span>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "2px 4px",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontSize: "0.65rem" }}>
              {changedData?.gallery?.length || "0"} photos <br /> uploaded
            </Typography>
            <IconButton onClick={handleGalleryRemove}>
              <DeleteSweepIcon sx={{ color: "#e89eab" }} />
            </IconButton>
          </Box>
        </label>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            overflowX: "auto",
            maxWidth: "13rem",
            border: "1px solid #333",
          }}
        >
          {imagesLink.map((image, index) => (
            <Box onClick={() => handleOpenModal(index)} key={index}>
              <img
                src={image}
                alt="edit"
                style={{ marginRight: "8px", width: "60px", height: "60px" }}
              />
            </Box>
          ))}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Map />
      </Grid>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <DialogContent
          sx={{
            overflow: "hidden",
            scrollbarWidth: "none",
            padding: "0",
            position: "relative",
            width: "100%",
          }}
        >
          <ShowImageDialog
            setModalOpen={setModalOpen}
            imagesLink={imagesLink}
            modalImageIndex={modalImageIndex}
            setModalImageIndex={setModalImageIndex}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleImageDelete}
            sx={{
              backgroundColor: "red",
              color: "white",
              "&:hover": {
                backgroundColor: "rgb(220,0,0)",
              },
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PropertyMapImages;
