import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

import { addBlogAsync, fetchBlogAsync } from "../../redux/blogSlice";

import { compressImage } from "../../utils/image_compressor";

import CancelIcon from "@mui/icons-material/Cancel";
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ["right", "center", "justify"] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: ["red", "#785412"] }],
    [{ background: ["red", "#785412"] }],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "background",
  "align",
  "size",
  "font",
];

function AddBlog({ setOpenAddNews }) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [renderImage, setRenderImage] = useState("");
  const [description, setDescription] = useState("Enter Description...");
  const isLoading = useSelector((state) => state.blog.isLoading);
  const handleProcedureContentChange = (content) => {
    setDescription(content);
  };
  function handleAddBlog() {
    const formData = new FormData();
    const data = { title, body: description };
    formData.append("image", imageUrl);
    formData.append("blog", JSON.stringify(data));

    dispatch(addBlogAsync(formData)).then((action) => {
      if (addBlogAsync.fulfilled.match(action)) {
        setOpenAddNews(false);
        dispatch(fetchBlogAsync());
      }
    });
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const compressedFile = await toast.promise(compressImage(file), {
        pending: "Compressing image. Please wait...",
        success: "Compression complete!",
        error: "Error compressing image. Please try again.",
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      if (compressedFile) {
        setImageUrl(compressedFile);
        setRenderImage(URL.createObjectURL(compressedFile));
      }
    } catch (error) {
      console.error("Error compressing images:", error);
    }
  };
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={handleAddBlog}
          sx={{
            backgroundColor: "#000",
            color: "white",
            marginRight: "1rem",
            padding: "0.3rem 1.2rem",
            transition: "all 0.5s ease",
            letterSpacing: "1.2px",
            "&:hover": {
              backgroundColor: "#141414",
              fontWeight: "bold",
            },
          }}
          disabled={isLoading}
        >
          {isLoading ? "Posting..." : "Post"}
        </Button>
        <Button
          onClick={() => setOpenAddNews(false)}
          sx={{
            backgroundColor: "#a61c2c",
            color: "white",
            padding: "0.3rem 1.2rem",
            transition: "all 0.5s ease",

            "&:hover": {
              backgroundColor: "#941826",
              fontWeight: "bold",
            },
          }}
        >
          Cancel
        </Button>
      </Box>
      <Box>
        <Typography variant="h4" textAlign={"center"}>
          Post Blogs
        </Typography>
        <hr />

        <Box sx={{ marginTop: "2rem" }}>
          <Box sx={{ marginBottom: "1rem" }}>
            <textarea
              type="text"
              placeholder="Enter Title..."
              className="blog_title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
          </Box>
          <Box
            sx={{ height: "50vh", overflow: "scroll", position: "relative" }}
          >
            <ReactQuill
              theme="snow"
              modules={modules}
              formats={formats}
              value={description}
              onChange={handleProcedureContentChange}
              className="sticky-toolbar"
            />
            {isLoading && (
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>

          <Box sx={{ mt: "1rem" }}>
            <input
              type="file"
              id="imageInput1"
              multiple
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
            <label
              htmlFor="imageInput1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                width: "fit-content",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "0.85rem",
                  padding: "0.5rem",
                  backgroundColor: "#0A6AB6",
                  borderRadius: "0.25rem",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                Upload Photo
              </Typography>

              <IconButton onClick={() => setImageUrl("")}>
                <CancelIcon />
              </IconButton>
            </label>
          </Box>
          {renderImage && (
            <img
              src={renderImage}
              alt="blog"
              style={{ width: "100px", height: "80px" }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AddBlog;
