import React, { useState } from "react";
import {
  Box,
  TextField,
  MenuItem,
  Grid,
  Typography,
  DialogContent,
  Dialog,
} from "@mui/material";
import { useSelector } from "react-redux";
import RequestedByPopup from "./requested_by_popup";
function RequestPropertyHome() {
  const [type, setType] = React.useState("");
  const [totalFloor, setTotalFloor] = React.useState("");
  const [propertyArea, setPropertyArea] = React.useState("");
  const [bedrooms, setBedrooms] = React.useState("");
  const [kitchen, setKitchen] = React.useState("");
  const [listedFor, setListedFor] = React.useState("");
  const [facing, setFacing] = React.useState("");
  const [roadSize, setRoadSize] = React.useState("");
  const [livingRoom, setLivingRoom] = React.useState("");
  const [furnishing, setFurnishing] = React.useState("");
  const [minPrice, setMinPrice] = React.useState("");
  const [maxPrice, setMaxPrice] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [needed, setNeeded] = React.useState("");
  const [requestData, setRequestData] = useState({});
  const [open, setOpen] = React.useState(false);
  const selectedProvince = useSelector(
    (state) => state.location.selectedProvince
  );
  const selectedDistrict = useSelector(
    (state) => state.location.selectedDistrict
  );
  const selectedMunicipality = useSelector(
    (state) => state.location.selectedMunicipality
  );
  const selectedWard = useSelector((state) => state.location.selectedWard);
  const selectedLandMark = useSelector(
    (state) => state.location.selectedLandMark
  );

  function openRequestedPopup() {
    const province = selectedProvince;
    const district = selectedDistrict;
    const municipality = selectedMunicipality;
    const ward = selectedWard;

    const otherDetails = {
      minPrice,
      maxPrice,
      road_size: roadSize,
      furnish: furnishing,
      property_area: propertyArea,
      floor: totalFloor,
      bedrooms,
      kitchen,
      living_rooms: livingRoom,
      facing,
      description,
      needed: needed,
    };
    const data = {
      property_type: "house",
      property_for: type,
      listed_for: listedFor,
      province: province,
      district: district,
      municipality: municipality,
      ward: ward,
      landmark: selectedLandMark,
    };
    const totalData = { ...data, otherDetails };
    setRequestData(totalData);
    setOpen(true);
  }
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TextField
            select
            label="Type"
            variant="outlined"
            sx={{ width: "100%", maxWidth: "13rem" }}
            size="small"
            value={type}
            onChange={(e) => setType(e.target.value)}
          >
            <MenuItem value="residential">Residential</MenuItem>
            <MenuItem value="Commercial">Commercial</MenuItem>
            <MenuItem value="Office">Office</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Total Floor"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={totalFloor}
            onChange={(e) => setTotalFloor(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Property Area(Aana)"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={propertyArea}
            onChange={(e) => setPropertyArea(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Bedrooms"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={bedrooms}
            onChange={(e) => setBedrooms(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Kitchen"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={kitchen}
            onChange={(e) => setKitchen(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            label="Listed for"
            variant="outlined"
            sx={{ width: "100%", maxWidth: "13rem" }}
            size="small"
            value={listedFor}
            onChange={(e) => setListedFor(e.target.value)}
          >
            <MenuItem value="sale">Sale</MenuItem>
            <MenuItem value="rent">Rent</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            select
            label="Facing"
            variant="outlined"
            sx={{ width: "100%", maxWidth: "13rem" }}
            size="small"
            value={facing}
            onChange={(e) => setFacing(e.target.value)}
          >
            <MenuItem value="north">North</MenuItem>
            <MenuItem value="east">East</MenuItem>
            <MenuItem value="west">West</MenuItem>
            <MenuItem value="south">South</MenuItem>
            <MenuItem value="north-east">North-East</MenuItem>
            <MenuItem value="north-west">North-West</MenuItem>
            <MenuItem value="south-east">South-East</MenuItem>
            <MenuItem value="south-west">South-West</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Road Size"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={roadSize}
            onChange={(e) => setRoadSize(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="Living Room"
            size="small"
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={livingRoom}
            onChange={(e) => setLivingRoom(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            label="Furnishing"
            variant="outlined"
            sx={{ width: "100%", maxWidth: "13rem" }}
            size="small"
            value={furnishing}
            onChange={(e) => setFurnishing(e.target.value)}
          >
            <MenuItem value="No">No</MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "13rem",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-around",
            }}
          >
            <Typography variant="subtitle1">Price:</Typography>
            <TextField
              type="number"
              label="Min"
              variant="standard"
              size="small"
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
            />
            <Typography variant="subtitle1">to:</Typography>

            <TextField
              label="Max"
              type="number"
              variant="standard"
              size="small"
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Description"
            multiline
            rows={2}
            sx={{ width: "100%", maxWidth: "13rem" }}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            label="Needed"
            variant="outlined"
            sx={{ width: "100%", maxWidth: "13rem" }}
            size="small"
            value={needed}
            onChange={(e) => setNeeded(e.target.value)}
          >
            <MenuItem value="urgent">Urgent</MenuItem>
            <MenuItem value="withinAMonth">Within a Month</MenuItem>
            <MenuItem value="within3Months">Within 3 Months</MenuItem>
            <MenuItem value="afterAYear">After a Year</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              width: "100%",
              maxWidth: "13rem",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            {/* <br /> */}
            <span
              style={{
                marginTop: "0.2rem",
              }}
            >
              <button
                style={{
                  padding: "0.5rem 1.5rem",
                  borderTopLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                  backgroundColor: "black",
                  color: "white",
                  cursor: "pointer",
                }}
                onClick={openRequestedPopup}
              >
                Request
              </button>
            </span>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogContent
          sx={{
            padding: "9px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <RequestedByPopup requestData={requestData} setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default RequestPropertyHome;
