//REACT IMPORTS
import { useEffect } from "react";
// LIBRARY IMPORTS
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { ToastContainer, Flip } from "react-toastify";

//PAGES IMPORT
import AdminDashboardPage from "./pages/admin_dashboard_pages/admin.dashboard.pages.jsx";
import LoginPage from "./pages/login/login.pages.jsx";

//LAYOUT IMPORT
import RootLayout from "./layout/RootLayout.jsx";

//CSS IMPORT
import "./App.css";
import "react-toastify/dist/ReactToastify.css";

//UTILS IMPORT
import PrivateRoutes from "./utils/private_route.jsx";
import { useDispatch } from "react-redux";
import { checkAuthAsync } from "./redux/authSlice.jsx";
const ROLES = {
  ADMIN: "superAdmin",
  STAFF: "staff",
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route
        element={<PrivateRoutes allowedRoles={[ROLES.ADMIN, ROLES.STAFF]} />}
      >
        <Route index element={<AdminDashboardPage />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
    </Route>
  )
);
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuthAsync());
  }, [dispatch]);

  return (
    <div className="App">
      <RouterProvider router={router} />
      <ToastContainer
        transition={Flip}
        position="top-right"
        autoClose={2000}
        limit={4}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
