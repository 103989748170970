import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TableRow,
  Typography,
  TablePagination,
  InputAdornment,
  Dialog,
  DialogContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { getComparator, stableSort } from "../../../utils/dashboard_table";
// import { searchStaff } from "../../redux/staffMgmtSlice";
import DeleteAgentPopup from "./delete_agent_popup";
import AgentDetailsDialog from "./agent_details_popup";
// import AddAgentDialog from "./add_agent_popup";
import VerifyAgentPopup from "./verify_agent_popup";
import { fetchAgentList } from "../../../redux/overviewSlice";

import EnhancedTableHead from "../../table/EnhancedTableHead";

const headCells = [
  {
    id: "c_id",

    disablePadding: true,
    label: "S_ID",
    sort: true,
  },
  {
    id: "full_name",
    disablePadding: false,
    label: "Full Name",
    sort: true,
  },
  {
    id: "location",

    disablePadding: false,
    label: "Address",
    sort: true,
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    sort: true,
  },
  {
    id: "contact",
    disablePadding: false,
    label: "Contact",
    sort: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    sort: true,
  },
];
function createData(c_id, full_name, location, email, contact, status) {
  return {
    c_id,
    full_name,
    location,
    email,
    contact,
    status,
  };
}

export default function AgentList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
  // const [openAddAgentDialog, setOpenAddAgentDialog] = React.useState(false);
  const [openVerifyDialog, setOpenVerifyDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selectedIdStatus, setSelectedIdStatus] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("c_id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchQuery, setSearchQuery] = React.useState("");
  const agentList = useSelector((state) => state.overview.agentList);
  const agentListLoading = useSelector(
    (state) => state.overview.agentListLoading
  );

  useEffect(() => {
    dispatch(fetchAgentList());
  }, [dispatch]);

  console.log("agent list", agentList);

  const rows = useMemo(() => {
    return agentList?.map((agent) => {
      return (
        createData(
          agent?.user_id,
          agent?.name,
          agent?.user_agentProfile?.district,
          agent?.email,
          agent?.phone_number,
          agent?.user_agentProfile?.status
        ) || []
      );
    });
  }, [agentList]);

  const handleSearch = () => {
    // dispatch(
    //   searchStaff({
    //     staffSearchQuery: searchQuery,
    //   })
    // );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (c_id, status) => {
    if (selectedId === null) {
      setSelectedId(c_id);
      setSelectedIdStatus(status);
    } else {
      setSelectedId(null);
      setSelectedIdStatus("");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {agentListLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : agentList?.length >= 1 ? (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1 1 100%",
                    display: "flex",
                    gap: "1.5rem",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                    id="Agent List"
                  >
                    Agent List
                  </Typography>
                  {/* <Button
                    variant="contained"
                    size="small"
                    endIcon={<AddIcon />}
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "0.75rem",
                    }}
                    onClick={() => setOpenAddAgentDialog(true)}
                  >
                    Add New Agent
                  </Button> */}
                </Box>

                {selectedId !== null ? (
                  <>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Agent Details">
                      <IconButton onClick={() => setOpenDetailsDialog(true)}>
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Verify Agent">
                      <IconButton onClick={() => setOpenVerifyDialog(true)}>
                        <VerifiedUserIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Box display={selectedId ? "none" : "block"}>
                      <TextField
                        sx={{ ml: "auto", mr: 1 }}
                        id="outlined-basic"
                        label="Search"
                        type="text"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSearch();
                          }
                        }}
                        // inputRef={(input) => input && input.focus()}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon onClick={() => handleSearch()} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </>
                )}
              </Toolbar>
            </Box>
            <TableContainer sx={{ background: "rgba(246, 237, 237, 0.60)" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows?.length}
                  headCells={headCells}
                />
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={() => handleClick(row.c_id, row.status)}
                        role="checkbox"
                        aria-checked={selectedId === row.c_id}
                        tabIndex={-1}
                        key={index}
                        selected={selectedId === row.c_id}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={selectedId === row.c_id}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">{row.full_name}</TableCell>
                        <TableCell align="left">{row.location}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.contact}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>No Agents</h1>
          </Box>
        )}
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteAgentPopup
            selectedId={selectedId}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDetailsDialog}
        onClose={() => {
          setOpenDetailsDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AgentDetailsDialog
            selectedId={selectedId}
            setOpenDetailsDialog={setOpenDetailsDialog}
          />
        </DialogContent>
      </Dialog>
      {/* <Dialog
        open={openAddAgentDialog}
        onClose={() => {
          setOpenAddAgentDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <AddAgentDialog />
        </DialogContent>
      </Dialog> */}
      <Dialog
        open={openVerifyDialog}
        onClose={() => setOpenVerifyDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <VerifyAgentPopup
            selectedId={selectedId}
            selectedIdStatus={selectedIdStatus}
            setSelectedIdStatus={setSelectedIdStatus}
            setOpenVerifyDialog={setOpenVerifyDialog}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
