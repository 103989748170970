import React from "react";
import { Box, Typography } from "@mui/material";

function AmenitiesDetails({ propertyAmenities }) {
  let amenities = {};

  if (typeof propertyAmenities === "string") {
    amenities = propertyAmenities ? JSON.parse(propertyAmenities) : {};
  } else {
    amenities = propertyAmenities ? propertyAmenities : {};
  }

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        border: "2px solid black",
        padding: "8px",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          mb: "0.1rem",
          position: "absolute",
          top: "-10px",
          left: "10px",
          backgroundColor: "white",
        }}
      >
        <strong>Ameneties</strong>
      </Typography>

      <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Hot Water : </strong>
          {amenities.hot_water ? "Yes" : "No"}
        </Typography>

        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Electricity Supply : </strong>
          {amenities.electricity ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Garden : </strong>
          {amenities.garden ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Well : </strong>
          {amenities.well ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Drainage : </strong>
          {amenities.drainage ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Garbage Disposal : </strong>
          {amenities.garbage_disposal ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Cable Connection : </strong>
          {amenities.cable_connection ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Internet : </strong>
          {amenities.internet ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Reserve Tank : </strong>
          {amenities.reserve_tank ? "Yes" : "No"}
        </Typography>
        <Typography variant="body2" sx={{ mb: "0.1rem" }}>
          <strong>Rental Income : </strong>
          {amenities.rental_income ? "Yes" : "No"}
        </Typography>
      </Box>
    </Box>
  );
}

export default AmenitiesDetails;
