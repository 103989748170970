import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
//MUI IMPORTS
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  TableRow,
  Typography,
  TablePagination,
  InputAdornment,
  Dialog,
  DialogContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { getComparator, stableSort } from "../../../utils/dashboard_table";
import DeleteCustomerPopup from "./delete_customer_popup";
import CustomerDetailDialog from "./overview_customer_list_visitor_details_popup";
import { fetchBuyerList, searchBuyer } from "../../../redux/overviewSlice";

import EnhancedTableHead from "../../table/EnhancedTableHead";

const headCells = [
  {
    id: "c_id",

    disablePadding: true,
    label: "B_ID",
    sort: true,
  },
  {
    id: "full_name",
    disablePadding: false,
    label: "Full Name",
    sort: true,
  },
  {
    id: "user_id",

    disablePadding: false,
    label: "User Id",
    sort: true,
  },
  {
    id: "email",
    disablePadding: false,
    label: "Email",
    sort: true,
  },
  {
    id: "contact",
    disablePadding: false,
    label: "Contact",
    sort: true,
  },
];
function createData(c_id, full_name, user_id, email, contact) {
  return {
    c_id,
    full_name,
    user_id,
    email,
    contact,
  };
}

export default function BuyerList() {
  const dispatch = useDispatch();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDetailsDialog, setOpenDetailsDialog] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("c_id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [searchQuery, setSearchQuery] = React.useState("");
  const buyerList = useSelector((state) => state.overview.buyerList);
  const customerLoading = useSelector(
    (state) => state.overview.customerLoading
  );

  useEffect(() => {
    dispatch(fetchBuyerList());
  }, [dispatch]);

  const rows = useMemo(() => {
    return buyerList?.map((customer) => {
      return (
        createData(
          customer.customer_id,
          customer.name,
          customer.user_id,
          customer.email,
          customer.phone_number
        ) || []
      );
    });
  }, [buyerList]);

  const handleSearch = () => {
    dispatch(searchBuyer(searchQuery));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (c_id) => {
    if (selectedId === null) {
      setSelectedId(c_id);
    } else {
      setSelectedId(null);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows]
  );

  return (
    <Box sx={{ width: "100%", boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
      <Paper sx={{ width: "100%" }}>
        {customerLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "20rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <EnhancedTableToolbar /> */}
              <Toolbar
                sx={{
                  pl: { sm: 2 },
                  pr: { xs: 1, sm: 1 },
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    flex: "1 1 100%",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                  id="Customer List"
                >
                  Buyer List
                </Typography>
                {selectedId !== null ? (
                  <>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => {
                          setOpenDeleteDialog(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Customer Details">
                      <IconButton onClick={() => setOpenDetailsDialog(true)}>
                        <InfoRoundedIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Box display={selectedId ? "none" : "block"}>
                    <TextField
                      sx={{ ml: "auto", mr: 1 }}
                      id="outlined-basic"
                      label="Search"
                      type="text"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSearch();
                        }
                      }}
                      // inputRef={(input) => input && input.focus()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon onClick={() => handleSearch()} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
              </Toolbar>
            </Box>
            {buyerList?.length >= 1 ? (
              <>
                <TableContainer
                  sx={{ background: "rgba(246, 237, 237, 0.60)" }}
                >
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={"medium"}
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows?.length}
                      headCells={headCells}
                    />
                    <TableBody>
                      {visibleRows?.map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={() => handleClick(row.c_id)}
                            role="checkbox"
                            aria-checked={selectedId === row.c_id}
                            tabIndex={-1}
                            key={index}
                            selected={selectedId === row.c_id}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={selectedId === row.c_id}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell align="left">{row.full_name}</TableCell>
                            <TableCell align="left">{row.user_id}</TableCell>
                            <TableCell align="left">{row.email}</TableCell>
                            <TableCell align="left">{row.contact}</TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={rows?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1>No Data</h1>
              </Box>
            )}
          </>
        )}
      </Paper>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <DeleteCustomerPopup
            item="buyer"
            selectedId={selectedId}
            setOpenDeleteDialog={setOpenDeleteDialog}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={openDetailsDialog}
        onClose={() => {
          setOpenDetailsDialog(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px",
            background: "#F6EDED",
            boxShadow:
              "2px 4px 4px 0px rgba(0, 0, 0, 0.50), -2px -2px 4px 0px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CustomerDetailDialog
            selectedId={selectedId}
            setOpenDetailsDialog={setOpenDetailsDialog}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
