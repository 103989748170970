import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerDetailsById } from "../../../redux/overviewSlice";
function OverviewCustomerDetails({ selectedId }) {
  const dispatch = useDispatch();
  const customerDetails = useSelector(
    (state) => state.overview.customerDetails
  );
  useEffect(() => {
    dispatch(fetchCustomerDetailsById({ customerId: selectedId }));
  }, [selectedId, dispatch]);

  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td>Customer ID : </td>
            <td>{selectedId}</td>
          </tr>
          <tr>
            <td>Customer Name : </td>
            <td>{customerDetails?.name}</td>
          </tr>

          <tr>
            <td>Customer Email : </td>
            <td>{customerDetails?.email}</td>
          </tr>
          <tr>
            <td>Contact : </td>
            <td>{customerDetails?.phone_number}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default OverviewCustomerDetails;
