import React from "react";
import { Box, CircularProgress } from "@mui/material";
function ShowLoaderError({ isLoading, errorMessage }) {
  const showLoading = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "35vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
  const showError = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        height: "35vh",
        flexDirection: "column",
      }}
    >
      <h1>Unable To Load</h1>
      <p>{errorMessage}</p>
    </Box>
  );

  return (
    <>
      {isLoading && showLoading}
      {errorMessage && showError}
    </>
  );
}

export default ShowLoaderError;
