import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";

import WestIcon from "@mui/icons-material/West";
import AddIcon from "@mui/icons-material/Add";

import {
  editProperty,
  fetchEditProperty,
  fetchRecentProperty,
} from "../../redux/overviewSlice";

import { resetLocation, setMap } from "../../redux/locationSlice";

import ApartmentFirstLocationDetails from "./apartment_first_location_details";
import ApartmentSecondRoomDetails from "./apartment_second_room_details";
import ApartmentThirdDescription from "./property_third_description";

const MAX_SECTION = 3;

function EditPropertyApartment({
  listedFor,
  setOpenAddPropertyPopup,
  selectedId,
}) {
  const dispatch = useDispatch();

  const [activeSectionId, setActiveSectionId] = useState(1);

  const [propertyDetailsData, setPropertyDetailsData] = useState({});
  const [roomDetailsData, setRoomDetailsData] = useState({});
  const [descriptionData, setDescriptionData] = useState({});
  const [changedData, setChangedData] = useState({});
  const [propertyImages, setPropertyImages] = useState({});

  const selectedMap = useSelector((state) => state.location.selectedMap);

  const editPropertyData = useSelector(
    (state) => state.overview.editPropertyData
  );

  useEffect(() => {
    dispatch(
      fetchEditProperty({ property_id: selectedId, endPoint: "apartment" })
    );
  }, [dispatch, selectedId]);

  useEffect(() => {
    if (editPropertyData) {
      dispatch(
        setMap({
          lat: editPropertyData?.latitude,
          lng: editPropertyData?.longitude,
        })
      );
      setPropertyDetailsData({
        province: editPropertyData.province,
        district: editPropertyData.district,
        municipality: editPropertyData.municipality,
        ward: editPropertyData.ward,
        landmark: editPropertyData.landmark,
        area_name: editPropertyData.area_name,

        listed_for: editPropertyData.listed_for,

        property_for: editPropertyData.property_for,
        property_name: editPropertyData.property_name,
        property_area: editPropertyData.property_area,
        bhk: editPropertyData.bhk,
        facing: editPropertyData.facing,
        road_access: editPropertyData.road_access,
        owner_name: editPropertyData.owner_name,
        owner_phone: editPropertyData.owner_phone,
      });

      setPropertyImages({
        property_id: editPropertyData.property_id,
        property_images: editPropertyData.property_image,
        property_type: editPropertyData.property_type,
      });

      setRoomDetailsData({
        price: editPropertyData.price,
        price_type: editPropertyData.price_type,
        price_per: editPropertyData.price_per,
        bedroom: editPropertyData.bedroom,
        kitchen: editPropertyData.kitchen,
        living_room: editPropertyData.living_room,
        bathroom: editPropertyData.bathroom,
        store_room: editPropertyData.store_room,
        pooja_room: editPropertyData.pooja_room,
        extra_room: editPropertyData.extra_room,
        servant_quarter: editPropertyData.servant_quarter,
        furnish: editPropertyData.furnish,
      });
      setDescriptionData({
        amenities: editPropertyData.amenities,
        parking_car: editPropertyData.parking_car,
        parking_bike: editPropertyData.parking_bike,
        social_media: editPropertyData.social_media,
        description: editPropertyData.description,
        gallery: [],
      });
    }
  }, [editPropertyData, dispatch]);

  function incrementActiveSectionId() {
    if (activeSectionId === MAX_SECTION) return;
    setActiveSectionId(activeSectionId + 1);
  }
  function decrementActiveSectionId() {
    if (activeSectionId === 1) return;
    setActiveSectionId(activeSectionId - 1);
  }
  function handleEditProperty(e) {
    e.preventDefault();
    const formData = new FormData();

    if (
      selectedMap.lat !== editPropertyData.latitude ||
      selectedMap.lng !== editPropertyData.longitude
    ) {
      setChangedData({
        ...changedData,
        latitude: selectedMap.lat || editPropertyData.latitude,
        longitude: selectedMap.lng || editPropertyData.longitude,
      });
    }

    if (Object.keys(changedData).length === 0) return;

    const { gallery, ...rest } = changedData;

    if (gallery) {
      for (let i = 0; i < gallery.length; i++) {
        formData.append("image", gallery[i]);
      }
    }

    formData.append("property", JSON.stringify(rest));

    dispatch(
      editProperty({
        formData: formData,
        endPoint: "apartment",
        property_id: selectedId,
      })
    ).then((action) => {
      if (editProperty.fulfilled.match(action)) {
        dispatch(fetchRecentProperty());
        setOpenAddPropertyPopup(false);
        dispatch(resetLocation());
      }
    });
  }

  return (
    <Box>
      {activeSectionId === 1 && (
        <ApartmentFirstLocationDetails
          propertyDetailsData={propertyDetailsData}
          setPropertyDetailsData={setPropertyDetailsData}
          incrementActiveSectionId={incrementActiveSectionId}
          changedData={changedData}
          setChangedData={setChangedData}
        />
      )}
      {activeSectionId === 2 && (
        <ApartmentSecondRoomDetails
          listedFor={listedFor}
          roomDetailsData={roomDetailsData}
          setRoomDetailsData={setRoomDetailsData}
          incrementActiveSectionId={incrementActiveSectionId}
          decrementActiveSectionId={decrementActiveSectionId}
          changedData={changedData}
          setChangedData={setChangedData}
        />
      )}
      {activeSectionId === 3 && (
        <ApartmentThirdDescription
          descriptionData={descriptionData}
          setDescriptionData={setDescriptionData}
          changedData={changedData}
          setChangedData={setChangedData}
          propertyImages={propertyImages}
          setPropertyImages={setPropertyImages}
        />
      )}

      {activeSectionId === MAX_SECTION && (
        <Box
          sx={{
            width: "100%",
            marginTop: "0.5rem",
            display: "flex",
            justifyContent:
              activeSectionId === 1 ? "flex-end" : "space-between ",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            style={{
              border: "none",
              color: "black",
              fontFamily: "Roboto Serif",
              fontSize: "0.9rem",
              fontWeight: "700",
              textTransform: "none",
            }}
            startIcon={<WestIcon />}
            onClick={decrementActiveSectionId}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            style={{
              borderRadius: "2rem",
              color: "white",
              fontFamily: "Roboto Serif",
              fontSize: "0.75rem",

              textTransform: "none",
              backgroundColor: "#000",
            }}
            endIcon={<AddIcon />}
            onClick={handleEditProperty}
          >
            Edit Property
          </Button>
        </Box>
      )}
    </Box>
  );
}
export default EditPropertyApartment;
