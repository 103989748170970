import React from "react";
import { Button, Typography, Box } from "@mui/material";
function ContactDeleteDialog({ handleDeleteClick, setOpenDeleteDialog }) {
  return (
    <>
      <Typography variant="h6">Are you sure you want to delete ?</Typography>
      <Box
        sx={{
          display: "flex",
          mt: "0.8rem",
          justifyContent: "space-around",
        }}
      >
        <Button
          variant="contained"
          onClick={handleDeleteClick}
          sx={{
            backgroundColor: "red",
            ":hover": {
              backgroundColor: "red",
            },
          }}
        >
          Yes
        </Button>
        <Button variant="contained" onClick={() => setOpenDeleteDialog(false)}>
          No
        </Button>
      </Box>
    </>
  );
}

export default ContactDeleteDialog;
